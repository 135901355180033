import React, { useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircle, RemoveCircle, Publish, PersonAdd, ExpandMore, Print, Close, Refresh as RefreshIcon, Drafts as DraftIcon, Edit as EditIcon, Delete as DeleteIcon, Visibility as PreviewIcon, VisibilityRounded as ShowIcon, VisibilityOffRounded as NotShowIcon } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import { names } from '../Settings/globalVar';
import Paper from '@material-ui/core/Paper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import toWords from 'numberinwords-id';
import { useReactToPrint } from 'react-to-print';
import { ComponentToPrint } from '../Components/PrintPreview';
import { ComponentToPrintDraft } from '../Components/PrintPreviewDraft';
import { getTokenOwnerRole } from '../Settings/globalFunc';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import qz from 'qz-tray';
// import fs from 'fs-extra';

const useStyles = makeStyles(() => ({
    salesContainer: {
        width: '100%'
    },
    salesForm: {
        width: '100%',
        padding: '10px'
    },
    draftForm: {
        width: '100%',
        padding: '10px',
        marginTop: '20px'
    },
    divider: {
        height: '10px',
        backgroundColor: 'rgba(0,0,0,0)'
    },
    inputAlamat: {
        width: '100%'
    },
    inputCustomer: {
        width: '100%'
    },
    inputSales: {
        width: '100%'
    },
    inputTelepon: {
        width: '100%'
    },
    itemContainer: {
        display: 'flex',
        // marginBottom: '10px'
    },
    inputJumlah: {
        marginRight: '20px',
        width: '80px',
    },
    inputHarga: {
        marginRight: '20px',
        width: '150px',
    },
    inputBarang: {
        marginRight: '20px',
        width: '350px',
    },
    inputUkuran: {
        marginRight: '20px',
        width: '80px',
    },
    inputTipe: {
        marginRight: '20px',
        width: '100px',
    },
    inputTotal: {
        marginRight: '20px',
        width: '150px',
    },
    accordion: {
        marginBottom: '20px',
        width: '100%'
    },
    removeControl: {
        padding: '10px',
    },
    dividerSection: {
        width: '100%',
        border: '1px solid black'
    },
    submitOnlyButton: {
        marginTop: '30px',
        width: '200px',
        padding: '20px',
        marginLeft: '30px',
        float: 'right'
    },
    submitPrintButton: {
        marginTop: '30px',
        width: '200px',
        padding: '20px'
    },
    draftButton: {
        margin: 'auto',
        width: '100px',
        padding: '20px'
    },
    previewButton: {
        margin: 'auto',
        width: '120px',
        padding: '20px'
    },
    loadingIndicator: {
        margin: 'auto',
    },
    previewPaper: {
        // textAlign: 'left',
        padding: '10px',
        width: '600px',
        margin: 'auto',
        marginTop: '100px'
    },
    previewContainer: {
        // textAlign: 'center',
        zIndex: '1',
        position: 'fixed',
        left: '0',
        right: '0',
        top: '0',
        margin: 'auto',
        backgroundColor: 'rgba(55,55,55,0.3)',
        height: '100vh',
        overflowY: 'scroll',
    }
}))

const statusBarangPenjualan = ['On The Way', 'Available', 'Taken', 'Delivered', '(Instant) Delivered']
const listTipeSengSpandek = ['Tipe 700', 'Tipe 740', 'Tipe 760']
const listTipeSengGenteng = ['Hoki Star', 'Classic Star', 'Star', 'RJ OK', 'Musim Roof']

export default function AddSales() {
    const classes = useStyles();
    const [customer, setCustomer] = React.useState(null)
    const [listCustomer, setListCustomer] = React.useState([])

    const [items, setItems] = React.useState([null])
    const [listItem, setListItem] = React.useState([])

    const [sales, setSales] = React.useState(null)
    const [listSales, setListSales] = React.useState([])

    const [noDraft, setNoDraft] = React.useState(null)
    const [listDrafts, setListDrafts] = React.useState([])

    // const [paymentTotal, setPaymentTotal] = React.useState(0)
    // const [paymentCredit, setPaymentCredit] = React.useState(0)
    const [pakaiPengantaran, setPakaiPengantaran] = React.useState(false)
    const [alamatPengantaran, setAlamatPengantaran] = React.useState("-")
    const [biayaPengantaran, setBiayaPengantaran] = React.useState(0)

    const [paymentCash, setPaymentCash] = React.useState(0);
    const [paymentTransfer, setPaymentTransfer] = React.useState(0)
    const [paymentDiscount, setPaymentDiscount] = React.useState(0)

    const [keteranganTambahan, setKeteranganTambahan] = React.useState("-")

    const [isFetchingCustomerData, setIsFetchingCustomerData] = React.useState(true)
    const [isFetchingItemData, setIsFetchingItemData] = React.useState(true)
    const [isFetchingSalesData, setIsFetchingSalesData] = React.useState(true)
    const [isFetchingDraftData, setIsFetchingDraftData] = React.useState(false)
    const [isSubmittingBon, setIsSubmittingBon] = React.useState(false)

    const [dialogPreviewProperties, setDialogPreviewProperties] = React.useState({
        isOpen: false, dialogTitle: "", dialogContent: "", dialogData: null
    })

    const [namaCustomer, setNamaCustomer] = React.useState("")
    const [nomorCustomer, setNomorCustomer] = React.useState("")
    const [alamatCustomer, setAlamatCustomer] = React.useState("")

    const [isCreatingCustomer, setIsCreatingCustomer] = React.useState(false)
    const [isSubmittingCustomerData, setIsSubmittingCustomerData] = React.useState(false)
    const [printData, setPrintData] = React.useState(null)
    const [printDraftData, setPrintDraftData] = React.useState(null)
    const [isSearchingDraft, setIsSearchingDraft] = React.useState(false);

    const componentRef = useRef();
    const componentDraftRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const handlePrintDraft = useReactToPrint({
        content: () => componentDraftRef.current,
    });

    useEffect(() => {
        setIsFetchingCustomerData(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_cat_sales/get_all_customer_public_data`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            }
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsFetchingCustomerData(false)
                return
            }
            return res.json()
        }).then((res) => {
            setListCustomer(res.data)
            setIsFetchingCustomerData(false)
        }).catch((ex) => {
            alert(ex)
        })
    }, [])

    useEffect(() => {
        setIsFetchingItemData(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_cat_sales/get_info_barang_for_sell`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            }
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsFetchingItemData(false)
                return
            }
            return res.json()
        }).then((res) => {
            setListItem(res.data)
            setIsFetchingItemData(false)
        }).catch((ex) => {
            alert(ex)
        })
    }, [])

    useEffect(() => {
        setIsFetchingSalesData(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_cat_sales/get_all_sales`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            }
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsFetchingItemData(false)
                return
            }
            return res.json()
        }).then((jsonData) => {
            setListSales(jsonData.data)
            setIsFetchingSalesData(false)
        }).catch(ex => {
            alert(ex)
        })
    }, [])

    useEffect(() => {
        setIsFetchingDraftData(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_cat_sales/get_all_draft_penjualan`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            }
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsFetchingDraftData(false)
                return
            }
            return res.json()
        }).then((res) => {
            const newListDrafts = [];
            res.data.forEach((val, idx, arr) => {
                newListDrafts.push({
                    no_draft: val.no_draft,
                    id_draft: val.id_draft,
                    json_draft: JSON.parse(val.json_draft.replace(/\t/g, '')),
                    admin: val.admin
                })
            })
            setListDrafts(newListDrafts)
            setIsFetchingDraftData(false)
        }).catch((ex) => {
            alert(ex)
        })
    }, [])

    function getCustomerList() {
        setIsFetchingCustomerData(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_cat_sales/get_all_customer_public_data`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            }
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsFetchingCustomerData(false)
                return
            }
            return res.json()
        }).then((res) => {
            setListCustomer(res.data)
            setIsFetchingCustomerData(false)
        }).catch((ex) => {
            alert(ex)
        })
    }

    function getItemList() {
        setIsFetchingItemData(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_cat_sales/get_info_barang_for_sell`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            }
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsFetchingItemData(false)
                return
            }
            return res.json()
        }).then((res) => {
            setListItem(res.data)
            setIsFetchingItemData(false)
        }).catch((ex) => {
            alert(ex)
        })
    }

    function getHargaBarang(idx) {
        if (!items[idx]) return 0
        else return items[idx].harga_barang
    }

    function getJumlahBarang(idx) {
        if (!items[idx]) return 0
        else return items[idx].jumlah_barang
    }

    function getStokBarang(idx) {
        if (!items[idx]) return 0
        else return items[idx].total_stok
    }

    function getUkuranBarang(idx) {
        if (!items[idx]) return 0
        else return items[idx].ukuran
    }

    function getTotalHargaBarang(idx) {
        if (!items[idx]) return 0
        else {
            if (!isNaN(items[idx].ukuran)) return items[idx].ukuran * items[idx].harga_barang * items[idx].jumlah_barang
            else return items[idx].harga_barang * items[idx].jumlah_barang
        }
    }

    function getTotalHargaBon() {
        if (items.length === 0) return 0
        let totalHarga = 0;
        items.forEach((val, idx, arr) => {
            if (!val) totalHarga += 0
            else if (!isNaN(val.ukuran)) totalHarga += val.ukuran * val.harga_barang * val.jumlah_barang
            else totalHarga += val.harga_barang * val.jumlah_barang
        })
        totalHarga += biayaPengantaran
        return totalHarga
    }

    function inputBonPenjualan(needPrint) {
        if (!customer) return alert('Data Customer tidak Di-Input')
        if (items.length === 0) return alert('Tidak Ada Barang yang Di-Pilih')
        if (items.includes(null)) return alert('Terdapat Data Barang yang Tidak Terisi pada List Barang Penjualan')
        if (items.find((val) => {
            if (!isNaN(val.ukuran) && val.ukuran === 0) {
                alert(`Terdapat Data Barang yang Tidak Terisi pada List Barang Penjualan`)
                return true;
            }
            else if (val.jumlah_barang === 0) {
                alert(`${val.id_barang}\nTerdapat Barang dengan Jumlah Penjualan - 0 (nol)`)
                return true
            }
            else if (val.harga_barang === 0) {
                alert(`${val.id_barang}\nTerdapat Barang dengan Harga Penjualan - 0 (nol)`)
                return true
            }
            else if (val.details.find((detail) => detail.quantity === 0)) {
                alert(`Tidak Boleh Ada Quantity 0 (nol) pada Rincian Penjualan - ${val.id_barang}`)
                return true
            }
            else if (val.details.reduce((a, b) => a + b.quantity, 0) !== val.jumlah_barang) {
                alert(`Masih Ada Sisa Kuota Barang Terjual yang Tidak di-Input Pada Rincian Penjualan - ${val.id_barang}`)
                return true
            }
            else if (!val.id_barang.includes("(Custom)") && val.details.filter((val) => val.status !== "On The Way" && val.status !== "(Instant) Delivered").reduce((a, b) => a + b.quantity, 0) > listItem.find((item) => item.id_barang === val.id_barang).total_stok) {
                alert(`Kuota Barang yang Tersedia Tidak Cukup\nHarap Atur Rincian Penjualan pada Barang dengan Status On The Way - ${val.id_barang}`)
                return true
            }
            else return false
        })) return;
        if (getTotalHargaBon() < paymentCash + paymentTransfer + paymentDiscount) return alert("Pembayaran Berlebihan\nMohon Pembayaran Di-Update atau Di-Ubah")

        const bodyData = { customer: customer, items: items, pakai_pengantaran: pakaiPengantaran, biaya_pengantaran: biayaPengantaran, alamat_pengantaran: alamatPengantaran, payment_cash: paymentCash, payment_transfer: paymentTransfer, payment_discount: paymentDiscount, keterangan_tambahan: keteranganTambahan, id_sales: sales, no_draft: noDraft }

        setIsSubmittingBon(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_cat_sales/input_bon_penjualan_cat`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
            body: JSON.stringify(bodyData)
        }).then(async (res) => {
            const jsonData = await res.json()
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                return
            }
            else if (res.status === 400 || res.status === 409) {
                console.log("Cancel")
                alert(jsonData.message)
                setIsSubmittingBon(false)
                return
            }

            if (res.status === 200 && needPrint) {
                setPrintData(jsonData.data)
                handlePrint()
            }
            setCustomer(null)
            setItems([null])
            setPakaiPengantaran(false)
            setAlamatPengantaran("-")
            setBiayaPengantaran(0)
            setPaymentCash(0)
            setPaymentTransfer(0)
            setPaymentDiscount(0)
            setKeteranganTambahan("-")
            setPrintData(null)
            alert(jsonData.message)
            getItemList()
            getListDraftPenjualan()
            setIsSubmittingBon(false)
        }).catch((ex) => {
            alert(ex)
            setIsSubmittingBon(false)
        })
    }

    function inputDraftPenjualan(needPrint) {
        if (!customer) return alert('Data Customer tidak Di-Input')
        if (items.length === 0) return alert('Tidak Ada Barang yang Di-Pilih')
        if (items.includes(null)) return alert('Terdapat Data Barang yang Tidak Terisi pada List Barang Penjualan')
        if (items.find((val) => {
            if (!isNaN(val.ukuran) && val.ukuran === 0) {
                alert(`Terdapat Data Barang yang Tidak Terisi pada List Barang Penjualan`)
                return true;
            }
            else if (val.jumlah_barang === 0) {
                alert(`${val.id_barang}\nTerdapat Barang dengan Jumlah Penjualan - 0 (nol)`)
                return true
            }
            else if (val.harga_barang === 0) {
                alert(`${val.id_barang}\nTerdapat Barang dengan Harga Penjualan - 0 (nol)`)
                return true
            }
            else if (val.details.find((detail) => detail.quantity === 0)) {
                alert(`Tidak Boleh Ada Quantity 0 (nol) pada Rincian Penjualan - ${val.id_barang}`)
                return true
            }
            else if (val.details.reduce((a, b) => a + b.quantity, 0) !== val.jumlah_barang) {
                alert(`Masih Ada Sisa Kuota Barang Terjual yang Tidak di-Input Pada Rincian Penjualan - ${val.id_barang}`)
                return true
            }
            else if (!val.id_barang.includes("(Custom)") && val.details.filter((val) => val.status !== "On The Way" && val.status !== "(Instant) Delivered").reduce((a, b) => a + b.quantity, 0) > listItem.find((item) => item.id_barang === val.id_barang).total_stok) {
                alert(`Kuota Barang yang Tersedia Tidak Cukup\nHarap Atur Rincian Penjualan pada Barang dengan Status On The Way - ${val.id_barang}`)
                return true
            }
            else return false
        })) return;
        if (getTotalHargaBon() < paymentCash + paymentTransfer + paymentDiscount) return alert("Pembayaran Berlebihan\nMohon Pembayaran Di-Update atau Di-Ubah")

        const bodyData = { customer: customer, items: items, pakai_pengantaran: pakaiPengantaran, biaya_pengantaran: biayaPengantaran, alamat_pengantaran: alamatPengantaran, payment_cash: paymentCash, payment_transfer: paymentTransfer, payment_discount: paymentDiscount, keterangan_tambahan: keteranganTambahan, id_sales: sales, no_draft: noDraft }

        setIsSubmittingBon(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_cat_sales/input_draft_bon_penjualan_cat`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
            body: JSON.stringify(bodyData)
        }).then(async (res) => {
            const jsonData = await res.json()
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                return
            }
            else if (res.status === 400 || res.status === 409) {
                console.log("Cancel")
                alert(jsonData.message)
                setIsSubmittingBon(false)
                return
            }

            setNoDraft(null)
            setCustomer(null)
            setItems([null])
            setPakaiPengantaran(false)
            setAlamatPengantaran("-")
            setBiayaPengantaran(0)
            setPaymentCash(0)
            setPaymentTransfer(0)
            setPaymentDiscount(0)
            setKeteranganTambahan("-")
            setPrintData(null)
            alert(jsonData.message)
            getItemList()
            getListDraftPenjualan()
            setIsSubmittingBon(false)
        }).catch((ex) => {
            alert(ex)
            setIsSubmittingBon(false)
        })
    }

    function getListDraftPenjualan() {
        setIsFetchingDraftData(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_cat_sales/get_all_draft_penjualan`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            }
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsFetchingDraftData(false)
                return
            }
            return res.json()
        }).then((res) => {
            const newListDrafts = [];
            res.data.forEach((val, idx, arr) => {
                newListDrafts.push({
                    no_draft: val.no_draft,
                    id_draft: val.id_draft,
                    json_draft: JSON.parse(val.json_draft.replace(/\t/g, '')),
                    admin: val.admin
                })
            })
            setListDrafts(newListDrafts)
            setIsFetchingDraftData(false)
        }).catch((ex) => {
            alert(ex)
        })
    }

    function clearBonPenjualanForm() {
        setNoDraft(null)
        setCustomer(null)
        setItems([null])
        setPakaiPengantaran(false)
        setAlamatPengantaran("-")
        setBiayaPengantaran(0)
        setPaymentCash(0)
        setPaymentTransfer(0)
        setPaymentDiscount(0)
        setKeteranganTambahan("-")
        setPrintData(null)
    }

    function editDraftPenjualan(draftData) {
        setNoDraft(draftData.no_draft)
        setCustomer(draftData.json_draft.customer)
        setItems(draftData.json_draft.items)
        setPakaiPengantaran(draftData.json_draft.pakai_pengantaran)
        setBiayaPengantaran(draftData.json_draft.biaya_pengantaran)
        setAlamatPengantaran(draftData.json_draft.alamat_pengantaran)
        setSales(draftData.json_draft.id_sales)
        setKeteranganTambahan(draftData.json_draft.keterangan_tambahan)
        setPaymentCash(draftData.json_draft.payment_cash)
        setPaymentDiscount(draftData.json_draft.payment_discount)
        setPaymentTransfer(draftData.json_draft.payment_transfer)
        setIsSearchingDraft(false);
    }

    function deleteDraftPenjualan(no_draft) {
        setIsFetchingDraftData(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/super_admin/delete_draft_penjualan_cat`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
            body: JSON.stringify({ no_draft: no_draft })
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsFetchingDraftData(false)
                return
            }
            getListDraftPenjualan()
        }).catch((ex) => {
            alert(ex)
        })
    }

    function createCustomer() {
        if (!namaCustomer || !nomorCustomer || !alamatCustomer) {
            alert("Please input all the data in the form")
            return
        }

        const data = { id_customer: namaCustomer, telp_customer: nomorCustomer, alamat_customer: alamatCustomer }
        setIsSubmittingCustomerData(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_cat_sales/create_new_common_customer`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
            body: JSON.stringify(data)
        }).then(async (res) => {
            const data = await res.json();
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                return
            }
            if (!data["success"]) throw (new Error(data["message"]))
            else alert("Create New Customer Successfully !!!")
            setNamaCustomer("")
            setNomorCustomer("")
            setAlamatCustomer("")
            getCustomerList()
            setIsSubmittingCustomerData(false)
        }).catch((ex) => {
            alert(ex)
            setNamaCustomer("")
            setNomorCustomer("")
            setAlamatCustomer("")
            setIsSubmittingCustomerData(false)
        })
    }

    return (
        <div className={classes.salesContainer}>
            {printData !== null ? <div style={{ display: 'none' }}><ComponentToPrint ref={componentRef} bonData={printData} /></div> : null}
            <Dialog
                open={dialogPreviewProperties.isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xl"
            >
                <DialogTitle id="alert-dialog-title">{dialogPreviewProperties.dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <TextField disabled label={dialogPreviewProperties.dialogContent} style={{ width: '100%', color: 'black' }} />
                    </DialogContentText>
                    <div style={{ display: 'block' }}><ComponentToPrintDraft ref={componentRef} draftData={dialogPreviewProperties.dialogData} /></div>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" variant="contained" onClick={(ev) => {
                        setDialogPreviewProperties({
                            isOpen: false,
                            dialogTitle: dialogPreviewProperties.dialogTitle,
                            dialogContent: dialogPreviewProperties.dialogContent,
                            dialogData: dialogPreviewProperties.dialogData
                        })
                    }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Divider className={classes.dividerSection} />

            {printDraftData !== null ? <div style={{ display: 'none' }}><ComponentToPrintDraft ref={componentDraftRef} bonData={printDraftData} /></div> : null}

            {isCreatingCustomer ? <div className={classes.previewContainer}>
                <Paper className={classes.previewPaper} id="divcontents">
                    <Typography variant="h6" gutterBottom>Tambah Customer Baru</Typography>
                    {isSubmittingCustomerData ? <CircularProgress /> : <>
                        <TextField
                            id="namaCustomer"
                            name="namaCustomer"
                            label="Nama Customer"
                            value={namaCustomer}
                            onChange={(ev) => setNamaCustomer(ev.target.value)}
                            fullWidth
                        />
                        <Divider className={classes.divider} />
                        <TextField
                            id="nomorCustomer"
                            name="nomorCustomer"
                            label="Nomor Telepon"
                            value={nomorCustomer}
                            onChange={(ev) => setNomorCustomer(ev.target.value)}
                            fullWidth
                        />
                        <Divider className={classes.divider} />
                        <TextField
                            id="alamatCustomer"
                            name="alamatCustomer"
                            label="Alamat Customer"
                            value={alamatCustomer}
                            onChange={(ev) => setAlamatCustomer(ev.target.value)}
                            fullWidth
                        />
                        <Divider className={classes.divider} />
                        <Button style={{ padding: '10px' }} variant="contained" className={classes.button} color="primary" onClick={createCustomer}>
                            <Publish className={classes.iconButton} />{"SUBMIT"}
                        </Button>
                        <Button style={{ padding: '10px', marginLeft: '10px' }} variant="contained" className={classes.button} color="secondary" onClick={(ev) => {
                            setNamaCustomer("")
                            setNomorCustomer("")
                            setAlamatCustomer("")
                            setIsCreatingCustomer(false)
                        }}>
                            <Close className={classes.iconButton} />{"CLOSE"}
                        </Button>
                    </>}
                </Paper>
            </div> : null}
            {isSubmittingBon ? <CircularProgress className={classes.loadingIndicator} /> : <Paper className={classes.salesForm}>
                <Typography variant="h6">Tambah Penjualan Toko Cat - {new Date().toLocaleDateString("id-ID", { year: "numeric", month: "long", day: "numeric", weekday: "long" })} || REFERENSI No. DRAFT : {noDraft ? noDraft : "-"}</Typography>
                <Grid container spacing={3}>
                    {isFetchingCustomerData || isFetchingSalesData ? <CircularProgress className={classes.loadingIndicator} /> : <>
                        <Grid item xs={12} sm={4}>
                            <Autocomplete
                                className={classes.inputCustomer}
                                options={listCustomer}
                                getOptionLabel={(opt) => opt.id_customer}
                                getOptionSelected={(opt, val) => opt.id_customer === val.id_customer}
                                value={customer}
                                onChange={(ev, val) => {
                                    let temp = [];
                                    if (items.length > 0) items.forEach((item, idx, arr) => {
                                        if (item !== null && val !== null) {
                                            switch (val.jenis_customer) {
                                                case 'ecer':
                                                    item.harga_barang = item.harga_ecer
                                                    break;
                                                case 'toko':
                                                    item.harga_barang = item.harga_toko
                                                    break;
                                                case 'tukang':
                                                    item.harga_barang = item.harga_tukang
                                                    break;
                                                default:
                                                    item.harga_barang = 0
                                            }
                                        }
                                        else if (item !== null && val === null) item.harga_barang = 0;
                                        temp.push(item)
                                    })
                                    setItems(temp)
                                    setCustomer(val)
                                }}
                                renderInput={(params) => <TextField {...params} label="Nama Customer" />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField label="Nomor Telepon" className={classes.inputTelepon} disabled value={customer ? customer.telp_customer : '-'} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField label="Alamat" className={classes.inputAlamat} disabled value={customer ? customer.alamat_customer : '-'} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Autocomplete
                                className={classes.inputSales}
                                options={listSales.map((val) => val.id_user)}
                                value={sales}
                                onChange={(ev, val) => setSales(val)}
                                renderInput={(params) => <TextField {...params} label="Sales" />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<PersonAdd />}
                                onClick={(ev) => setIsCreatingCustomer(true)}>
                                Add New Customer
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<RefreshIcon />}
                                onClick={(ev) => getCustomerList()}>
                                Refresh Customer
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<RefreshIcon />}
                                onClick={(ev) => getItemList()}>
                                Refresh Item
                            </Button>
                        </Grid>
                    </>}
                    <Divider className={classes.dividerSection} />
                    {isFetchingItemData ? <CircularProgress className={classes.loadingIndicator} /> :
                        <Grid item xs={12} style={{ margin: '20px' }}>
                            {items.map((item, idx, arr) => {
                                return (
                                    <Accordion key={`accordion_${idx}`} className={classes.accordion}/*  expanded={itemExpand === "panel_" + idx} */>
                                        <AccordionSummary key={`accordion_summary_${idx}`} className={classes.itemContainer} expandIcon={<ExpandMore />}>
                                            <FormControlLabel
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={<Autocomplete
                                                    className={classes.inputBarang}
                                                    options={listItem}
                                                    getOptionLabel={(opt) => opt.id_barang}
                                                    getOptionSelected={(opt, val) => opt.id_barang === val.id_barang}
                                                    value={items[idx]}
                                                    onChange={(ev, value) => {
                                                        let temp = [];
                                                        temp = temp.concat(arr);
                                                        temp[idx] = value;
                                                        if (temp[idx] !== null) {
                                                            if (!customer) temp[idx].harga_barang = 0
                                                            else switch (customer.jenis_customer) {
                                                                case 'ecer':
                                                                    temp[idx].harga_barang = temp[idx].harga_ecer
                                                                    break;
                                                                case 'toko':
                                                                    temp[idx].harga_barang = temp[idx].harga_toko
                                                                    break;
                                                                case 'tukang':
                                                                    temp[idx].harga_barang = temp[idx].harga_tukang
                                                                    break;
                                                                default:
                                                                    temp[idx].harga_barang = 0
                                                            }

                                                            if (temp[idx].id_barang.toLowerCase().includes('(custom)')) {
                                                                temp[idx].ukuran = 0
                                                                if (temp[idx].id_barang.toLowerCase().includes('seng spandek'))
                                                                    temp[idx].tipe = "Tipe 760"
                                                                else if (temp[idx].id_barang.toLowerCase().includes('seng genteng'))
                                                                    temp[idx].tipe = "Musim Roof"
                                                            }
                                                            temp[idx].jumlah_barang = 0;
                                                            temp[idx].details = [{ quantity: 0, status: statusBarangPenjualan[2], keterangan: "-" }];
                                                        }
                                                        const tempJsonString = JSON.stringify(temp)
                                                        setItems(JSON.parse(tempJsonString));
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label={`Barang ${idx + 1}`} />}
                                                />}
                                            />
                                            {items[idx] ? <>
                                                {!isNaN(items[idx].ukuran) ?
                                                    <FormControlLabel
                                                        onClick={(event) => event.stopPropagation()}
                                                        onFocus={(event) => event.stopPropagation()}
                                                        control={<TextField className={classes.inputUkuran}
                                                            id={"ukuran_" + idx}
                                                            name={"ukuran_" + idx}
                                                            label="Ukuran"
                                                            key={"ukuran_" + idx}
                                                            type="number"
                                                            value={getUkuranBarang(idx)}
                                                            onChange={(ev) => {
                                                                let temp = [];
                                                                temp = temp.concat(arr);
                                                                if (temp[idx] != null) {
                                                                    if (ev.target.value < 0) return 0;
                                                                    else temp[idx].ukuran = isNaN(parseFloat(ev.target.value)) ? 0 : parseFloat(ev.target.value);
                                                                }
                                                                setItems(temp);
                                                            }}
                                                        />} /> :
                                                    <FormControlLabel
                                                        onClick={(event) => event.stopPropagation()}
                                                        onFocus={(event) => event.stopPropagation()}
                                                        control={<TextField className={classes.inputJumlah}
                                                            id={"stok_" + idx}
                                                            name={"stok_" + idx}
                                                            label="Stok"
                                                            key={"stok_" + idx}
                                                            type="number"
                                                            value={getStokBarang(idx)}
                                                            disabled
                                                        />}
                                                    />}
                                                {items[idx].tipe && items[idx].id_barang.toLowerCase().includes('seng spandek') ?
                                                    <FormControlLabel
                                                        onClick={(event) => event.stopPropagation()}
                                                        onFocus={(event) => event.stopPropagation()}
                                                        control={
                                                            <FormControl fullWidth className={classes.inputTipe}>
                                                                <InputLabel>Tipe</InputLabel>
                                                                <Select value={items[idx].tipe} onChange={(ev) => {
                                                                    let temp = [];
                                                                    temp = temp.concat(arr);
                                                                    temp[idx].tipe = ev.target.value
                                                                    setItems(temp);
                                                                }}>
                                                                    {listTipeSengSpandek.map((tipeVal, tipeIdx, tipeArr) => {
                                                                        return (<MenuItem key={`rincian_status_${idx}`} value={tipeVal}>{tipeVal}</MenuItem>)
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        } /> : null}
                                                {items[idx].tipe && items[idx].id_barang.toLowerCase().includes('seng genteng') ?
                                                    <FormControlLabel
                                                        onClick={(event) => event.stopPropagation()}
                                                        onFocus={(event) => event.stopPropagation()}
                                                        control={
                                                            <FormControl fullWidth className={classes.inputTipe}>
                                                                <InputLabel>Tipe</InputLabel>
                                                                <Select value={items[idx].tipe} onChange={(ev) => {
                                                                    let temp = [];
                                                                    temp = temp.concat(arr);
                                                                    temp[idx].tipe = ev.target.value
                                                                    setItems(temp);
                                                                }}>
                                                                    {listTipeSengGenteng.map((tipeVal, tipeIdx, tipeArr) => {
                                                                        return (<MenuItem key={`rincian_status_${idx}`} value={tipeVal}>{tipeVal}</MenuItem>)
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        } /> : null}
                                                <FormControlLabel
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={<TextField className={classes.inputJumlah}
                                                        id={"quantity_" + idx}
                                                        name={"quantity_" + idx}
                                                        label="Quantity"
                                                        key={"quantity_" + idx}
                                                        type="number"
                                                        value={getJumlahBarang(idx)}
                                                        onChange={(ev) => {
                                                            let temp = [];
                                                            temp = temp.concat(arr);
                                                            if (temp[idx] != null) {
                                                                if (!temp[idx].id_barang.toLowerCase().includes('(custom)') && ev.target.value > getStokBarang(idx)) alert(`PERINGATAN : Jumlah Barang melebihi Kapasitas Stok\nSejumlah Barang harus Di-Order (${temp[idx].id_barang})`)

                                                                if (ev.target.value < 0) return temp[idx].jumlah_barang = 0;
                                                                else {
                                                                    temp[idx].jumlah_barang = isNaN(parseInt(ev.target.value)) ? 0 : parseInt(ev.target.value);
                                                                    if (temp[idx].details.length === 1) temp[idx].details[0].quantity = isNaN(parseInt(ev.target.value)) ? 0 : parseInt(ev.target.value);
                                                                }
                                                            }
                                                            setItems(temp);
                                                        }}
                                                    />} />
                                                <FormControlLabel
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={<TextField className={classes.inputHarga}
                                                        id={"harga_" + idx}
                                                        name={"harga_" + idx}
                                                        label="Harga"
                                                        key={"harga_" + idx}
                                                        type="number"
                                                        value={getHargaBarang(idx)}
                                                        onChange={(ev) => {
                                                            let harga_barang_customer;
                                                            if (customer != null) {
                                                                if (customer.jenis_customer === "ecer") harga_barang_customer = items[idx].harga_ecer;
                                                                else if (customer.jenis_customer === "toko") harga_barang_customer = items[idx].harga_toko;
                                                                else if (customer.jenis_customer === "tukang") harga_barang_customer = items[idx].harga_tukang;
                                                            }
                                                            else harga_barang_customer = items[idx].harga_ecer;

                                                            if (harga_barang_customer != 0 && (getTokenOwnerRole() !== "super_admin" || getTokenOwnerRole() !== "admin_cat_super")) return

                                                            let temp = [];
                                                            temp = temp.concat(arr);
                                                            if (temp[idx] != null) {
                                                                if (ev.target.value < 0) return 0;
                                                                else temp[idx].harga_barang = isNaN(parseInt(ev.target.value)) ? 0 : parseInt(ev.target.value);
                                                            }
                                                            setItems(temp);
                                                        }}
                                                    />} />
                                                <FormControlLabel
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={<TextField className={classes.inputTotal}
                                                        id={"total_" + idx}
                                                        name={"total_" + idx}
                                                        label="Total"
                                                        key={"total_" + idx}
                                                        value={'Rp ' + getTotalHargaBarang(idx).toLocaleString('id-ID')}
                                                        disabled
                                                    />} />
                                            </> : null}
                                            <FormControlLabel
                                                className={classes.removeControl}
                                                onFocus={(event) => event.stopPropagation()}
                                                onClick={(ev) => {
                                                    ev.stopPropagation()
                                                    let temp = [];
                                                    temp = temp.concat(arr);
                                                    if (temp.length === 1) return
                                                    temp.splice(idx, 1)
                                                    setItems(temp);
                                                }}
                                                control={<RemoveCircle color='secondary' />}
                                                label={'Remove Item'}
                                            />
                                        </AccordionSummary>
                                        {items[idx] ? <AccordionDetails>
                                            <Grid container item spacing={2}>
                                                {items[idx].details.map((detail, detailIndex, detailArray) => {
                                                    return (
                                                        <>
                                                            <Grid key={`rincian_title_${idx}`} item xs={1}>
                                                                <Typography style={{ paddingTop: '10px' }} variant="h6">Rincian {detailIndex + 1}</Typography>
                                                            </Grid>
                                                            <Grid key={`rincian_qty_${idx}`} item xs={2}>
                                                                <TextField label="Quantity" style={{ width: '100%' }} type="number" value={detail.quantity} onChange={(ev) => {
                                                                    if (ev.target.value < 0) return;
                                                                    else if (items[idx].jumlah_barang - ev.target.value < items[idx].details.reduce((a, b) => a + b.quantity, 0) - detail.quantity) return alert("Quantity Melebihi Jumlah Pembelian")
                                                                    let temp = [];
                                                                    temp = temp.concat(arr);
                                                                    temp[idx].details[detailIndex].quantity = parseInt(ev.target.value)
                                                                    setItems(temp);
                                                                }} />
                                                            </Grid>
                                                            <Grid key={`rincian_kuota_${idx}`} item xs={2}>
                                                                <TextField label="Sisa Kuota Barang Pembeli" style={{ width: '100%' }} value={items[idx].jumlah_barang - items[idx].details.reduce((a, b) => a + b.quantity, 0)} disabled />
                                                            </Grid>
                                                            <Grid key={`rincian_status_${idx}`} item xs={2}>
                                                                <FormControl fullWidth>
                                                                    <InputLabel>Status Barang</InputLabel>
                                                                    <Select value={detail.status} onChange={(ev) => {
                                                                        let temp = [];
                                                                        temp = temp.concat(arr);
                                                                        temp[idx].details[detailIndex].status = ev.target.value
                                                                        setItems(temp);
                                                                    }}>
                                                                        {statusBarangPenjualan.map((statusVal, statusIdx, statusArr) => {
                                                                            return (<MenuItem key={`rincian_status_${idx}_${statusIdx}`} value={statusVal}>{statusVal}</MenuItem>)
                                                                        })}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid key={`rincian_keterangan_${idx}`} item xs={4}>
                                                                <TextField label="Keterangan" style={{ width: '100%' }} value={detail.keterangan} onChange={(ev) => {
                                                                    let temp = [];
                                                                    temp = temp.concat(arr);
                                                                    temp[idx].details[detailIndex].keterangan = ev.target.value
                                                                    setItems(temp);
                                                                }} />
                                                            </Grid>
                                                            <Grid key={`rincian_remove_button_${idx}`} item xs={1}>
                                                                <Button variant="contained" color="secondary" onClick={(ev) => {
                                                                    let temp = [];
                                                                    temp = temp.concat(arr);
                                                                    if (temp[idx].details.length === 1) return
                                                                    temp[idx].details.splice(detailIndex, 1)
                                                                    setItems(temp);
                                                                }}>Remove Detail</Button>
                                                            </Grid>
                                                        </>
                                                    )
                                                })}
                                            </Grid>
                                            <Button style={{ width: '100px' }} color="primary" variant="contained" onClick={(ev) => {
                                                let temp = [];
                                                temp = temp.concat(arr);
                                                temp[idx].details.push({ quantity: 0, status: statusBarangPenjualan[2], keterangan: "-" })
                                                setItems(temp);
                                            }}>
                                                Add More Details
                                            </Button>
                                        </AccordionDetails> : null
                                        }
                                    </Accordion>
                                )
                            })}
                            <Button
                                onClick={(ev) => {
                                    let temp = [];
                                    temp = temp.concat(items);
                                    temp.push(null)
                                    setItems(temp);
                                }}
                                variant="contained"
                                color="primary"
                                startIcon={<AddCircle />}>
                                Add More Item
                            </Button>
                        </Grid>}
                    <Divider className={classes.dividerSection} />
                    <Grid item xs={1}>
                        <FormControlLabel control={<Checkbox checked={pakaiPengantaran} onChange={(ev) => {
                            if (!ev.target.checked) {
                                setAlamatPengantaran("-")
                                setBiayaPengantaran(0)
                            }
                            setPakaiPengantaran(ev.target.checked)
                        }} />} label="Pakai Pegantaran" />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField label="Alamat Pegantaran" style={{ width: '100%' }} disabled={!pakaiPengantaran} value={alamatPengantaran} onChange={(ev) => setAlamatPengantaran(ev.target.value)} />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField label="Biaya Pegantaran" style={{ width: '100%' }} type="number" disabled={!pakaiPengantaran} value={biayaPengantaran} onChange={(ev) => {
                            if (ev.target.value < 0) return
                            setBiayaPengantaran(parseInt(ev.target.value))
                        }} />
                        <Typography>{toWords.convert(biayaPengantaran)}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField label="Total Pembayaran - Rp" fullWidth disabled value={getTotalHargaBon().toLocaleString('id-ID')} />
                        <Typography>{toWords.convert(getTotalHargaBon())}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField label="Pembayaran Cash - Rp" fullWidth type="number" value={paymentCash} onChange={(ev) => {
                            if (parseInt(ev.target.value) < 0) return
                            if (getTotalHargaBon() < parseInt(ev.target.value) + paymentTransfer + paymentDiscount) return alert("Pembayaran Berelebih")
                            setPaymentCash(parseInt(ev.target.value))
                        }} />
                        <Typography>{toWords.convert(paymentCash)}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField label="Pembayaran Transfer - Rp" fullWidth type="number" value={paymentTransfer} onChange={(ev) => {
                            if (parseInt(ev.target.value < 0)) return
                            if (getTotalHargaBon() < parseInt(ev.target.value) + paymentCash + paymentDiscount) return alert("Pembayaran Berelebih")
                            setPaymentTransfer(parseInt(ev.target.value))
                        }} />
                        <Typography>{toWords.convert(paymentTransfer)}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField label="Diskon - Rp" fullWidth type="number" value={paymentDiscount} onChange={(ev) => {
                            if (parseInt(ev.target.value < 0)) return
                            if (getTotalHargaBon() < parseInt(ev.target.value) + paymentCash + paymentTransfer) return alert("Pembayaran Berelebih")
                            setPaymentDiscount(parseInt(ev.target.value))
                        }} />
                        <Typography>{toWords.convert(paymentDiscount)}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField color='secondary' label="Total Kredit - Rp" fullWidth disabled value={(getTotalHargaBon() - paymentCash - paymentTransfer - paymentDiscount).toLocaleString('id-ID')} />
                        <Typography color='secondary'>{toWords.convert(getTotalHargaBon() - paymentCash - paymentTransfer - paymentDiscount)}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField label="Keterangan Tambahan" fullWidth value={keteranganTambahan} onChange={(ev) => setKeteranganTambahan(ev.target.value)} />
                    </Grid>
                </Grid>
                <Button
                    className={classes.submitPrintButton}
                    variant="contained"
                    color="primary"
                    onClick={(ev) => inputBonPenjualan(true)}
                    startIcon={<Print />}>
                    {'Submit & Print'}
                </Button>
                <Button
                    className={classes.submitOnlyButton}
                    variant="contained"
                    style={{ backgroundColor: "#98fb98" }}
                    onClick={(ev) => inputBonPenjualan(false)}
                    startIcon={<Publish />}>
                    Submit Only
                </Button>
                <Button
                    className={classes.submitOnlyButton}
                    variant="contained"
                    style={{ backgroundColor: "#FFFACD" }}
                    onClick={(ev) => inputDraftPenjualan(false)}
                    startIcon={<DraftIcon />}>
                    Save To Draft
                </Button>
                <Button
                    className={classes.submitOnlyButton}
                    variant="contained"
                    style={{ backgroundColor: "#DC143C	" }}
                    onClick={(ev) => clearBonPenjualanForm()}
                    startIcon={<DeleteIcon />}>
                    Clear
                </Button>
            </Paper>}
            <Button
                variant="contained"
                style={{ backgroundColor: "white", marginTop: '30px', width: '200px' }}
                onClick={(ev) => setIsSearchingDraft(!isSearchingDraft)}
                startIcon={isSearchingDraft ? <ShowIcon /> : <NotShowIcon />}>
                {isSearchingDraft ? "Tutup List Draft" : "Lihat List Draft"}
            </Button>
            {!isSearchingDraft || isFetchingDraftData ? null :
                <Accordion className={classes.accordion} style={{ marginTop: '20px' }}>
                    <AccordionSummary className={classes.itemContainer} expandIcon={<ExpandMore />}>
                        <Typography variant="h6">Draft Penjualan</Typography>
                    </AccordionSummary>
                    {listDrafts.length > 0 ? <AccordionDetails>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Tanggal</TableCell>
                                    <TableCell>Customer</TableCell>
                                    <TableCell>Barang</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listDrafts.map((draft) => {
                                    return (
                                        <TableRow>
                                            <TableCell>
                                                <Typography style={{ paddingTop: '10px' }} variant="h6">{draft.no_draft}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography style={{ paddingTop: '10px' }} variant="h6">{new Date(parseInt(draft.id_draft)).toLocaleDateString("id-ID", { year: "numeric", month: "long", day: "numeric", weekday: "long" })}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography style={{ paddingTop: '10px' }} variant="h6">
                                                    {draft.json_draft.customer.id_customer}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography style={{ paddingTop: '10px' }} variant="h6">
                                                    {draft.json_draft.items.map(item => item.id_barang).join(", ")}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography style={{ paddingTop: '10px' }} variant="h6">
                                                    <Button
                                                        className={classes.previewButton}
                                                        variant="contained"
                                                        onClick={(ev) => setDialogPreviewProperties({
                                                            isOpen: true,
                                                            dialogTitle: "Preview Bon",
                                                            dialogContent: "Bon Ini Hanya Draft & Bersifat Sementara, Harga Dapat Berubah Sewaktu-waktu",
                                                            dialogData: draft
                                                        })}
                                                        startIcon={<PreviewIcon />}>
                                                        {'Preview'}
                                                    </Button>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography style={{ paddingTop: '10px' }} variant="h6">
                                                    <Button
                                                        className={classes.draftButton}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={(ev) => editDraftPenjualan(draft)}
                                                        startIcon={<EditIcon />}>
                                                        {'Edit'}
                                                    </Button>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography style={{ paddingTop: '10px' }} variant="h6">
                                                    <Button
                                                        className={classes.draftButton}
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={(ev) => deleteDraftPenjualan(draft.no_draft)}
                                                        startIcon={<DeleteIcon />}>
                                                        {'Delete'}
                                                    </Button>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table></AccordionDetails> : null}
                </Accordion>}
        </div>
    );
}