import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#4169E1',
        },
        secondary: {
            main: '#B22222',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: 'rgb(20, 30, 73)',
        },
    }
});

export default theme;
