import React, { useEffect, useRef } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import { HighlightOff as ClearIcon, RotateLeft as ResetIcon, Search as SearchIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete'
import { names } from '../Settings/globalVar';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Divider from '@material-ui/core/Divider';
import { getTokenOwnerRole } from '../Settings/globalFunc';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import toWords from 'numberinwords-id';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ComponentToPrint } from '../Components/PrintPreview';
import { useReactToPrint } from 'react-to-print';

const useStyles = makeStyles(() => ({
    salesSetupContainer: {
        width: '100%'
    },
    salesSetupForm: {
        width: '100%',
        padding: '10px'
    },
    inputFilterCustomer: {
        width: '90%'
    },
    inputFilterIdBon: {
        width: '90%'
    },
    inputFilterNomorBon: {
        width: '90%'
    },
    inputFilterTanggal: {
        width: '70%'
    },
    inputFilterDeadline: {
        width: '70%'
    },
    clearFilterDeadline: {
        marginTop: '13px'
    },
    clearFilterTanggal: {
        marginTop: '13px'
    },
    inputFilterJenisCustomer: {
        width: '90%'
    },
    inputFilterStatusPembayaran: {
        width: '90%'
    },
    inputFilterNamaBarang: {
        width: '90%'
    },
    inputFilterStatusBarang: {
        width: '90%'
    },
    inputFilterPakaiPengantaran: {
        width: '90%'
    },
    inputFilterNamaSales: {
        width: '90%'
    },
    previewPaper: {
        // textAlign: 'left',
        padding: '10px',
        width: '95%',
        margin: 'auto',
        marginTop: '100px',
        marginBottom: '50px'
    },
    previewContainer: {
        // textAlign: 'center',
        zIndex: '1',
        position: 'fixed',
        left: '0',
        right: '0',
        top: '0',
        margin: 'auto',
        backgroundColor: 'rgba(55,55,55,0.3)',
        height: '100vh',
        overflowY: 'scroll',
    },
    divider: {
        height: '10px',
        backgroundColor: 'rgba(0,0,0,0)'
    },
    dividerSection: {
        marginTop: '10px',
        marginBottom: '10px',
        width: '100%',
        border: '1px solid black'
    },
    editBarangPenjualan: {
        marginRight: '30px',
        width: '350px',
    },
    editJumlahBarang: {
        marginRight: '50px',
        width: '200px',
    },
    editHargaBarang: {
        marginRight: '50px',
        width: '200px',
    },
    editHargaTotal: {
        marginRight: '50px',
        width: '200px',
    },
    disabledInput: {
        "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.8)"
        }
    }
}))


const statusBarangPenjualan = ['On The Way', 'Available', 'Taken', 'Delivered', '(Instant) Delivered', 'Cancel']

export default function SuratJalanSetup() {
    const classes = useStyles();
    const [filterIdBon, setFilterIdBon] = React.useState("")
    const [filterNomorBon, setFilterNomorBon] = React.useState("")
    const [filterTanggal, setFilterTanggal] = React.useState(new Date().getTime().toString())
    const [filterDeadline, setFilterDeadline] = React.useState(null)
    const [filterCustomer, setFilterCustomer] = React.useState("")
    const [filterJenisCustomer, setFilterJenisCustomer] = React.useState("semua")
    const [filterStatusPengantaran, setFilterStatusPengantaran] = React.useState("semua")
    const [filterStatusPembayaran, setFilterStatusPembayaran] = React.useState("semua")
    const [filterBarang, setFilterBarang] = React.useState("semua")
    const [filterStatusBarang, setFilterStatusBarang] = React.useState("semua")
    const [filterNamaSales, setFilterNamaSales] = React.useState("semua")
    const [filterSortBy, setFilterSortBy] = React.useState("no_bon_penjualan-DESC")

    const [listItem, setListItem] = React.useState([])
    const [listSales, setListSales] = React.useState([])
    const [listBon, setListBon] = React.useState(null)

    const [isFetchingItemData, setIsFetchingItemData] = React.useState(true)
    const [isFetchingSalesData, setIsFetchingSalesData] = React.useState(true)
    const [isFetchingBonData, setIsFetchingBonData] = React.useState(false)

    const [currentEcerPageNumber, setCurrentEcerPageNumber] = React.useState(1)
    const [currentTokoPageNumber, setCurrentTokoPageNumber] = React.useState(1)
    const [currentTukangPageNumber, setCurrentTukangPageNumber] = React.useState(1)

    const [listEcerPageButton, setListEcerPageButton] = React.useState([])
    const [listTokoPageButton, setListTokoPageButton] = React.useState([])
    const [listTukangPageButton, setListTukangPageButton] = React.useState([])

    const [isPreviewing, setIsPreviewing] = React.useState(false)
    const [previewData, setPreviewData] = React.useState(null)
    const [defaultPreviewData, setDefaultPreviewData] = React.useState(null)

    const [expandGroupName, setExpandGroupName] = React.useState("")

    const [isNotEditing, setIsNotEditing] = React.useState(true)
    const [isSubmittingEditedData, setIsSubmittingEditedData] = React.useState(false)

    const [dialogProperties, setDialogProperties] = React.useState({
        isOpen: false, dialogTitle: "", dialogContent: "", dialogFunction: ""
    })

    const [dialogPrintProperties, setDialogPrintProperties] = React.useState({
        isOpen: false, dialogTitle: "", dialogContent: ""
    })

    const [printPassword, setPrintPassword] = React.useState("");

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        setIsFetchingItemData(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_cat_sales/get_info_barang_for_sell`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            }
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsFetchingItemData(false)
                return
            }
            return res.json()
        }).then((res) => {
            res.data.push({ id_barang: '(Custom) Seng Spandek', harga_toko: 1000, harga_ecer: 2000, harga_tukang: 1500 })
            res.data.push({ id_barang: '(Custom) Seng Genteng', harga_toko: 1000, harga_ecer: 2000, harga_tukang: 1500 })
            res.data.push({ id_barang: 'semua' })
            setListItem(res.data)
            setIsFetchingItemData(false)
        }).catch((ex) => {
            alert(ex)
            setIsFetchingItemData(false)
        })
    }, [])

    useEffect(() => {
        setIsFetchingSalesData(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_cat_sales/get_all_sales`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            }
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsFetchingItemData(false)
                return
            }
            return res.json()
        }).then((jsonData) => {
            jsonData.data.push({ id_user: '-' })
            jsonData.data.push({ id_user: 'semua' })
            setListSales(jsonData.data)
            setIsFetchingSalesData(false)
        }).catch(ex => {
            alert(ex)
            setIsFetchingSalesData(false)
        })
    }, [])

    useEffect(() => { getListBon() }, [currentEcerPageNumber, currentTokoPageNumber, currentTukangPageNumber])

    function accordionHandleChange(groupName) {
        if (expandGroupName === groupName) setExpandGroupName("")
        else setExpandGroupName(groupName)
    }

    function getFilterTanggalValue() {
        if (filterTanggal !== null) {
            const dateStringSplit = new Date(parseInt(filterTanggal)).toLocaleDateString("id-ID", { month: '2-digit', day: '2-digit', year: 'numeric' }).split("/")
            return `${dateStringSplit[2]}-${dateStringSplit[1]}-${dateStringSplit[0]}`
        }
        else return ""
    }

    function getTotalHargaBon() {
        let totalHarga = 0;
        previewData[2].forEach((val, idx, arr) => {
            if (val.status_barang === "Cancel") return;
            const totalHargaPerItem = val.jumlah_barang * previewData[1].find((val1, idx1, arr1) => val.id_detail_barang.includes(val1.id_barang_penjualan + "_")).harga_barang;
            totalHarga += totalHargaPerItem
        })
        totalHarga += previewData[0].biaya_pengantaran;
        return totalHarga
    }

    function getFilterDeadlineValue() {
        if (filterDeadline !== null) {
            const dateStringSplit = new Date(parseInt(filterDeadline)).toLocaleDateString("id-ID", { month: '2-digit', day: '2-digit', year: 'numeric' }).split("/")
            return `${dateStringSplit[2]}-${dateStringSplit[1]}-${dateStringSplit[0]}`
        }
        else return ""
    }

    function getPreviewDeadlineValue() {
        if (previewData[0].deadline !== "-") {
            const dateStringSplit = new Date(parseInt(previewData[0].deadline)).toLocaleDateString("id-ID", { month: '2-digit', day: '2-digit', year: 'numeric' }).split("/")
            return `${dateStringSplit[2]}-${dateStringSplit[1]}-${dateStringSplit[0]}`
        }
        else return ""
    }

    function getListBon(ev, isDefault = false) {
        const bodyData = isDefault ? { filterIdBon: "", filterNomorBon: "", filterJenisCustomer: "semua", filterNamaSales: "semua", filterSortBy: "no_bon_penjualan-DESC", filterTanggal: null, filterDeadline: null, filterStatusBarang: "semua", filterStatusPengantaran: "semua", filterCustomer: "", filterStatusPembayaran: "semua", filterBarang: "semua" } : { filterIdBon: filterIdBon, filterNomorBon: filterNomorBon, filterJenisCustomer: filterJenisCustomer, filterNamaSales: filterNamaSales, filterSortBy: filterSortBy, filterTanggal: filterTanggal, filterDeadline: filterDeadline, filterStatusBarang: filterStatusBarang, filterStatusPengantaran: filterStatusPengantaran, filterCustomer: filterCustomer, filterStatusPembayaran: filterStatusPembayaran, filterBarang: filterBarang }
        setIsFetchingBonData(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_cat_sales/get_bon_penjualan_cat?ecerPageNumber=${currentEcerPageNumber}&tokoPageNumber=${currentTokoPageNumber}&tukangPageNumber=${currentTukangPageNumber}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
            body: JSON.stringify(bodyData)
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsFetchingBonData(false)
                return
            }
            return res.json()
        }).then((res) => {
            if (!res["success"]) throw (new Error(res["message"]))
            else {
                setListBon(res.data)
                const newListEcerPageButton = [];
                for (let i = 0; i < res.data.ecerPages; i++) {
                    const index = i + 1
                    newListEcerPageButton.push(
                        <Button key={"ecerButton" + index} className="next-page" color={currentEcerPageNumber === index ? "primary" : "default"} style={{ marginTop: "5px", marginLeft: "5px" }} variant="contained" onClick={(ev) => setCurrentEcerPageNumber(index)}> {index}</Button >
                    )
                }
                const newListTokoPageButton = [];
                for (let i = 0; i < res.data.tokoPages; i++) {
                    const index = i + 1
                    newListTokoPageButton.push(
                        <Button key={"tokoButton" + index} className="next-page" color={currentTokoPageNumber === index ? "primary" : "default"} style={{ marginTop: "5px", marginLeft: "5px" }} variant="contained" onClick={(ev) => setCurrentTokoPageNumber(index)}> {index}</Button >
                    )
                }
                const newListTukangPageButton = [];
                for (let i = 0; i < res.data.tukangPages; i++) {
                    const index = i + 1
                    newListTukangPageButton.push(
                        <Button key={"tukangButton" + index} className="next-page" color={currentTukangPageNumber === index ? "primary" : "default"} style={{ marginTop: "5px", marginLeft: "5px" }} variant="contained" onClick={(ev) => setCurrentTukangPageNumber(index)}> {index}</Button >
                    )
                }
                setListEcerPageButton(newListEcerPageButton)
                setListTokoPageButton(newListTokoPageButton)
                setListTukangPageButton(newListTukangPageButton)
                setIsFetchingBonData(false)
            }
        }).catch(ex => {
            alert(ex)
            setIsFetchingBonData(false)
        })
    }

    function getDetailBonPenjualan(id_bon_penjualan) {
        setPreviewData(null)
        setDefaultPreviewData(null)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_cat_sales/get_detail_bon_penjualan/${id_bon_penjualan}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            }
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                return
            }
            return res.json()
        }).then((res) => {
            if (!res["success"]) throw (new Error(res["message"]))
            const jsonData = JSON.stringify(res.data)
            setDefaultPreviewData(JSON.parse(jsonData))
            setPreviewData(JSON.parse(jsonData))
        }).catch(ex => {
            alert(ex)
            setIsPreviewing(false)
        })
    }

    function updateBonPenjualan() {
        if (previewData[1].find((val, idx, arr) => {
            if (previewData[2].filter((filterVal, filterIdx, filterArr) => filterVal.id_detail_barang.includes(val.id_barang_penjualan + "_")).reduce((a, b) => a + b.jumlah_barang, 0) !== val.jumlah_barang) {
                alert(`Masih Ada Sisa Kuota Barang Terjual yang Tidak di-Input Pada Rincian Penjualan - ${val.id_barang}`)
                return true
            }
            else if (val.harga_barang === 0) {
                alert(`${val.id_barang}\nTerdapat Barang dengan Harga Penjualan - 0 (nol)`)
                return true
            }
            else return false
        })) return;
        if (previewData[2].find((val, idx, arr) => val.jumlah_barang === 0)) return alert("Terdapat Rincian dengan Jumlah Barang 0\nTidak Boleh Ada Jumlah Barang 0 pada Rincian")
        else if (getTotalHargaBon() < previewData[0].pembayaran_cash + previewData[0].pembayaran_transfer + previewData[0].pembayaran_discount) return alert("Pembayaran Berlebihan\nMohon Pembayaran Di-Update atau Di-Ubah")

        const bodyData = { updated_bon: previewData }
        setIsSubmittingEditedData(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_cat_sales/update_bon_penjualan`, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
            body: JSON.stringify(bodyData)
        }).then((res) => {
            return res.json()
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                return
            }
            if (!res["success"]) throw (new Error(res["message"]))
            else {
                getListBon()
                getDetailBonPenjualan(previewData[0].id_bon_penjualan)
                setIsSubmittingEditedData(false)
                alert(res["message"])
            }
        }).catch((ex) => {
            alert(ex)
            setIsSubmittingEditedData(false)
            setIsPreviewing(false)
        })
    }

    function getBackgroundColorPerRow(val) {
        if (val.status_pembayaran) {
            if (val.list_status_barang.includes("Available") || val.list_status_barang.includes("On The Way")) return { backgroundColor: "#EDE275" }
            else return { backgroundColor: "#AAF0D1" }
        }
        else {
            if (val.list_status_barang.includes("Available") || val.list_status_barang.includes("On The Way")) return { backgroundColor: "White" }
            else return { backgroundColor: "#C24641" }
        }
    }

    function shortcutFinishPaymentWith(paymentType) {
        let temp = []
        temp = temp.concat(previewData);

        const totalHargaBon = getTotalHargaBon();
        const totalPembayaran = temp[0].pembayaran_cash + temp[0].pembayaran_transfer + temp[0].pembayaran_discount;
        const totalYangBelumTerbayar = totalHargaBon - totalPembayaran;
        if (totalYangBelumTerbayar === 0) return alert("Bon Sudah Lunas")

        if (paymentType === "cash") temp[0].pembayaran_cash += totalYangBelumTerbayar;
        else if (paymentType === "transfer") temp[0].pembayaran_transfer += totalYangBelumTerbayar;
        else if (paymentType === "discount") temp[0].pembayaran_discount += totalYangBelumTerbayar;

        setPreviewData(temp)
        updateBonPenjualan()
    }

    function shortcutFinishStatusItem() {
        let temp = []
        temp = temp.concat(previewData);
        const new_status_barang = temp[0].pakai_pengantaran === 1 ? "Delivered" : "Taken"

        for (let i = 0; i < temp[2].length; i++) {
            temp[2][i].status_barang = new_status_barang;
        }

        setPreviewData(temp)
        updateBonPenjualan()
    }

    function shortcutCancelTheOrder() {
        let temp = []
        temp = temp.concat(previewData);

        for (let i = 0; i < temp[2].length; i++) {
            temp[2][i].status_barang = "Cancel";
        }

        temp[0].pembayaran_cash = 0;
        temp[0].pembayaran_transfer = 0;
        temp[0].pembayaran_discount = 0;
        temp[0].biaya_pengantaran = 0
        setPreviewData(temp)
        updateBonPenjualan()
    }

    return (
        <div className={classes.salesSetupContainer}>
            <Dialog
                open={dialogProperties.isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{dialogProperties.dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogProperties.dialogContent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" variant="contained" onClick={(ev) => {
                        setDialogProperties({
                            isOpen: false,
                            dialogTitle: "",
                            dialogContent: "",
                            dialogFunction: "",
                        })
                    }}>
                        Tidak
                    </Button>
                    <Button color="primary" variant="contained" autoFocus onClick={(ev) => {
                        if (dialogProperties.dialogFunction === "finishPaymentCash") shortcutFinishPaymentWith("cash")
                        else if (dialogProperties.dialogFunction === "finishPaymentTransfer") shortcutFinishPaymentWith("transfer")
                        else if (dialogProperties.dialogFunction === "finishPaymentDiscount") shortcutFinishPaymentWith("discount")
                        else if (dialogProperties.dialogFunction === "finishStatusItem") shortcutFinishStatusItem()
                        else if (dialogProperties.dialogFunction === "cancelTheOrder") shortcutCancelTheOrder()

                        setDialogProperties({
                            isOpen: false,
                            dialogTitle: "",
                            dialogContent: "",
                            dialogFunction: "",
                        })
                    }}>
                        Iya
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogPrintProperties.isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xl"
            >
                <DialogTitle id="alert-dialog-title">{dialogPrintProperties.dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <TextField type='password' onChange={(ev) => setPrintPassword(ev.target.value)} label={dialogPrintProperties.dialogContent} value={printPassword} style={{ width: '100%', color: 'black' }} />
                    </DialogContentText>
                    <div style={{ display: 'block' }}><ComponentToPrint ref={componentRef} bonData={defaultPreviewData} /></div>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="contained" autoFocus onClick={(ev) => {
                        if (printPassword == new Date().toLocaleDateString('id-ID') + "/" + new Date().getHours() + "™") handlePrint()
                        else alert("UN-AUTHORIZED ACCESS DETECTED !!! SENDING MESSAGE TO SERVER !!!")

                        setDialogPrintProperties({
                            isOpen: false,
                            dialogTitle: "",
                            dialogContent: ""
                        })
                        setPrintPassword("")
                    }}>
                        Print
                    </Button>
                    <Button color="secondary" variant="contained" onClick={(ev) => {
                        setDialogPrintProperties({
                            isOpen: false,
                            dialogTitle: "",
                            dialogContent: ""
                        })
                        setPrintPassword("")
                    }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {isPreviewing ? <div className={classes.previewContainer}><Paper className={classes.previewPaper}>
                {previewData == null || isSubmittingEditedData ? <CircularProgress style={{ marginLeft: '46%' }} /> : <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                            <TextField className={classes.disabledInput} label={"ID Bon"} value={previewData[0].id_bon_penjualan} style={{ width: '100%', color: 'black' }} disabled />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField className={classes.disabledInput} label={"NO Bon"} value={previewData[0].no_bon_penjualan} style={{ width: '100%' }} disabled />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField className={classes.disabledInput} label={"Tanggal"} value={new Date(parseInt(previewData[0].tanggal)).toLocaleDateString('id-ID', { year: "numeric", month: "long", day: "numeric", weekday: "long" })} style={{ width: '100%' }} disabled />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <><TextField className={classes.disabledInput} label={"Deadline"} value={getPreviewDeadlineValue()} style={{ width: '50%' }} disabled={isNotEditing} type="date" InputLabelProps={{ shrink: true }} onChange={(ev) => {
                                let temp = []
                                temp = temp.concat(previewData)
                                temp[0].deadline = new Date(ev.target.value).getTime().toString();
                                setPreviewData(temp)
                            }} />
                                <Button disabled={isNotEditing} onClick={(ev) => {
                                    let temp = []
                                    temp = temp.concat(previewData)
                                    temp[0].deadline = "-";
                                    setPreviewData(temp)
                                }} className={classes.clearFilterDeadline} color='secondary' startIcon={<ClearIcon />}>Remove Deadline</Button></> : null}
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField className={classes.disabledInput} label={"Nama Customer"} value={previewData[0].id_customer} style={{ width: '100%' }} disabled />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField className={classes.disabledInput} label={"Telp Customer"} value={previewData[0].telp_customer} style={{ width: '100%' }} disabled />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField className={classes.disabledInput} label={"Alamat Customer"} value={previewData[0].alamat_customer} style={{ width: '100%' }} disabled />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Autocomplete
                                disabled={isNotEditing}
                                options={listSales.map((val) => val.id_user)}
                                value={previewData[0].id_sales}
                                onChange={(ev, val) => {
                                    let temp = []
                                    temp = temp.concat(previewData)
                                    temp[0].id_sales = val;
                                    setPreviewData(temp)
                                }}
                                renderInput={(params) => <TextField className={classes.disabledInput} {...params} label="Sales" />}
                            />
                        </Grid>
                    </Grid>
                    <Divider className={classes.dividerSection} />
                    {previewData[1].map((val, idx, arr) => {
                        return (
                            <Accordion key={val.id_barang_penjualan}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <FormControlLabel
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        control={<Autocomplete
                                            className={classes.editBarangPenjualan}
                                            options={listItem}
                                            getOptionLabel={(opt) => opt.id_barang}
                                            getOptionSelected={(opt, val) => opt.id_barang === val.id_barang}
                                            value={val}
                                            renderInput={(params) => <TextField className={classes.disabledInput} {...params} label={`Barang ${idx + 1}`} />}
                                            disabled
                                        />}
                                    />
                                    <FormControlLabel
                                        className={classes.editJumlahBarang}
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        control={
                                            <TextField className={classes.disabledInput} label={"Quantity"} type={"number"} value={val.jumlah_barang} fullWidth disabled />
                                        }
                                    />
                                    {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <FormControlLabel
                                        className={classes.editHargaBarang}
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        control={
                                            <TextField className={classes.disabledInput} label={"Harga"} type={"number"} value={val.harga_barang} disabled={isNotEditing} fullWidth onChange={(ev) => {
                                                if (parseInt(ev.target.value < 0)) return
                                                let temp = []
                                                temp = temp.concat(previewData)
                                                temp[1][idx].harga_barang = parseInt(ev.target.value);
                                                setPreviewData(temp)
                                            }} />
                                        }
                                    /> : null}
                                    {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <FormControlLabel
                                        className={classes.editHargaTotal}
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        control={
                                            <TextField className={classes.disabledInput} label={"Total"} type={"number"} value={val.harga_barang * val.jumlah_barang} fullWidth disabled />
                                        }
                                    /> : null}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        {previewData[2].filter((detailVal, detailIdx, detailArr) => detailVal.id_detail_barang.includes(val.id_barang_penjualan + "_")).map((detailVal, detailIdx, detailArr) => {
                                            return (
                                                <Grid key={detailVal.id_detail_barang} item container xs={12} sm={12}>
                                                    <Grid item xs={12} sm={2}><Typography style={{ paddingTop: '10px' }} variant="h6">Rincian {detailIdx + 1}</Typography></Grid>
                                                    <Grid item xs={12} sm={3}><TextField className={classes.disabledInput} label={"Quantity"} type={"number"} value={detailVal.jumlah_barang} disabled={isNotEditing} style={{ width: '90%' }} onChange={(ev) => {
                                                        if (ev.target.value < 0) return;
                                                        else if (val.jumlah_barang - ev.target.value < detailArr.reduce((a, b) => a + b.jumlah_barang, 0) - detailVal.jumlah_barang) return alert("Quantity Melebihi Jumlah Pembelian")
                                                        let temp = [];
                                                        temp = temp.concat(previewData);
                                                        previewData[2][previewData[2].findIndex((dataVal, dataIdx, dataArr) => dataVal.id_detail_barang === detailVal.id_detail_barang)].jumlah_barang = parseInt(ev.target.value)
                                                        setPreviewData(temp);
                                                    }} /></Grid>
                                                    <Grid item xs={12} sm={3}><TextField className={classes.disabledInput} label={"Sisa Kuota"} type={"number"} value={val.jumlah_barang - detailArr.reduce((a, b) => a + b.jumlah_barang, 0)} disabled style={{ width: '90%' }} /></Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <FormControl className={classes.disabledInput} disabled={isNotEditing} style={{ width: '90%' }}>
                                                            <InputLabel>Status Barang</InputLabel>
                                                            <Select value={detailVal.status_barang} onChange={(ev) => {
                                                                let temp = [];
                                                                temp = temp.concat(previewData);
                                                                temp[2][previewData[2].findIndex((dataVal, dataIdx, dataArr) => dataVal.id_detail_barang === detailVal.id_detail_barang)].status_barang = ev.target.value
                                                                setPreviewData(temp);
                                                            }}>
                                                                {statusBarangPenjualan.map((statusVal, statusIdx, statusArr) => {
                                                                    return (<MenuItem key={`rincian_status_${detailVal.id_detail_barang}_${statusIdx}`} value={statusVal}>{statusVal}</MenuItem>)
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    {defaultPreviewData[2].find((findVal, findIdx, findArr) => findVal.id_detail_barang === detailVal.id_detail_barang) ? null : <Grid key={`rincian_remove_button_${idx}`} item xs={1}>
                                                        <Button variant="contained" color="secondary" onClick={(ev) => {
                                                            let temp = [];
                                                            temp = temp.concat(previewData);
                                                            temp[2].splice(previewData[2].findIndex((dataVal, dataIdx, dataArr) => dataVal.id_detail_barang === detailVal.id_detail_barang), 1)
                                                            setPreviewData(temp)
                                                        }}>Remove Detail</Button>
                                                    </Grid>}
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                    <Button variant={"contained"} color={"primary"} disabled={isNotEditing} onClick={(ev) => {
                                        let temp = []
                                        temp = temp.concat(previewData);
                                        const newIdDetailBarang = val.id_barang_penjualan + "_" + temp[2].length;
                                        temp[2].push({ id_detail_barang: newIdDetailBarang, jumlah_barang: 0, status_barang: "Available" })
                                        console.log(temp)
                                        setPreviewData(temp)
                                    }}>Add More Details</Button>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                    <Divider className={classes.dividerSection} />
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={2}>
                            <FormControlLabel className={classes.disabledInput} control={<Checkbox checked={previewData[0].pakai_pengantaran === 1 ? true : false} />} label="Pakai Pegantaran" disabled={isNotEditing} onChange={(ev) => {
                                let temp = []
                                temp = temp.concat(previewData)
                                temp[0].pakai_pengantaran = ev.target.checked ? 1 : 0;
                                if (!ev.target.checked) {
                                    temp[0].biaya_pengantaran = 0;
                                    temp[0].alamat_pengantaran = "-";
                                }
                                setPreviewData(temp)
                            }} />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <TextField className={classes.disabledInput} label="Alamat Pegantaran" fullWidth value={previewData[0].alamat_pengantaran} disabled={isNotEditing || previewData[0].pakai_pengantaran !== 1} onChange={(ev) => {
                                let temp = []
                                temp = temp.concat(previewData)
                                temp[0].alamat_pengantaran = ev.target.value;
                                setPreviewData(temp)
                            }} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField className={classes.disabledInput} label="Biaya Pegantaran" fullWidth type="number" value={previewData[0].biaya_pengantaran} disabled={isNotEditing || previewData[0].pakai_pengantaran !== 1} onChange={(ev) => {
                                if (parseInt(ev.target.value < 0)) return
                                let temp = []
                                temp = temp.concat(previewData)
                                temp[0].biaya_pengantaran = parseInt(ev.target.value);
                                setPreviewData(temp)
                            }} />
                            <Typography>{toWords.convert(previewData[0].biaya_pengantaran)}</Typography>
                        </Grid>
                        {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <><Grid item xs={12} sm={3}>
                            <TextField className={classes.disabledInput} label="Total Pembayaran - Rp" fullWidth disabled value={getTotalHargaBon().toLocaleString('id-ID')} />
                            <Typography>{toWords.convert(getTotalHargaBon())}</Typography>
                        </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField className={classes.disabledInput} label="Pembayaran Cash - Rp" fullWidth type="number" value={previewData[0].pembayaran_cash} disabled={isNotEditing} onChange={(ev) => {
                                    if (parseInt(ev.target.value < 0)) return
                                    if (getTotalHargaBon() < parseInt(ev.target.value) + previewData[0].pembayaran_transfer + previewData[0].pembayaran_discount) return alert("Pembayaran Berelebih")
                                    let temp = []
                                    temp = temp.concat(previewData)
                                    temp[0].pembayaran_cash = parseInt(ev.target.value);
                                    setPreviewData(temp)
                                }} />
                                <Typography>{toWords.convert(previewData[0].pembayaran_cash)}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField className={classes.disabledInput} label="Pembayaran Transfer - Rp" fullWidth type="number" value={previewData[0].pembayaran_transfer} disabled={isNotEditing} onChange={(ev) => {
                                    if (parseInt(ev.target.value < 0)) return
                                    if (getTotalHargaBon() < parseInt(ev.target.value) + previewData[0].pembayaran_cash + previewData[0].pembayaran_discount) return alert("Pembayaran Berelebih")
                                    let temp = []
                                    temp = temp.concat(previewData)
                                    temp[0].pembayaran_transfer = parseInt(ev.target.value);
                                    setPreviewData(temp)
                                }} />
                                <Typography>{toWords.convert(previewData[0].pembayaran_transfer)}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField className={classes.disabledInput} label="Diskon - Rp" fullWidth type="number" value={previewData[0].pembayaran_discount} disabled={isNotEditing} onChange={(ev) => {
                                    if (parseInt(ev.target.value < 0)) return
                                    if (getTotalHargaBon() < parseInt(ev.target.value) + previewData[0].pembayaran_cash + previewData[0].pembayaran_transfer) return alert("Pembayaran Berelebih")
                                    let temp = []
                                    temp = temp.concat(previewData)
                                    temp[0].pembayaran_discount = parseInt(ev.target.value);
                                    setPreviewData(temp)
                                }} />
                                <Typography>{toWords.convert(previewData[0].pembayaran_discount)}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField className={classes.disabledInput} color='secondary' label="Total Kredit - Rp" fullWidth disabled value={(getTotalHargaBon() - previewData[0].pembayaran_cash - previewData[0].pembayaran_transfer - previewData[0].pembayaran_discount).toLocaleString('id-ID')} />
                                <Typography color='secondary'>{toWords.convert(getTotalHargaBon() - previewData[0].pembayaran_cash - previewData[0].pembayaran_transfer - previewData[0].pembayaran_discount)}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <TextField className={classes.disabledInput} label="Keterangan Tambahan" fullWidth value={previewData[0].keterangan} disabled={isNotEditing} onChange={(ev) => {
                                    let temp = []
                                    temp = temp.concat(previewData)
                                    temp[0].keterangan = ev.target.value;
                                    setPreviewData(temp)
                                }} />
                            </Grid></> : null}
                    </Grid>
                    <Divider className={classes.dividerSection} />
                    <Typography variant="h6">List Shortcut</Typography>
                    {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <Button style={{ marginRight: '20px', marginBottom: '20px', backgroundColor: "#98fb98" }} variant={"contained"} disabled={getTotalHargaBon() - previewData[0].pembayaran_cash - previewData[0].pembayaran_transfer - previewData[0].pembayaran_discount === 0} onClick={(ev) => {
                        setDialogProperties({
                            dialogTitle: "Confirm Finish Payment With Transfer",
                            dialogContent: "Apakah Anda Yakin Untuk Menyelesaikan Pembayaran Bon Ini Melalui Transfer",
                            dialogFunction: "finishPaymentTransfer",
                            isOpen: true,
                        })
                    }}>Selesaikan Pembayaran Melalui Transfer</Button> : null}

                    {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <Button style={{ marginRight: '20px', marginBottom: '20px', backgroundColor: "#98fb98" }} variant={"contained"} disabled={getTotalHargaBon() - previewData[0].pembayaran_cash - previewData[0].pembayaran_transfer - previewData[0].pembayaran_discount === 0} onClick={(ev) => {
                        setDialogProperties({
                            dialogTitle: "Confirm Finish Payment With Cash",
                            dialogContent: "Apakah Anda Yakin Untuk Menyelesaikan Pembayaran Bon Ini Melalui Cash",
                            dialogFunction: "finishPaymentCash",
                            isOpen: true,
                        })
                    }}>Selesaikan Pembayaran Melalui Cash</Button> : null}

                    {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <Button style={{ marginRight: '20px', marginBottom: '20px', backgroundColor: "#98fb98" }} variant={"contained"} disabled={getTotalHargaBon() - previewData[0].pembayaran_cash - previewData[0].pembayaran_transfer - previewData[0].pembayaran_discount === 0} onClick={(ev) => {
                        setDialogProperties({
                            dialogTitle: "Confirm Finish Payment With Discount",
                            dialogContent: "Apakah Anda Yakin Untuk Menyelesaikan Pembayaran Bon Ini Melalui Discount",
                            dialogFunction: "finishPaymentDiscount",
                            isOpen: true,
                        })
                    }}>Selesaikan Pembayaran Melalui Diskon</Button> : null}

                    <Button style={{ marginRight: '20px', marginBottom: '20px', backgroundColor: "#FFD700" }} variant={"contained"} disabled={!previewData[2].find((val, idx, arr) => val.status_barang === "On The Way" || val.status_barang === "Available")} onClick={(ev) => {
                        setDialogProperties({
                            dialogTitle: "Confirm Finish Delivery/Taken All Item",
                            dialogContent: "Apakah Anda Yakin Untuk Menyelesaikan Pengantaran/Pengambilan Semua Item Pada Bon",
                            dialogFunction: "finishStatusItem",
                            isOpen: true,
                        })
                    }}>Selesaikan Pengantaran / Pengambilan</Button>

                    {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <Button style={{ marginRight: '20px', marginBottom: '20px' }} variant={"contained"} color={"secondary"} onClick={(ev) => {
                        setDialogProperties({
                            dialogTitle: "Confirm To Cancel The Order",
                            dialogContent: "Apakah Anda Yakin Untuk Melakukan Pembatalan Pada Bon",
                            dialogFunction: "cancelTheOrder",
                            isOpen: true,
                        })
                    }}>Cancel Bon / Batalkan Bon</Button> : null}

                    <Divider className={classes.dividerSection} />
                    <Button variant="contained" color="primary" style={{ marginRight: '20px' }} onClick={(ev) => {
                        setDialogPrintProperties({
                            dialogInput: "",
                            dialogTitle: "Need Access",
                            dialogContent: "Input Code For Print",
                            isOpen: true,
                        })
                    }}>
                        Print This Bon
                    </Button>
                    {isNotEditing ? <Button variant="contained" color="primary" style={{ marginRight: '20px' }} onClick={(ev) => setIsNotEditing(false)}>Update This Bon</Button> :
                        <>
                            <Button variant="contained" color="primary" style={{ marginRight: '20px' }} onClick={(ev) => {
                                updateBonPenjualan()
                            }}>Submit Update</Button>
                            <Button variant="contained" color="secondary" style={{ marginRight: '20px' }} onClick={(ev) => {
                                const jsonData = JSON.stringify(defaultPreviewData)
                                setPreviewData(JSON.parse(jsonData))
                                setIsNotEditing(true)
                            }}>Cancel Update</Button>
                        </>}
                    <Button variant="contained" color="secondary" style={{ marginRight: '20px' }} onClick={(ev) => {
                        setIsPreviewing(false)
                        setDefaultPreviewData(null)
                        setPreviewData(null)
                        setIsNotEditing(true)
                    }}>
                        Close Preview
                    </Button>
                </>}
            </Paper ></div > : null
            }
            <Paper className={classes.salesSetupForm}>
                <Typography variant="h5">Atur Bon Penjualan Toko Cat</Typography>
                {isFetchingSalesData || isFetchingItemData ? <CircularProgress /> :
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography style={{ margin: 'auto' }} variant="h6">FILTER PENCARIAN BON</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={3}>
                                    <TextField className={classes.inputFilterCustomer} label={"Nama Customer"} value={filterCustomer} onChange={(ev) => setFilterCustomer(ev.target.value)} />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField className={classes.inputFilterIdBon} label={"ID Bon"} value={filterIdBon} onChange={(ev) => setFilterIdBon(ev.target.value)} />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField className={classes.inputFilterNomorBon} label={"Nomor Bon"} type="number" value={filterNomorBon} onChange={(ev) => setFilterNomorBon(ev.target.value)} />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField className={classes.inputFilterTanggal} label={"Tanggal"} value={getFilterTanggalValue()} onChange={(ev) => setFilterTanggal(new Date(ev.target.value).getTime().toString())} type="date" InputLabelProps={{ shrink: true }} />
                                    <Button onClick={(ev) => setFilterTanggal(null)} className={classes.clearFilterTanggal} color='secondary' startIcon={<ClearIcon />}>Clear</Button>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControl className={classes.inputFilterCustomer}>
                                        <InputLabel>Jenis Customer</InputLabel>
                                        <Select value={filterJenisCustomer} onChange={(ev) => setFilterJenisCustomer(ev.target.value)}>
                                            <MenuItem value={"ecer"}>Ecer</MenuItem>
                                            <MenuItem value={"toko"}>Toko</MenuItem>
                                            <MenuItem value={"tukang"}>Tukang</MenuItem>
                                            <MenuItem value={"semua"}>Semua</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Autocomplete
                                        className={classes.inputFilterNamaBarang}
                                        options={listItem.map((val) => val.id_barang)}
                                        value={filterBarang}
                                        onChange={(ev, val) => setFilterBarang(val)}
                                        renderInput={(params) => <TextField {...params} label="Barang" />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControl className={classes.inputFilterStatusBarang}>
                                        <InputLabel>Status Barang</InputLabel>
                                        <Select value={filterStatusBarang} onChange={(ev) => setFilterStatusBarang(ev.target.value)}>
                                            {statusBarangPenjualan.map((statusVal, statusIdx, statusArr) => {
                                                return (<MenuItem key={statusIdx} value={statusVal}>{statusVal}</MenuItem>)
                                            })}
                                            <MenuItem value={"semua"}>Semua</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <Grid item xs={12} sm={3}>
                                    <TextField className={classes.inputFilterDeadline} label={"Deadline <="} value={getFilterDeadlineValue()} onChange={(ev) => setFilterDeadline(new Date(ev.target.value).getTime().toString())} type="date" InputLabelProps={{ shrink: true }} />
                                    <Button onClick={(ev) => setFilterDeadline(null)} className={classes.clearFilterDeadline} color='secondary' startIcon={<ClearIcon />}>Clear</Button>
                                </Grid> : null}
                                {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <Grid item xs={12} sm={3}>
                                    <FormControl className={classes.inputFilterStatusPembayaran}>
                                        <InputLabel>Status Pembayaran</InputLabel>
                                        <Select value={filterStatusPembayaran} onChange={(ev) => setFilterStatusPembayaran(ev.target.value)}>
                                            <MenuItem value={"sudah"}>Sudah Lunas</MenuItem>
                                            <MenuItem value={"belum"}>Belum Lunas</MenuItem>
                                            <MenuItem value={"semua"}>Semua</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid> : null}
                                <Grid item xs={12} sm={3}>
                                    <FormControl className={classes.inputFilterPakaiPengantaran}>
                                        <InputLabel>Status Pengantaran / Pengambilan</InputLabel>
                                        <Select value={filterStatusPengantaran} onChange={(ev) => setFilterStatusPengantaran(ev.target.value)}>
                                            <MenuItem value={"sudah"}>Sudah</MenuItem>
                                            <MenuItem value={"belum"}>Belum</MenuItem>
                                            <MenuItem value={"semua"}>Semua</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControl className={classes.inputFilterPakaiPengantaran}>
                                        <InputLabel>Urutkan Berdasarkan</InputLabel>
                                        <Select value={filterSortBy} onChange={(ev) => setFilterSortBy(ev.target.value)}>
                                            <MenuItem value={"id_customer-ASC"}>Nama Customer - Ascending</MenuItem>
                                            <MenuItem value={"id_customer-DESC"}>Nama Customer - Descending</MenuItem>
                                            <MenuItem value={"id_bon_penjualan-ASC"}>ID Bon - Ascending</MenuItem>
                                            <MenuItem value={"id_bon_penjualan-DESC"}>ID Bon - Descending</MenuItem>
                                            <MenuItem value={"no_bon_penjualan-ASC"}>Nomor Bon - Ascending</MenuItem>
                                            <MenuItem value={"no_bon_penjualan-DESC"}>Nomor Bon - Descending</MenuItem>
                                            <MenuItem value={"tanggal-ASC"}>Tanggal Bon - Ascending</MenuItem>
                                            <MenuItem value={"tanggal-DESC"}>Tanggal Bon - Descending</MenuItem>
                                            <MenuItem value={"deadline-ASC"}>Deadline Bon - Ascending</MenuItem>
                                            <MenuItem value={"deadline-DESC"}>Deadline Bon - Descending</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <Grid item xs={12} sm={3}>
                                    <Autocomplete
                                        className={classes.inputFilterSales}
                                        options={listSales.map((val) => val.id_user)}
                                        value={filterNamaSales}
                                        onChange={(ev, val) => setFilterNamaSales(val)}
                                        renderInput={(params) => <TextField {...params} label="Sales" />}
                                    />
                                </Grid> : null}
                                <Grid item xs={12} sm={3}>
                                    <Button color='primary' variant="contained" startIcon={<SearchIcon />} onClick={getListBon}>Search</Button>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Button color='secondary' variant="contained" startIcon={<ResetIcon />} onClick={(ev) => {
                                        setFilterIdBon("")
                                        setFilterNomorBon("")
                                        setFilterTanggal(null)
                                        setFilterDeadline(null)
                                        setFilterCustomer("")
                                        setFilterJenisCustomer("semua")
                                        setFilterStatusPengantaran("semua")
                                        setFilterStatusPembayaran("semua")
                                        setFilterBarang("semua")
                                        setFilterStatusBarang("semua")
                                        setFilterNamaSales("semua")
                                        setFilterSortBy("no_bon_penjualan-DESC")
                                        getListBon(ev, true)
                                    }}>Reset</Button>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>}
                {isFetchingBonData ? <CircularProgress /> : <>
                    <Accordion expanded={expandGroupName === "ecer"} onChange={(ev) => accordionHandleChange("ecer")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}><Typography variant="h6">BON PENJUALAN ECER</Typography></AccordionSummary>
                        <AccordionDetails >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nomor Bon</TableCell>
                                        <TableCell>ID Bon</TableCell>
                                        <TableCell>Customer</TableCell>
                                        {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>Total Harga</TableCell> : null}
                                        <TableCell>Pakai Pengantaran</TableCell>
                                        <TableCell>Status Pengantaran / Pengambilan</TableCell>
                                        {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>Status Pembayaran</TableCell> : null}
                                        <TableCell>List Barang</TableCell>
                                        <TableCell>Tanggal</TableCell>
                                        {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>Deadline</TableCell> : null}
                                        {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>Sales</TableCell> : null}
                                        {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>Admin</TableCell> : null}
                                        {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell></TableCell> : null}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listBon !== null ? listBon.ecer.map((val, idx, arr) => {
                                        return (
                                            <TableRow key={"ecer" + idx} style={getBackgroundColorPerRow(val)}>
                                                <TableCell>{val.no_bon_penjualan}</TableCell>
                                                <TableCell>{val.id_bon_penjualan}</TableCell>
                                                <TableCell>{val.id_customer}</TableCell>
                                                {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>Rp {parseInt(val.total_harga).toLocaleString('id-ID')}</TableCell> : null}
                                                <TableCell>{val.pakai_pengantaran ? "Pakai" : "Tidak"}</TableCell>
                                                <TableCell>{(val.list_status_barang.includes("Available") || val.list_status_barang.includes("On The Way")) ? "Belum Antar / Ambil" : "Sudah Antar / Ambil"}</TableCell>
                                                {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>{val.status_pembayaran ? "Sudah Lunas" : "Belum Lunas"}</TableCell> : null}
                                                <TableCell>{val.list_barang}</TableCell>
                                                <TableCell>{new Date(parseInt(val.tanggal)).toLocaleDateString('id-ID', { year: "numeric", month: "long", day: "numeric", weekday: "long" })}</TableCell>
                                                {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>{val.deadline === "-" ? "-" : new Date(parseInt(val.deadline)).toLocaleDateString('id-ID', { year: "numeric", month: "long", day: "numeric", weekday: "long" }) || "-"}</TableCell> : null}
                                                {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>{val.id_sales}</TableCell> : null}
                                                {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>{val.admin}</TableCell> : null}
                                                <TableCell><Button color='primary' variant="contained" onClick={(ev) => {
                                                    setIsPreviewing(true)
                                                    getDetailBonPenjualan(val.id_bon_penjualan)
                                                }}>Check</Button></TableCell>
                                            </TableRow>
                                        )
                                    }) : null}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                        <div style={{ padding: '20px', paddingTop: '0px' }}>
                            {listEcerPageButton}
                        </div>
                    </Accordion>
                    <Accordion expanded={expandGroupName === "toko"} onChange={(ev) => accordionHandleChange("toko")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}><Typography variant="h6">BON PENJUALAN TOKO</Typography></AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nomor Bon</TableCell>
                                        <TableCell>ID Bon</TableCell>
                                        <TableCell>Customer</TableCell>
                                        {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>Total Harga</TableCell> : null}
                                        <TableCell>Pakai Pengantaran</TableCell>
                                        <TableCell>Status Pengantaran / Pengambilan</TableCell>
                                        {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>Status Pembayaran</TableCell> : null}
                                        <TableCell>List Barang</TableCell>
                                        <TableCell>Tanggal</TableCell>
                                        {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>Deadline</TableCell> : null}
                                        {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>Sales</TableCell> : null}
                                        {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>Admin</TableCell> : null}
                                        {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell></TableCell> : null}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listBon !== null ? listBon.toko.map((val, idx, arr) => {
                                        return (
                                            <TableRow key={"toko" + idx} style={getBackgroundColorPerRow(val)}>
                                                <TableCell>{val.no_bon_penjualan}</TableCell>
                                                <TableCell>{val.id_bon_penjualan}</TableCell>
                                                <TableCell>{val.id_customer}</TableCell>
                                                {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>Rp {parseInt(val.total_harga).toLocaleString('id-ID')}</TableCell> : null}
                                                <TableCell>{val.pakai_pengantaran ? "Pakai" : "Tidak"}</TableCell>
                                                <TableCell>{(val.list_status_barang.includes("Available") || val.list_status_barang.includes("On The Way")) ? "Belum Antar / Ambil" : "Sudah Antar / Ambil"}</TableCell>
                                                {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>{val.status_pembayaran ? "Sudah Lunas" : "Belum Lunas"}</TableCell> : null}
                                                <TableCell>{val.list_barang}</TableCell>
                                                <TableCell>{new Date(parseInt(val.tanggal)).toLocaleDateString('id-ID', { year: "numeric", month: "long", day: "numeric", weekday: "long" })}</TableCell>
                                                {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>{val.deadline === "-" ? "-" : new Date(parseInt(val.deadline)).toLocaleDateString('id-ID', { year: "numeric", month: "long", day: "numeric", weekday: "long" }) || "-"}</TableCell> : null}
                                                {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>{val.id_sales}</TableCell> : null}
                                                {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>{val.admin}</TableCell> : null}
                                                <TableCell><Button color='primary' variant="contained" onClick={(ev) => {
                                                    setIsPreviewing(true)
                                                    getDetailBonPenjualan(val.id_bon_penjualan)
                                                }}>Check</Button></TableCell>
                                            </TableRow>
                                        )
                                    }) : null}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                        <div style={{ padding: '20px', paddingTop: '0px' }}>
                            {listTokoPageButton}
                        </div>
                    </Accordion>
                    <Accordion expanded={expandGroupName === "tukang"} onChange={(ev) => accordionHandleChange("tukang")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}><Typography variant="h6">BON PENJUALAN TUKANG</Typography></AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nomor Bon</TableCell>
                                        <TableCell>ID Bon</TableCell>
                                        <TableCell>Customer</TableCell>
                                        {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>Total Harga</TableCell> : null}
                                        <TableCell>Pakai Pengantaran</TableCell>
                                        <TableCell>Status Pengantaran / Pengambilan</TableCell>
                                        {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>Status Pembayaran</TableCell> : null}
                                        <TableCell>List Barang</TableCell>
                                        <TableCell>Tanggal</TableCell>
                                        {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>Deadline</TableCell> : null}
                                        {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>Sales</TableCell> : null}
                                        {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>Admin</TableCell> : null}
                                        {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell></TableCell> : null}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listBon !== null ? listBon.tukang.map((val, idx, arr) => {
                                        return (
                                            <TableRow key={"tukang" + idx} style={getBackgroundColorPerRow(val)}>
                                                <TableCell>{val.no_bon_penjualan}</TableCell>
                                                <TableCell>{val.id_bon_penjualan}</TableCell>
                                                <TableCell>{val.id_customer}</TableCell>
                                                {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>Rp {parseInt(val.total_harga).toLocaleString('id-ID')}</TableCell> : null}
                                                <TableCell>{val.pakai_pengantaran ? "Pakai" : "Tidak"}</TableCell>
                                                <TableCell>{(val.list_status_barang.includes("Available") || val.list_status_barang.includes("On The Way")) ? "Belum Antar / Ambil" : "Sudah Antar / Ambil"}</TableCell>
                                                {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>{val.status_pembayaran ? "Sudah Lunas" : "Belum Lunas"}</TableCell> : null}
                                                <TableCell>{val.list_barang}</TableCell>
                                                <TableCell>{new Date(parseInt(val.tanggal)).toLocaleDateString('id-ID', { year: "numeric", month: "long", day: "numeric", weekday: "long" })}</TableCell>
                                                {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>{val.deadline === "-" ? "-" : new Date(parseInt(val.deadline)).toLocaleDateString('id-ID', { year: "numeric", month: "long", day: "numeric", weekday: "long" }) || "-"}</TableCell> : null}
                                                {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>{val.id_sales}</TableCell> : null}
                                                {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <TableCell>{val.admin}</TableCell> : null}
                                                <TableCell><Button color='primary' variant="contained" onClick={(ev) => {
                                                    setIsPreviewing(true)
                                                    getDetailBonPenjualan(val.id_bon_penjualan)
                                                }}>Check</Button></TableCell>
                                            </TableRow>
                                        )
                                    }) : null}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                        <div style={{ padding: '20px', paddingTop: '0px' }}>
                            {listTukangPageButton}
                        </div>
                    </Accordion>
                </>}
            </Paper>
        </div >
    )
}