import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chart from '../Components/Sales/Chart';
import ChartData from '../Components/Sales/ChartData';
import Orders from '../Components/Sales/Orders';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
import { names } from '../Settings/globalVar';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    filter: {
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'row',
    },
    filterText: {
        // backgroundColor: "blue",
        padding: '20px'
    },
    fixedHeight: {
        height: 240,
    },
    itemBarang: {
        marginRight: '20px',
        width: '250px',
    },
    itemText: {
        padding: '20px',
        fontWeight: 'bold',
        // marginLeft: "10%",
        // backgroundColor: 'blue'
    }
}));

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export default function DashboardSales() {
    const classes = useStyles();
    const [item, setItem] = React.useState('Semua');
    const [tanggal, setTanggal] = React.useState({
        month_name_year: months[new Date().getMonth()] + " " + new Date().getFullYear(),
        month_year: ('0' + (new Date().getMonth() + 1)).slice(-2) + " " + new Date().getFullYear()
    });
    const [listDate, setListDate] = React.useState([]);
    const [isLoadingDate, setIsLoadingDate] = React.useState(false);
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    useEffect(() => {
        setIsLoadingDate(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/super_admin/get_sales_date_filter_list`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            }
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsLoadingDate(false)
                return
            }

            return res.json()
        }).then((res) => {
            setListDate(res.data)
            setIsLoadingDate(false)
        }).catch((ex) => {
            setIsLoadingDate(false)
            alert(ex)
        })
    }, [])

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.filter}>
                        <Typography className={classes.filterText}>Filter : </Typography>
                        {isLoadingDate ? <CircularProgress style={{ marginTop: '10px', marginRight: '250px' }} /> :
                            <Autocomplete
                                options={listDate.filter(val => val.month_name_year !== tanggal.month_name_year)}
                                getOptionSelected={(option, value) => option.month_name_year === value.month_name_year || listDate.find(val => val.month_name_year === value.month_name_year)}
                                getOptionLabel={(option) => option.month_name_year}
                                value={tanggal}
                                onChange={(ev, value) => {
                                    if (value == null) return
                                    setTanggal(value)
                                }}
                                className={classes.itemBarang}
                                renderInput={(params) => <TextField {...params} label="Date" />}
                            />}
                        <Typography className={classes.itemText}>DATA PENJUALAN {tanggal.month_name_year}</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={8} lg={9}>
                    <Paper className={fixedHeightPaper}>
                        <Chart id_barang={item} tanggal={tanggal} />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Paper className={fixedHeightPaper}>
                        <ChartData id_barang={item} tanggal={tanggal} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Orders id_barang={item} tanggal={tanggal} />
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}