import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { CircularProgress, TableCell, Table, TableRow, TableHead, Paper, TableBody, Input, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { names } from '../Settings/globalVar';

const useStyles = makeStyles(() => ({
    divider: {
        height: '25px',
        backgroundColor: 'rgba(0,0,0,0)'
    },
    itemContainer: {
        marginTop: '10px'
    },
    itemQuantity: {
        marginRight: '20px',
        width: '150px'
    },
    itemBarang: {
        marginRight: '20px',
        width: '250px'
    },
    gridItem: {
        backgroundColor: 'white',
        borderRadius: '10px',
        textAlign: 'center',
        margin: 'auto',
        marginTop: '20px',
    },
    iconButton: {
    },
    newItemButton: {
        marginTop: '10px',
        margin: 'auto'
    },
    tableRoot: {
        width: '100%',
        marginTop: '10px',
        overflowX: 'auto'
    },
    button: {
        margin: '20px',
        width: '150px',
        height: '50px'
    }
}))

const CustomTableCell = ({ row, name }) => {
    const { isEditMode } = row;
    return (
        <TableCell align="left" className="userTableCell">
            {isEditMode && name !== "id_user" && name !== "hired_date" ? (
                <Input
                    value={row[name]}
                    name={name}
                    className="input" />) : (
                row[name]
            )}
        </TableCell>
    );
};

const CreateData = (id_barang, total_stok) => ({
    id_barang,
    total_stok,
    isEditMode: false
});

export default function ItemInfo() {
    const classes = useStyles();
    const [items, setItems] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)

    const [searchFilter, setSearchFilter] = React.useState('')

    useEffect(() => {
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/sales/get_info_barang`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
        }).then(async (res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsLoading(false)
                return
            }

            const jsonData = await res.json();
            const rowsData = jsonData.data;
            const newRows = Object.values(rowsData).map(row => {
                return CreateData(row.id_barang, row.total_stok)
            })
            setItems(newRows)
            setIsLoading(false)
        }).catch((ex) => {
            alert(ex)
            setIsLoading(false)
        })
    }, [])

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={"auto"} sm={11} className={classes.gridItem}>
                    <Typography variant="h6" gutterBottom>List Barang</Typography>
                    {isLoading ? <CircularProgress /> :
                        <Paper className={classes.tableRoot}>
                            <TextField label='Filter' style={{ width: '90%', marginBottom: '10px' }} onChange={(ev) => setSearchFilter(ev.target.value)} />
                            <Table className="userTable" aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="userTableCell" align="left">No</TableCell>
                                        <TableCell className="userTableCell" align="left">ID Barang</TableCell>
                                        <TableCell className="userTableCell" align="left">Stok Ready</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items.filter((val) => {
                                        if (searchFilter === "" || searchFilter === null || searchFilter === undefined) return true
                                        else return val.id_barang.includes(searchFilter)
                                    }).map((row, idx) => (
                                        <TableRow hover key={row.id_barang} style={{ textAlign: 'center' }}>
                                            <TableCell align="left" className="userTableCell">{idx + 1}</TableCell>
                                            {CustomTableCell({ ...{ row, name: "id_barang" } })}
                                            {CustomTableCell({ ...{ row, name: "total_stok" } })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>}
                </Grid>
            </Grid>
        </React.Fragment >
    );
}