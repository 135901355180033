import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Title from './Title';
import { names } from '../../Settings/globalVar';

// const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export default function ChartData(props) {
    const [chartData, setChartData] = React.useState({})
    const [isLoading, setIsLoading] = React.useState(true);
    const { id_barang, tanggal } = props;

    useEffect(() => {
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/super_admin/get_sales_chart_total_data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
            body: JSON.stringify({ id_barang: id_barang, tanggal: tanggal.month_year })
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsLoading(false)
                return
            }
            else if (res.status === 500) throw new Error("Internal Server Error");
            return res.json()
        }).then((res) => {
            setChartData(res.data.chartData)
            setIsLoading(false)
        }).catch((ex) => {
            setIsLoading(false)
            alert(ex)
        })
    }, [id_barang, tanggal])

    return (
        <React.Fragment>
            {isLoading ? <CircularProgress style={{ margin: 'auto' }} /> : <>
                <Title>Jumlah Cash</Title>
                <Typography component="p" variant="h5" gutterBottom>
                    {chartData ? parseInt(chartData.total_cash).toLocaleString('id-ID') : 0}
                </Typography>
                <Divider />

                <Title>Jumlah Transfer</Title>
                <Typography component="p" variant="h5" gutterBottom>
                    {chartData ? parseInt(chartData.total_transfer).toLocaleString('id-ID') : 0}
                </Typography>
                <Divider />

                <Title>Jumlah Piutang</Title>
                <Typography component="p" variant="h5" gutterBottom>
                    {chartData ? parseInt(chartData.total_piutang).toLocaleString('id-ID') : 0}
                </Typography>
            </>}
        </React.Fragment>
    );
}