import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../Components/Title';
import { names } from '../Settings/globalVar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// // Generate Order Data
// function createData(id, date, name, shipTo, paymentMethod, amount) {
//     return { id, date, name, shipTo, paymentMethod, amount };
// }

// const rows = [
//     createData(0, '16 Mar, 2019', 'Elvis Presley', 'Tupelo, MS', 'VISA ⠀•••• 3719', 312.44),
//     createData(1, '16 Mar, 2019', 'Paul McCartney', 'London, UK', 'VISA ⠀•••• 2574', 866.99),
//     createData(2, '16 Mar, 2019', 'Tom Scholz', 'Boston, MA', 'MC ⠀•••• 1253', 100.81),
//     createData(3, '16 Mar, 2019', 'Michael Jackson', 'Gary, IN', 'AMEX ⠀•••• 2000', 654.39),
//     createData(4, '15 Mar, 2019', 'Bruce Springsteen', 'Long Branch, NJ', 'VISA ⠀•••• 5919', 212.79),
// ];

// function preventDefault(event) {
//     event.preventDefault();
// }

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    previewPaper: {
        // textAlign: 'left',
        padding: '10px',
        width: '600px',
        margin: 'auto',
        marginTop: '100px'
    },
    previewContainer: {
        // textAlign: 'center',
        zIndex: '1',
        position: 'fixed',
        left: '0',
        right: '0',
        top: '0',
        margin: 'auto',
        backgroundColor: 'rgba(55,55,55,0.3)',
        height: '100vh',
        overflowY: 'scroll',
    },
    userTableCell: {
        maxWidth: '100px',
        paddingRight: '0px'
    }
}));

export default function Orders(props) {
    const classes = useStyles();
    const { id_barang, tanggal } = props;
    const [day, setDay] = React.useState(('0' + new Date().getDate()).slice(-2))
    const [listBon, setListBon] = React.useState([])
    const [listPage, setListPage] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true);

    const [isPreviewing, setIsPreviewing] = React.useState(false);
    const [previewData, setPreviewData] = React.useState(null);

    function showPreview(nomor_bon) {
        setIsPreviewing(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_stok/get_bon/${nomor_bon}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            }
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsPreviewing(false)
                return
            }
            else if (res.status === 500) throw new Error("Internal Server Error");
            return res.json()
        }).then((res) => {
            setPreviewData(res.data)
        }).catch(ex => {
            setIsPreviewing(false)
            alert(ex)
        })
    }

    useEffect(() => {
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_stok/get_all_bon_with_filter`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
            body: JSON.stringify({ id_barang: id_barang, tanggal: day + " " + tanggal.month_year })
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsLoading(false)
                return
            }
            else if (res.status === 500) throw new Error("Internal Server Error");
            return res.json()
        }).then((jsonData) => {
            const rowsData = jsonData.data.allBonData;
            const pagesData = jsonData.data.pageCount;

            const newPages = []
            for (let i = 0; i < pagesData; i++) {
                const index = i + 1
                newPages.push(
                    <Button key={index} className="next-page" color={day === ('0' + index).slice(-2) ? "primary" : "default"} style={{ marginTop: "5px", marginLeft: "5px" }
                    } variant="contained" onClick={(ev) => {
                        if (day !== ('0' + index).slice(-2)) setDay(('0' + index).slice(-2))
                    }}> {index}</Button >
                )
            }

            setListBon(rowsData)
            setListPage(newPages)
            setIsLoading(false)
        }).catch((ex) => {
            setIsLoading(false)
            alert(ex)
        })
    }, [id_barang, tanggal, day])

    return (
        <React.Fragment>
            {isPreviewing ?
                <div className={classes.previewContainer}>
                    <Paper className={classes.previewPaper}>
                        {previewData === null ? <CircularProgress style={{ marginLeft: '46%' }} /> :
                            <>
                                <Typography variant="h6" gutterBottom>Nomor BON : {previewData[0].nomor_bon}</Typography>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="userTableCell" align="left">No</TableCell>
                                            <TableCell className="userTableCell" align="left">Item</TableCell>
                                            <TableCell className="userTableCell" align="left">Jumlah</TableCell>
                                            <TableCell className="userTableCell" align="left">Tanggal</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {previewData.map((val, idx, arr) => {
                                            return (
                                                <TableRow hover key={idx} style={{ textAlign: 'center' }} >
                                                    <TableCell className='userTableCell' align="left">{idx + 1}</TableCell>
                                                    <TableCell className='userTableCell' align="left">{val.id_barang}</TableCell>
                                                    <TableCell className='userTableCell' align="left">{val.jumlah_barang}</TableCell>
                                                    <TableCell className='userTableCell' align="left">{new Date(parseInt(val.tanggal)).toDateString() + ", " + new Date(parseInt(val.tanggal)).toLocaleTimeString()}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                                <Typography variant="h6" gutterBottom>Admin : {previewData[0].admin}</Typography>
                                <Typography variant="h6" gutterBottom>Keterangan : {previewData[0].keterangan}</Typography>
                                <Button variant="contained" color="primary" onClick={(ev) => {
                                    setIsPreviewing(false)
                                    setPreviewData(null)
                                }}>
                                    Close Preview
                                </Button>
                            </>}
                    </Paper>
                </div> : null}
            <Title>{id_barang} - {day} {tanggal.month_name_year} Recent Orders</Title>
            <div style={{ padding: '10px' }}>
                <Typography>
                    Tanggal :
                </Typography>
                {listPage}
            </div>
            {isLoading ? <CircularProgress /> :
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.userTableCell}>No</TableCell>
                            <TableCell className={classes.userTableCell}>No Bon</TableCell>
                            <TableCell className={classes.userTableCell}>Status</TableCell>
                            <TableCell className={classes.userTableCell}>Item</TableCell>
                            <TableCell className={classes.userTableCell}>Quantity</TableCell>
                            <TableCell className={classes.userTableCell}>Admin</TableCell>
                            <TableCell className={classes.userTableCell}>Tanggal</TableCell>
                            <TableCell className={classes.userTableCell}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listBon.map((row, idx) => (
                            <TableRow key={idx}>
                                <TableCell className={classes.userTableCell}>{idx + 1}</TableCell>
                                <TableCell className={classes.userTableCell}>{row.nomor_bon.split("_")[0]}</TableCell>
                                <TableCell className={classes.userTableCell}>{row.jenis}</TableCell>
                                <TableCell className={classes.userTableCell}>{row.id_barang}</TableCell>
                                <TableCell className={classes.userTableCell}>{row.jumlah_barang}</TableCell>
                                <TableCell className={classes.userTableCell}>{row.admin}</TableCell>
                                <TableCell className={classes.userTableCell}>{new Date(parseInt(row.tanggal)).toDateString() + ", " + new Date(parseInt(row.tanggal)).toLocaleTimeString()}</TableCell>
                                <TableCell>
                                    <Button color='primary' variant='contained' onClick={ev => showPreview(row.nomor_bon)}>Details</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }
        </React.Fragment>
    );
}