import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import { CircularProgress, TableCell, Table, TableRow, TableHead, Paper, TableBody, Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Publish as PublishIcon, Update as UpdateIcon, Search as SearchIcon, Restore as DeleteIcon } from '@material-ui/icons'
import Button from '@material-ui/core/Button';
import { names } from '../Settings/globalVar'
import { useParams, useLocation } from 'react-router-dom';
import { getTokenOwnerRole } from '../Settings/globalFunc';

const useStyles = makeStyles(() => ({
    divider: {
        height: '25px',
        backgroundColor: 'rgba(0,0,0,0)'
    },
    itemContainer: {
        marginTop: '10px'
    },
    itemQuantity: {
        marginRight: '20px',
        width: '150px'
    },
    itemBarang: {
        marginRight: '20px',
        width: '250px'
    },
    gridItem: {
        backgroundColor: 'white',
        borderRadius: '10px',
        textAlign: 'center',
        margin: 'auto',
        marginTop: '20px',
    },
    iconButton: {
    },
    newItemButton: {
        marginTop: '10px',
        margin: 'auto'
    },
    tableRoot: {
        width: '100%',
        marginTop: '10px',
        overflowX: 'auto'
    },
    button: {
        margin: '20px',
        width: '150px',
        height: '50px'
    }
}))

const CustomTableCell = ({ row, name }) => {
    const { isEditMode } = row;
    return (
        <TableCell align="left" className="userTableCell">
            {isEditMode && name !== "id_user" && name !== "hired_date" ? (
                <Input
                    value={row[name]}
                    name={name}
                    className="input" />) : (
                row[name]
            )}
        </TableCell>
    );
};

const CreateData = (id_barang, total_stok, total_masuk, total_keluar, harga_ecer, harga_toko, harga_tukang, harga_modal, stok_pesanan) => ({
    id_barang,
    total_stok,
    total_masuk,
    total_keluar,
    harga_ecer,
    harga_toko,
    harga_tukang,
    harga_modal,
    stok_pesanan,
    isEditMode: false
});

export default function ItemSetup() {
    const classes = useStyles();
    const [barang, setBarang] = React.useState("")
    const [hargaEcer, setHargaEcer] = React.useState(0)
    const [hargaToko, setHargaToko] = React.useState(0)
    const [hargaTukang, setHargaTukang] = React.useState(0)
    const [hargaModal, setHargaModal] = React.useState(0)
    const [isCreating, setIsCreating] = React.useState(false)

    const [items, setItems] = React.useState([])
    const [pages, setPages] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)

    const [editBarang, setEditBarang] = React.useState('')
    const [editHargaEcer, setEditHargaEcer] = React.useState(0)
    const [editHargaToko, setEditHargaToko] = React.useState(0)
    const [editHargaTukang, setEditHargaTukang] = React.useState(0)
    const [editHargaModal, setEditHargaModal] = React.useState(0)
    const [isUpdating, setIsUpdating] = React.useState(false)


    const { pageNumber } = useParams();
    const editRef = React.createRef()

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    const query = useQuery()
    const [searchFilter, setSearchFilter] = React.useState(query.get("searchFilter") || "")

    useEffect(() => {
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin/get_all_barang/${pageNumber}?searchFilter=${query.get("searchFilter") || ""}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
        }).then(async (res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsLoading(false)
                return
            }

            const jsonData = await res.json();
            console.log(jsonData)
            const rowsData = jsonData.data.allBarangData;
            const pagesData = jsonData.data.pageCount;
            const newRows = Object.values(rowsData).map(row => {
                return CreateData(row.id_barang, row.total_stok, row.total_masuk, row.total_keluar, row.harga_ecer, row.harga_toko, row.harga_tukang, row.harga_modal)
            })
            const newPages = []
            for (let i = 0; i < pagesData; i++) {
                const index = i + 1
                newPages.push(
                    <Button key={index} className="next-page" color={pageNumber === index.toString() ? "primary" : "default"} style={{ marginTop: "5px", marginLeft: "5px" }
                    } variant="contained" onClick={(ev) => {
                        if (window.location.pathname !== "/SetupItem/" + index) window.location.pathname = "/SetupItem/" + index
                    }}> {index}</Button >
                )
            }
            setItems(newRows)
            setPages(newPages)
            setIsLoading(false)
        }).catch((ex) => {
            alert(ex)
            setIsLoading(false)
        })
    }, [pageNumber, query])

    // function getAllBarang(ev, isDefault = false) {
    //     setIsLoading(true)
    //     fetch(`${process.env.REACT_APP_HOSTNAME}/admin/get_all_barang/${pageNumber}?searchFilter=${isDefault ? "" : searchFilter}`, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': localStorage.getItem(names.token)
    //         },
    //     }).then(async (res) => {
    //         if (res.status === 401) {
    //             localStorage.removeItem(names.token)
    //             window.location.pathname = ""
    //             alert("Un-Authorized Access Detected !!!")
    //             setIsLoading(false)
    //             return
    //         }

    //         const jsonData = await res.json();
    //         const rowsData = jsonData.data.allBarangData;
    //         const pagesData = jsonData.data.pageCount;
    //         const newRows = Object.values(rowsData).map(row => {
    //             return CreateData(row.id_barang, row.total_stok, row.total_masuk, row.total_keluar, row.harga_ecer, row.harga_toko, row.harga_tukang, row.harga_modal)
    //         })
    //         const newPages = []
    //         for (let i = 0; i < pagesData; i++) {
    //             const index = i + 1
    //             newPages.push(
    //                 <Button key={index} className="next-page" color={pageNumber === index.toString() ? "primary" : "default"} style={{ marginTop: "5px", marginLeft: "5px" }
    //                 } variant="contained" onClick={(ev) => {
    //                     if (window.location.pathname !== "/SetupItem/" + index) window.location.pathname = "/SetupItem/" + index
    //                 }}> {index}</Button >
    //             )
    //         }
    //         setItems(newRows)
    //         setPages(newPages)
    //         setIsLoading(false)
    //     }).catch((ex) => {
    //         alert(ex)
    //         setIsLoading(false)
    //     })
    // }

    function createItem() {
        if (!barang) {
            alert("Please input the id barang")
            setBarang("")
            return
        }

        const data = { id_barang: barang, harga_ecer: hargaEcer, harga_toko: hargaToko, harga_tukang: hargaTukang, harga_modal: hargaModal }
        setIsCreating(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin/create_item`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
            body: JSON.stringify(data)
        }).then(async (res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsCreating(false)
                return
            }

            const data = await res.json();
            if (!data["success"]) throw (new Error(data["message"]))
            else alert("Create New Item Successfully !!!")
            window.location.reload();
        }).catch((ex) => {
            alert(ex)
            setBarang("")
            setIsCreating(false)
        })
    }

    function updateItem() {
        if (!editBarang === null || !editBarang === "") {
            alert("Please input the id barang that you want to update")
            setEditBarang("")
            return
        }

        const data = { id_barang: editBarang, harga_ecer: editHargaEcer, harga_toko: editHargaToko, harga_tukang: editHargaTukang, harga_modal: editHargaModal }
        setIsUpdating(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/super_admin/update_data_barang`, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
            body: JSON.stringify(data)
        }).then(async (res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsUpdating(false)
                return
            }

            const data = await res.json();
            if (!data["success"]) throw (new Error(data["message"]))
            else alert("Update Data Barang Successfully !!!")
            window.location.reload();
        }).catch((ex) => {
            alert(ex)
            setEditBarang("")
            setIsUpdating(false)
        })
    }

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={"auto"} sm={11} className={classes.gridItem}>
                    <TextField label='Filter ID Barang' style={{ width: '30%', marginBottom: '10px' }} value={searchFilter} onChange={(ev) => setSearchFilter(ev.target.value)} />
                    <Button variant="contained" style={{ padding: '10px', marginLeft: '20px' }} color="primary" onClick={(ev) => {
                        window.location.href = `${window.location.origin}/SetupItem/1?searchFilter=${searchFilter}`
                    }} startIcon={<SearchIcon />}>Search</Button>
                    <Button variant="contained" style={{ padding: '10px', marginLeft: '20px' }} color="secondary" onClick={(ev) => {
                        window.location.href = `${window.location.origin}/SetupItem/1`
                    }} startIcon={<DeleteIcon />}>Reset</Button>
                    <Typography variant="h6" gutterBottom>List Barang</Typography>
                    {isLoading ? <CircularProgress /> :
                        <><Paper className={classes.tableRoot}>
                            <Table className="userTable" aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        {getTokenOwnerRole() === "super_admin" ? <TableCell></TableCell> : null}
                                        <TableCell className="userTableCell" align="left">No</TableCell>
                                        <TableCell className="userTableCell" align="left">ID Barang</TableCell>
                                        <TableCell className="userTableCell" align="left">Stok Ready</TableCell>
                                        {getTokenOwnerRole() === "super_admin" ? <TableCell className="userTableCell" align="left">Harga Ecer</TableCell> : null}
                                        {getTokenOwnerRole() === "super_admin" ? <TableCell className="userTableCell" align="left">Harga Toko</TableCell> : null}
                                        {getTokenOwnerRole() === "super_admin" ? <TableCell className="userTableCell" align="left">Harga Tukang</TableCell> : null}
                                        {getTokenOwnerRole() === "super_admin" ? <TableCell className="userTableCell" align="left">Harga Modal</TableCell> : null}
                                        {getTokenOwnerRole() === "super_admin" ? <TableCell className="userTableCell" align="left">Nilai Aset</TableCell> : null}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items.map((row, idx) => (
                                        <TableRow hover key={row.id_barang} style={{ textAlign: 'center' }}>
                                            {getTokenOwnerRole() === "super_admin" ?
                                                <TableCell align="center">
                                                    <Button variant='contained' color='primary' onClick={(ev) => {
                                                        editRef.current.scrollIntoView()
                                                        setEditBarang(row.id_barang);
                                                        setEditHargaEcer(row.harga_ecer);
                                                        setEditHargaToko(row.harga_toko);
                                                        setEditHargaTukang(row.harga_tukang);
                                                    }}>Edit</Button>
                                                </TableCell> : null}
                                            <TableCell className="userTableCell" align="left">{idx + 1 + ((pageNumber - 1) * 20)}</TableCell>
                                            {CustomTableCell({ ...{ row, name: "id_barang" } })}
                                            {CustomTableCell({ ...{ row, name: "total_stok" } })}
                                            {getTokenOwnerRole() === "super_admin" ? <TableCell className="userTableCell" align="left">Rp {row.harga_ecer.toLocaleString('id-ID')}</TableCell> : null}
                                            {getTokenOwnerRole() === "super_admin" ? <TableCell className="userTableCell" align="left">Rp {row.harga_toko.toLocaleString('id-ID')}</TableCell> : null}
                                            {getTokenOwnerRole() === "super_admin" ? <TableCell className="userTableCell" align="left">Rp {row.harga_tukang.toLocaleString('id-ID')}</TableCell> : null}
                                            {getTokenOwnerRole() === "super_admin" ? <TableCell className="userTableCell" align="left">Rp {row.harga_modal.toLocaleString('id-ID')}</TableCell> : null}
                                            {getTokenOwnerRole() === "super_admin" ? <TableCell className="userTableCell" align="left">Rp {(row.harga_ecer * row.total_stok).toLocaleString('id-ID')}</TableCell> : null}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>{pages}</>}
                </Grid>
                {getTokenOwnerRole() === "super_admin" ?
                    <Grid item xs={10} sm={5} className={classes.gridItem}>
                        <Typography variant="h6" gutterBottom>Update Data Barang</Typography>
                        {isUpdating ? <CircularProgress /> : <>
                            <TextField
                                label="ID Barang"
                                value={editBarang}

                                fullWidth
                                disabled
                            />
                            <Divider className={classes.divider} ref={editRef} />
                            {editBarang !== null && editBarang !== '' ?
                                <>
                                    <TextField
                                        label="Harga Ecer"
                                        value={editHargaEcer}
                                        onChange={(ev) => setEditHargaEcer(parseInt(ev.target.value))}
                                        type='number'
                                        fullWidth
                                    />
                                    <Divider className={classes.divider} />
                                    <TextField
                                        label="Harga Toko"
                                        value={editHargaToko}
                                        onChange={(ev) => setEditHargaToko(parseInt(ev.target.value))}
                                        type='number'
                                        fullWidth
                                    />
                                    <Divider className={classes.divider} />
                                    <TextField
                                        label="Harga Tukang"
                                        value={editHargaTukang}
                                        onChange={(ev) => setEditHargaTukang(parseInt(ev.target.value))}
                                        type='number'
                                        fullWidth
                                    />
                                    <Divider className={classes.divider} />
                                    <TextField
                                        label="Harga Modal"
                                        value={editHargaModal}
                                        onChange={(ev) => setEditHargaModal(parseInt(ev.target.value))}
                                        type='number'
                                        fullWidth
                                    />
                                    <Divider className={classes.divider} />
                                    <Button variant="contained" className={classes.button} color="primary" onClick={updateItem}>
                                        <UpdateIcon className={classes.iconButton} />{"UPDATE"}
                                    </Button>
                                </> : null}
                        </>}
                    </Grid> : null}
                <Grid item xs={10} sm={5} className={classes.gridItem}>
                    <Typography variant="h6" gutterBottom>Tambah Barang Baru</Typography>
                    {isCreating ? <CircularProgress /> : <>
                        <TextField
                            id="idbarang"
                            name="idbarang"
                            label="ID Barang"
                            value={barang}
                            onChange={(ev) => setBarang(ev.target.value)}
                            fullWidth
                        />
                        <Divider className={classes.divider} />
                        <TextField
                            label="Harga Ecer"
                            value={hargaEcer}
                            onChange={(ev) => setHargaEcer(parseInt(ev.target.value))}
                            type='number'
                            fullWidth
                        />
                        <Divider className={classes.divider} />
                        <TextField
                            label="Harga Toko"
                            value={hargaToko}
                            onChange={(ev) => setHargaToko(parseInt(ev.target.value))}
                            type='number'
                            fullWidth
                        />
                        <Divider className={classes.divider} />
                        <TextField
                            label="Harga Tukang"
                            value={hargaTukang}
                            onChange={(ev) => setHargaTukang(parseInt(ev.target.value))}
                            type='number'
                            fullWidth
                        />
                        <Divider className={classes.divider} />
                        <TextField
                            label="Harga Modal"
                            value={hargaModal}
                            onChange={(ev) => setHargaModal(parseInt(ev.target.value))}
                            type='number'
                            fullWidth
                        />
                        <Divider className={classes.divider} />
                        <Button variant="contained" className={classes.button} color="primary" onClick={createItem}>
                            <PublishIcon className={classes.iconButton} />{"SUBMIT"}
                        </Button>
                    </>}
                </Grid>
            </Grid>
        </React.Fragment >
    );
}