import React, { useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, Tooltip } from 'recharts';
import Title from './Title';
import { names } from '../Settings/globalVar';
import CircularProgress from '@material-ui/core/CircularProgress';

function createData(time, masuk, keluar) {
    return { time, masuk, keluar };
}

export default function Chart(props) {
    const theme = useTheme();

    const { id_barang, tanggal } = props;
    const [chartData, setChartData] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)

    useEffect(() => {
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/super_admin/get_chart_data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
            body: JSON.stringify({ id_barang: id_barang, tanggal: tanggal.month_year })
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsLoading(false)
                return
            }
            else if (res.status === 500) throw new Error("Internal Server Error");
            return res.json()
        }).then((res) => {
            const newChartData = []
            const resData = res.data.allBonData
            const maxCount = res.data.pageCount
            for (let idx = 0; idx < maxCount; idx++) {
                const index = idx + 1;
                const day = ('0' + index).slice(-2)
                const dataMasuk = resData.find(val => val.day_month_year === day + " " + tanggal.month_year && val.jenis === "masuk")
                const dataKeluar = resData.find(val => val.day_month_year === day + " " + tanggal.month_year && val.jenis === "keluar")
                newChartData.push(createData(day, dataMasuk ? parseInt(dataMasuk.jumlah) : 0, dataKeluar ? parseInt(dataKeluar.jumlah) : 0))
            }
            setChartData(newChartData)
            setIsLoading(false)
        }).catch((ex) => {
            setIsLoading(false)
            alert(ex)
        })
    }, [id_barang, tanggal])

    return (
        <React.Fragment>
            <Title>{id_barang} - {tanggal.month_name_year}</Title>
            {isLoading ? <CircularProgress style={{ margin: 'auto' }} /> :
                <ResponsiveContainer>
                    <LineChart
                        data={chartData}
                        margin={{
                            top: 10,
                            right: 16,
                            bottom: 5,
                            left: 24,
                        }}>
                        <XAxis dataKey="time" stroke={theme.palette.text.secondary} >
                            <Label position="center" dy={10} style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}>
                                Tanggal
                            </Label>
                        </XAxis>
                        <Tooltip />
                        <YAxis stroke={theme.palette.text.secondary}>
                            <Label angle={270} position="left" style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}>
                                Quantity
                            </Label>
                        </YAxis>
                        <Line type="monotone" dataKey="masuk" stroke="#D2691E" dot={false} />
                        <Line type="monotone" dataKey="keluar" stroke="#008000" dot={false} />
                    </LineChart>
                </ResponsiveContainer>}
        </React.Fragment >
    );
}