import React from 'react';
import trimustlogo from '../Images/trimustlogo.jpg';

export class ComponentToPrintDraft extends React.PureComponent {
    render() {
        const headerStyle = { height: '75px', overflow: 'hidden', width: '100%' };
        const logoStyle = { width: '6%', float: 'left', marginTop: '10px' };
        const headerInfoStyle = { margin: '0', fontWeight: '700', fontSize: '16px', lineHeight: '18px' };
        const thStyle = { width: '20%', border: '1px dotted black', fontSize: '18px', lineHeight: '20px', borderCollapse: 'collapse' }
        const tdStyle = { fontSize: '15px', lineHeight: '16px', fontWeight: '550', paddingLeft: '5px', fontFamily: 'cursive' }
        const bonData = this.props.draftData.json_draft
        const totalHarga = () => {
            let harga = 0
            bonData.items.forEach((val, idx, arr) => {
                val.details.forEach((val2, idx2, arr2) => {
                    if (val2.status === "Cancel") return;
                    const totalHargaPerItem = Math.ceil(val.id_barang.includes("(Custom)") ? val.harga_barang * val2.quantity * val.ukuran : val.harga_barang * val2.quantity);
                    harga += totalHargaPerItem
                })
            })
            return harga
        }

        return (
            <div style={{ marginLeft: '10px', marginTop: '25px', fontFamily: 'sans-serif', width: '21.5cm' }}>
                <div style={headerStyle}>
                    <img src={trimustlogo} style={logoStyle} />
                    <div style={{ float: 'left', marginLeft: '5px', width: '10%' }}>
                        <p style={headerInfoStyle}>Customer</p>
                        <p style={headerInfoStyle}>No Telp</p>
                        <p style={headerInfoStyle}>Admin</p>
                        <p style={headerInfoStyle}>Alamat</p>
                    </div>
                    <div style={{ float: 'left', marginLeft: '0px', width: '37%' }}>
                        <p style={headerInfoStyle}>: {bonData.customer.id_customer.toUpperCase()}</p>
                        <p style={headerInfoStyle}>: {bonData.customer.telp_customer}</p>
                        <p style={headerInfoStyle}>: {this.props.draftData.admin.toUpperCase()}</p>
                        <p style={headerInfoStyle}>: {bonData.customer.alamat_customer}</p>
                    </div>
                    <div style={{ float: 'left', marginLeft: '15px', width: '8%' }}>
                        <p style={headerInfoStyle}>Tanggal</p>
                        <p style={headerInfoStyle}>ID Bon</p>
                        <p style={headerInfoStyle}>Sales</p>
                        <p style={headerInfoStyle}>Hp Toko</p>
                    </div>
                    <div style={{ float: 'left', marginLeft: '0px', width: '35%' }}>
                        <p style={headerInfoStyle}>: {new Date(parseInt(this.props.draftData.id_draft)).toLocaleDateString("id-ID", { year: "numeric", month: "long", day: "numeric", weekday: "long" })}</p>
                        <p style={headerInfoStyle}>: {"Draft - " + this.props.draftData.no_draft}</p>
                        <p style={headerInfoStyle}>: {bonData.id_sales || "-"}</p>
                        <p style={headerInfoStyle}>: 081377041353 | 085837924522</p>
                    </div>
                </div>
                <div style={{ height: '260px', borderBottom: '1px dotted black' }}>
                    <table style={{ borderCollapse: 'collapse', width: '95%' }}>
                        <thead>
                            <tr>
                                <th style={{ width: '40%', border: '1px dotted black', fontSize: '16px', lineHeight: '18px' }}>Nama Barang</th>
                                <th style={thStyle}>Quantity</th>
                                <th style={thStyle}>Harga/Unit</th>
                                <th style={thStyle}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bonData.items.map((val, idx, arr) => {
                                const jumlahBarangCancel = val.details.filter((filterVal, filterIdx, filterArr) => filterVal.status === "Cancel").reduce((a, b) => a + b.quantity, 0)
                                if (val.jumlah_barang - jumlahBarangCancel <= 0) return null;
                                const jumlahBarang = val.jumlah_barang - jumlahBarangCancel;
                                return (<tr key={"item_" + idx}>
                                    <td style={{ fontSize: '14px', lineHeight: '16px', fontWeight: '550', paddingLeft: '5px', textTransform: 'uppercase' }}>{val.id_barang.includes("(Custom)") ? val.id_barang.replace("(Custom)", "") + " " + val.ukuran + " M" + (val.id_barang.toLowerCase().includes("seng spandek") || val.id_barang.toLowerCase().includes("seng genteng") ? "|" + val.tipe : "") : val.id_barang}</td>
                                    <td style={tdStyle}>{jumlahBarang}</td>
                                    <td style={tdStyle}>Rp &nbsp; {Math.ceil(val.id_barang.includes("(Custom)") ? val.harga_barang * val.ukuran : val.harga_barang).toLocaleString('id-ID')}</td>
                                    <td style={tdStyle}>Rp &nbsp; {Math.ceil(val.id_barang.includes("(Custom)") ? val.harga_barang * jumlahBarang * val.ukuran : val.harga_barang * jumlahBarang).toLocaleString('id-ID')}</td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
                <div style={{ height: '75px', marginTop: '5px', borderBottom: '1px dotted black' }}>
                    <div style={{ float: 'left', width: '65%' }}>
                        <div style={{ float: 'left', width: '18%' }}>
                            <p style={{ margin: 0, fontWeight: '600', fontSize: '15px', lineHeight: '16px', height: '32px' }}>Pengantaran</p>
                            <p style={{ margin: 0, fontWeight: '600', fontSize: '15px', lineHeight: '16px', height: '32px' }}>Note</p>
                        </div>
                        <div style={{ float: 'left', overflow: 'hidden', width: '82%' }}>
                            <p style={{ margin: 0, fontWeight: '600', fontSize: '15px', lineHeight: '16px', height: '32px', overflow: 'hidden' }}>: {bonData.alamat_pengantaran}</p>
                            <p style={{ margin: 0, fontWeight: '600', fontSize: '15px', lineHeight: '16px', height: '32px', overflow: 'hidden' }}>: {bonData.keterangan_tambahan}</p>
                        </div>
                    </div>
                    <div style={{ height: '75px', marginLeft: '15px', width: '33%', float: 'left' }}>
                        <div style={{ float: 'left' }}>
                            <p style={{ margin: '0', fontWeight: '600', fontSize: '15px', lineHeight: '16px' }}>Jumlah</p>
                            <p style={{ margin: '0', fontWeight: '600', fontSize: '15px', lineHeight: '16px' }}>Ongkir</p>
                            <p style={{ margin: '0', fontWeight: '600', fontSize: '15px', lineHeight: '16px' }}>Diskon</p>
                            <p style={{ margin: '0', fontWeight: '600', fontSize: '15px', lineHeight: '16px' }}>Total </p>
                        </div>
                        <div style={{ float: 'left' }}>
                            <p style={{ fontFamily: 'cursive', margin: '0', fontWeight: '550', fontSize: '15px', lineHeight: '16px' }}> : Rp &nbsp;{totalHarga().toLocaleString('id-ID')}</p>
                            <p style={{ fontFamily: 'cursive', margin: '0', fontWeight: '550', fontSize: '15px', lineHeight: '16px' }}> : Rp &nbsp;{bonData.biaya_pengantaran.toLocaleString('id-ID')}</p>
                            <p style={{ fontFamily: 'cursive', margin: '0', fontWeight: '550', fontSize: '15px', lineHeight: '16px' }}> : Rp &nbsp;{bonData.payment_discount.toLocaleString('id-ID')}</p>
                            <p style={{ fontFamily: 'cursive', margin: '0', fontWeight: '550', fontSize: '15px', lineHeight: '16px' }}> : Rp &nbsp;{(totalHarga() + bonData.biaya_pengantaran - bonData.payment_discount).toLocaleString('id-ID')}</p>
                        </div>
                    </div>
                </div>
                <div style={{ textAlign: 'center', marginTop: '5px', clear: 'both' }}>
                    <p style={{ margin: '0', float: 'left', fontSize: '14px', lineHeight: '16px', width: '24%' }}>Telly</p>
                    <p style={{ margin: '0', float: 'left', fontSize: '14px', lineHeight: '16px', width: '24%' }}>Supir</p>
                    <p style={{ margin: '0', float: 'left', fontSize: '14px', lineHeight: '16px', width: '24%' }}>Diperiksa Oleh</p>
                    <p style={{ margin: '0', float: 'left', fontSize: '14px', lineHeight: '16px', width: '24%' }}>Diterima Oleh</p>
                </div>
            </div >
        );
    }
}