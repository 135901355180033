import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import { FormControl, InputLabel, Select, MenuItem, CircularProgress, TableCell, Table, TableRow, TableHead, Paper, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Publish as PublishIcon, Edit as EditIcon, Block as CancelIcon, Search as SearchIcon, Delete as DeleteIcon } from '@material-ui/icons'
import Button from '@material-ui/core/Button';
import { names } from '../Settings/globalVar'
import { useParams, useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    divider: {
        height: '25px',
        backgroundColor: 'rgba(0,0,0,0)'
    },
    itemContainer: {
        marginTop: '10px'
    },
    itemQuantity: {
        marginRight: '20px',
        width: '150px'
    },
    itemBarang: {
        marginRight: '20px',
        width: '250px'
    },
    gridItem: {
        backgroundColor: 'white',
        borderRadius: '10px',
        textAlign: 'center',
        margin: 'auto',
        marginTop: '20px',
    },
    iconButton: {
    },
    newItemButton: {
        marginTop: '10px',
        margin: 'auto'
    },
    tableRoot: {
        width: '100%',
        marginTop: '10px',
        overflowX: 'auto'
    },
    button: {
        margin: '20px',
        width: '150px',
        height: '50px'
    }
}))

export default function CustomerSetup() {
    const classes = useStyles();
    const [namaCustomer, setNamaCustomer] = React.useState("")
    const [nomorCustomer, setNomorCustomer] = React.useState("")
    const [alamatCustomer, setAlamatCustomer] = React.useState("")
    const [jenisCustomer, setJenisCustomer] = React.useState("ecer")
    const [isCreating, setIsCreating] = React.useState(false)

    const [editNamaCustomer, setEditNamaCustomer] = React.useState("")
    const [editNomorCustomer, setEditNomorCustomer] = React.useState("")
    const [editAlamatCustomer, setEditAlamatCustomer] = React.useState("")
    const [editLimitKredit, setEditLimitKredit] = React.useState(0)
    const [editJenisCustomer, setEditJenisCustomer] = React.useState("")
    const [isUpdating, setIsUpdating] = React.useState(false)

    const [customers, setCustomers] = React.useState([])
    const [pages, setPages] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)

    const { pageNumber } = useParams();
    const editRef = React.createRef();

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery()
    const [filterIdCustomer, setFilterIdCustomer] = React.useState(query.get("idCustomer") || "")
    const [filterJenisCustomer, setFilterJenisCustomer] = React.useState(query.get("jenisCustomer") || "")

    useEffect(() => {
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/super_admin/get_all_customer/${pageNumber}?idCustomer=${query.get("idCustomer") || ''}&jenisCustomer=${query.get("jenisCustomer") || ''}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
        }).then(async (res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsLoading(false)
                return
            }
            const jsonData = await res.json();
            console.log(jsonData)
            const rowsData = jsonData.data.allCustomerData;
            const pagesData = jsonData.data.pageCount;
            const newPages = []
            for (let i = 0; i < pagesData; i++) {
                const index = i + 1
                newPages.push(
                    <Button key={index} className="next-page" color={pageNumber === index.toString() ? "primary" : "default"} style={{ marginTop: "5px", marginLeft: "5px" }
                    } variant="contained" onClick={(ev) => {
                        if (window.location.pathname !== "/SetupCustomer/" + index) window.location.pathname = "/SetupCustomer/" + index
                    }}> {index}</Button >
                )
            }
            setCustomers(rowsData)
            setPages(newPages)
            setIsLoading(false)
        }).catch((ex) => {
            alert(ex)
            setIsLoading(false)
        })
    }, [pageNumber, query])

    function createCustomer() {
        if (!namaCustomer || !nomorCustomer || !alamatCustomer || !jenisCustomer) {
            alert("Please input all the data in the form")
            return
        }

        const data = { id_customer: namaCustomer, telp_customer: nomorCustomer, alamat_customer: alamatCustomer, jenis_customer: jenisCustomer }
        setIsCreating(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/super_admin/create_new_customer`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
            body: JSON.stringify(data)
        }).then(async (res) => {
            const data = await res.json();
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                return
            }
            if (!data["success"]) throw (new Error(data["message"]))
            else alert("Create New Customer Successfully !!!")
            window.location.reload();
        }).catch((ex) => {
            alert(ex)
            setNamaCustomer("")
            setNomorCustomer("")
            setAlamatCustomer("")
            setJenisCustomer("ecer")
            setIsCreating(false)
        })
    }

    function UpdateCustomer() {
        if (!editNamaCustomer || !editNomorCustomer || !editAlamatCustomer || !editJenisCustomer || !editLimitKredit) {
            alert("Please Insert All The Customer Data")
            return
        }
        const data = { id_customer: editNamaCustomer, telp_customer: editNomorCustomer, alamat_customer: editAlamatCustomer, jenis_customer: editJenisCustomer, limit_kredit: editLimitKredit }
        setIsUpdating(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/super_admin/update_customer`, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
            body: JSON.stringify(data)
        }).then(async (res) => {
            const data = await res.json();
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                return
            }

            if (!data["success"]) throw (new Error(data["message"]))

            alert("Update Customer Data Successfully !!!")
            setIsUpdating(false)
            window.location.reload();
        }).catch((ex) => {
            alert(ex)
            setIsUpdating(false)
            window.location.reload();
        })
    }

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={"auto"} sm={11} className={classes.gridItem}>

                    <TextField label='Filter ID Customer' style={{ width: '10%', marginBottom: '10px' }} value={filterIdCustomer} onChange={(ev) => setFilterIdCustomer(ev.target.value)} />
                    <FormControl style={{ width: '10%', marginBottom: '10px', marginLeft: '20px' }}>
                        <InputLabel>Jenis Customer</InputLabel>
                        <Select value={filterJenisCustomer} onChange={(ev) => setFilterJenisCustomer(ev.target.value)}>
                            <MenuItem value={"ecer"}>Ecer</MenuItem>
                            <MenuItem value={"toko"}>Toko</MenuItem>
                            <MenuItem value={"tukang"}>Tukang</MenuItem>
                            <MenuItem value={""}>Semua</MenuItem>
                        </Select>
                    </FormControl>
                    <Button variant="contained" style={{ padding: '10px', marginLeft: '20px' }} color="primary" onClick={(ev) => {
                        window.location.href = `${window.location.origin}/SetupCustomer/1?idCustomer=${filterIdCustomer}&jenisCustomer=${filterJenisCustomer}`
                    }} startIcon={<SearchIcon />}>Search</Button>
                    <Button variant="contained" style={{ padding: '10px', marginLeft: '20px' }} color="secondary" onClick={(ev) => {
                        window.location.href = `${window.location.origin}/SetupCustomer/1`
                    }} startIcon={<DeleteIcon />}>Reset</Button>

                    <Typography variant="h6" gutterBottom>List Customer</Typography>
                    {isLoading ? <CircularProgress /> :
                        <Paper className={classes.tableRoot}>
                            <Table className="userTable" aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="userTableCell" style={{ textAlign: 'center' }}></TableCell>
                                        <TableCell className="userTableCell" align="left">No</TableCell>
                                        <TableCell className="userTableCell" align="left">Nama Customer</TableCell>
                                        <TableCell className="userTableCell" align="left">No Telepon</TableCell>
                                        <TableCell className="userTableCell" align="left">Alamat</TableCell>
                                        <TableCell className="userTableCell" align="left">Tipe</TableCell>
                                        <TableCell className="userTableCell" align="left">Total Belanja</TableCell>
                                        <TableCell className="userTableCell" align="left">Total Kredit</TableCell>
                                        <TableCell className="userTableCell" align="left">Limit Kredit</TableCell>
                                        <TableCell className="userTableCell" align="left">Tanggal Bergabung</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {customers.map((row, idx) => (
                                        <TableRow hover key={row.id_customer} style={{ textAlign: 'center' }}>
                                            <TableCell align="left" className="editTableCell">
                                                <Button variant='contained' color='primary' onClick={(ev) => {
                                                    editRef.current.scrollIntoView()
                                                    setEditNamaCustomer(row.id_customer);
                                                    setEditNomorCustomer(row.telp_customer);
                                                    setEditAlamatCustomer(row.alamat_customer);
                                                    setEditLimitKredit(row.limit_kredit)
                                                    setEditJenisCustomer(row.jenis_customer)
                                                }}>Edit</Button>
                                            </TableCell>
                                            <TableCell align="left" className="userTableCell">{idx + 1 + ((pageNumber - 1) * 20)}</TableCell>
                                            <TableCell align="left" className="userTableCell">{row.id_customer}</TableCell>
                                            <TableCell align="left" className="userTableCell">{row.telp_customer}</TableCell>
                                            <TableCell align="left" className="userTableCell">{row.alamat_customer}</TableCell>
                                            <TableCell align="left" className="userTableCell">{row.jenis_customer}</TableCell>
                                            <TableCell align="left" className="userTableCell">Rp {row.total_belanja.toLocaleString('id-ID')}</TableCell>
                                            <TableCell align="left" className="userTableCell">Rp {row.total_kredit.toLocaleString('id-ID')}</TableCell>
                                            <TableCell align="left" className="userTableCell">Rp {row.limit_kredit.toLocaleString('id-ID')}</TableCell>
                                            <TableCell align="left" className="userTableCell">{new Date(parseInt(row.joined_date)).toLocaleDateString("id-ID", { year: "numeric", month: "long", day: "numeric", weekday: "long" })}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>}
                    {pages}
                </Grid>
                <Grid item xs={10} sm={5} className={classes.gridItem}>
                    <Typography variant="h6" gutterBottom>Edit Customer</Typography>
                    {isUpdating ? <CircularProgress /> : <>
                        <TextField
                            id="editNamaCustomer"
                            name="editNamaCustomer"
                            label="Nama Customer"
                            value={editNamaCustomer}
                            disabled={true}
                            fullWidth
                        />
                        <Divider className={classes.divider} ref={editRef} />
                        {editNamaCustomer ? <>
                            <TextField
                                id="editNomorCustomer"
                                name="editNomorCustomer"
                                label="Nomor Telepon"
                                value={editNomorCustomer}
                                onChange={(ev) => setEditNomorCustomer(ev.target.value)}
                                fullWidth
                            />
                            <Divider className={classes.divider} />
                            <TextField
                                id="editAlamatCustomer"
                                name="editAlamatCustomer"
                                label="Alamat"
                                value={editAlamatCustomer}
                                onChange={(ev) => setEditAlamatCustomer(ev.target.value)}
                                fullWidth
                            />
                            <Divider className={classes.divider} />
                            <TextField
                                id="editLimitKredit"
                                name="editLimitKredit"
                                label="Limit Kredit"
                                type='number'
                                value={editLimitKredit}
                                onChange={(ev) => setEditLimitKredit(ev.target.value)}
                                fullWidth
                            />
                            <Divider className={classes.divider} />
                            <FormControl fullWidth>
                                <InputLabel>Jenis Customer</InputLabel>
                                <Select value={editJenisCustomer} onChange={(ev) => setEditJenisCustomer(ev.target.value)}>
                                    <MenuItem value={"ecer"}>Ecer</MenuItem>
                                    <MenuItem value={"toko"}>Toko</MenuItem>
                                    <MenuItem value={"tukang"}>Tukang</MenuItem>
                                </Select>
                            </FormControl>
                            <Divider className={classes.divider} />
                            <Button variant="contained" className={classes.button} color="primary" onClick={UpdateCustomer}>
                                <EditIcon className={classes.iconButton} />{"Update"}
                            </Button>
                            <Button variant="contained" className={classes.button} color="secondary" onClick={(ev) => {
                                setEditNamaCustomer("");
                                setEditNomorCustomer("");
                                setEditAlamatCustomer("");
                                setEditLimitKredit(0)
                                setEditJenisCustomer("");
                            }}>
                                <CancelIcon className={classes.iconButton} />{"Cancel"}
                            </Button>
                        </> : null}
                    </>}
                </Grid>
                <Grid item xs={10} sm={5} className={classes.gridItem}>
                    <Typography variant="h6" gutterBottom>Tambah Customer Baru</Typography>
                    {isCreating ? <CircularProgress /> : <>
                        <TextField
                            id="namaCustomer"
                            name="namaCustomer"
                            label="Nama Customer"
                            value={namaCustomer}
                            onChange={(ev) => setNamaCustomer(ev.target.value)}
                            fullWidth
                        />
                        <Divider className={classes.divider} />
                        <TextField
                            id="nomorCustomer"
                            name="nomorCustomer"
                            label="Nomor Telepon"
                            value={nomorCustomer}
                            onChange={(ev) => setNomorCustomer(ev.target.value)}
                            fullWidth
                        />
                        <Divider className={classes.divider} />
                        <TextField
                            id="alamatCustomer"
                            name="alamatCustomer"
                            label="Alamat Customer"
                            value={alamatCustomer}
                            onChange={(ev) => setAlamatCustomer(ev.target.value)}
                            fullWidth
                        />
                        <Divider className={classes.divider} />
                        <FormControl fullWidth>
                            <InputLabel>Jenis Customer</InputLabel>
                            <Select value={jenisCustomer} onChange={(ev) => setJenisCustomer(ev.target.value)}>
                                <MenuItem value={"ecer"}>Ecer</MenuItem>
                                <MenuItem value={"toko"}>Toko</MenuItem>
                                <MenuItem value={"tukang"}>Tukang</MenuItem>
                            </Select>
                        </FormControl>
                        <Divider className={classes.divider} />
                        <Button variant="contained" className={classes.button} color="primary" onClick={createCustomer}>
                            <PublishIcon className={classes.iconButton} />{"SUBMIT"}
                        </Button>
                    </>}
                </Grid>
            </Grid>
        </React.Fragment >
    );
}