import React from 'react';
import { TextField, Button, CircularProgress, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { InputOutlined } from '@material-ui/icons'
import { names } from '../Settings/globalVar'
import logo from '../Images/trimustlogo.png'
// import qz from 'qz-tray'

const useStyles = makeStyles(() => ({
    signIn: {
        textAlign: 'center',
        marginTop: '10vh',
        // backgroundColor: 'red'
    },
    inputField: {
        width: '35ch',
        backgroundColor: 'white',
        borderRadius: '3.5px'
    },
    signInButton: {
        width: '150px',
        height: '50px'
    },
    iconButton: {
        padding: '5px',
    },
    logo: {
        width: '200px',
        borderRadius: '100px',
    },
    signInTitle: {
        color: 'white',
    },
    divider: {
        height: '20px',
        backgroundColor: 'rgba(0,0,0,0)'
    }
}))

export default function SignIn() {
    const classes = useStyles()
    const [username, setUsername] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)

    function signIn(ev) {
        const data = { username: username, password: password }
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/signin`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(async (res) => {
            const data = await res.json();
            if (!data["success"]) throw (new Error(data["message"]))
            localStorage.setItem(names.token, data["token"])
            setIsLoading(false)
            window.location.reload();
        }).catch((ex) => {
            setPassword("")
            setIsLoading(false)
            alert(ex)
        })
    }

    return (
        <div className={classes.signIn}>
            <img className={classes.logo} src={logo} alt={logo} />
            <h1 className={classes.signInTitle}>Sign In</h1>
            <TextField
                className={classes.inputField}
                onChange={(ev) => setUsername(ev.target.value)}
                value={username}
                label="Username"
                type="search"
                variant="filled" />
            <Divider className={classes.divider} />
            <TextField
                className={classes.inputField}
                onChange={(ev) => setPassword(ev.target.value)}
                value={password}
                label="Password"
                type="password"
                variant="filled" />
            <Divider className={classes.divider} />
            <Button variant="contained" className={classes.signInButton} color="primary" onClick={signIn}>
                <InputOutlined className={classes.iconButton} />{"SIGN IN"}
            </Button>
            <Divider className={classes.divider} />
            {isLoading ? <CircularProgress /> : null}
        </div>
    )
}