import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import { FormControl, InputLabel, Select, MenuItem, CircularProgress, TableCell, Table, TableRow, TableHead, Paper, TableBody, Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Publish as PublishIcon, Edit as EditIcon, Block as CancelIcon } from '@material-ui/icons'
import Button from '@material-ui/core/Button';
import { names } from '../Settings/globalVar'
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    divider: {
        height: '25px',
        backgroundColor: 'rgba(0,0,0,0)'
    },
    itemContainer: {
        marginTop: '10px'
    },
    itemQuantity: {
        marginRight: '20px',
        width: '150px'
    },
    itemBarang: {
        marginRight: '20px',
        width: '250px'
    },
    gridItem: {
        backgroundColor: 'white',
        borderRadius: '10px',
        textAlign: 'center',
        margin: 'auto',
        marginTop: '20px',
    },
    iconButton: {
    },
    newItemButton: {
        marginTop: '10px',
        margin: 'auto'
    },
    tableRoot: {
        width: '100%',
        marginTop: '10px',
        overflowX: 'auto'
    },
    button: {
        margin: '20px',
        width: '150px',
        height: '50px'
    }
}))

const CustomTableCell = ({ row, name }) => {
    const { isEditMode } = row;
    return (
        <TableCell align="left" className="userTableCell">
            {isEditMode && name !== "id_user" && name !== "hired_date" ? (
                <Input
                    value={row[name]}
                    name={name}
                    className="input" />) : (
                row[name]
            )}
        </TableCell>
    );
};

const CreateData = (id_user, status_user, role_user, hired_date) => ({
    id_user,
    status_user,
    role_user,
    hired_date,
    isEditMode: false
});

export default function UserSetup() {
    const classes = useStyles();
    const [username, setUsername] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [confirmPassword, setConfirmPassword] = React.useState("")
    const [role, setRole] = React.useState("sales")
    const [isCreating, setIsCreating] = React.useState(false)

    const [editUsername, setEditUsername] = React.useState("")
    const [editRole, setEditRole] = React.useState("")
    const [editStatus, setEditStatus] = React.useState("")
    const [isUpdating, setIsUpdating] = React.useState(false)

    const [users, setUsers] = React.useState([])
    const [pages, setPages] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)

    const { pageNumber } = useParams();
    const editRef = React.createRef()

    useEffect(() => {
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/super_admin/get_all_user/${pageNumber}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
        }).then(async (res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsLoading(false)
                return
            }
            const jsonData = await res.json();
            const rowsData = jsonData.data.allUserData;
            const pagesData = jsonData.data.pageCount;
            const newRows = Object.values(rowsData).map(row => {
                const inDate = new Date(parseInt(row.key_user)).toLocaleDateString("id-ID", { year: "numeric", month: "long", day: "numeric", weekday: "long" })
                return CreateData(row.id_user, row.status, row.role_user, inDate)
            })
            const newPages = []
            for (let i = 0; i < pagesData; i++) {
                const index = i + 1
                newPages.push(
                    <Button key={index} className="next-page" color={pageNumber === index.toString() ? "primary" : "default"} style={{ marginTop: "5px", marginLeft: "5px" }
                    } variant="contained" onClick={(ev) => {
                        if (window.location.pathname !== "/SetupUser/" + index) window.location.pathname = "/SetupUser/" + index
                    }}> {index}</Button >
                )
            }
            setUsers(newRows)
            setPages(newPages)
            setIsLoading(false)
        }).catch((ex) => {
            alert(ex)
            setIsLoading(false)
        })
    }, [pageNumber])

    function createUser() {
        if (!username || !confirmPassword || !password) {
            alert("Please input your username and password")
            setUsername("")
            setPassword("")
            setConfirmPassword("")
            return
        }
        else if (confirmPassword !== password) {
            alert("Password and Confirm Password not Match")
            setUsername("")
            setPassword("")
            setConfirmPassword("")
            return
        }
        else if (password.trim() !== password) {
            alert("Password contains Whitespace")
            setUsername("")
            setPassword("")
            setConfirmPassword("")
            return
        }

        const data = { username: username, password: password, confirmPassword: confirmPassword, role: role }
        setIsCreating(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/super_admin/create_user`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
            body: JSON.stringify(data)
        }).then(async (res) => {
            const data = await res.json();
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                return
            }
            if (!data["success"]) throw (new Error(data["message"]))
            else alert("Create New User Successfully !!!")
            window.location.reload();
        }).catch((ex) => {
            alert(ex)
            setUsername("")
            setPassword("")
            setConfirmPassword("")
            setIsCreating(false)
        })
    }

    function UpdateUser() {
        if (!editUsername || !editRole || !editStatus) {
            alert("Please Insert All The User Data")
            setEditUsername("")
            setEditRole("")
            setEditStatus("")
            return
        }
        const data = { username: editUsername, role: editRole, status: editStatus }
        setIsUpdating(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/super_admin/update_user`, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
            body: JSON.stringify(data)
        }).then(async (res) => {
            const data = await res.json();
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                return
            }
            if (!data["success"]) throw (new Error(data["message"]))
            alert("Update User Data Successfully !!!")
            setIsUpdating(false)
            window.location.reload();
        }).catch((ex) => {
            alert(ex)
            setIsUpdating(false)
            window.location.reload();
        })
    }

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={"auto"} sm={11} className={classes.gridItem}>
                    <Typography variant="h6" gutterBottom>List Pengguna</Typography>
                    {isLoading ? <CircularProgress /> :
                        <Paper className={classes.tableRoot}>
                            <Table className="userTable" aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="userTableCell" style={{ textAlign: 'center' }}></TableCell>
                                        <TableCell className="userTableCell" align="left">No</TableCell>
                                        <TableCell className="userTableCell" align="left">Nama User</TableCell>
                                        <TableCell className="userTableCell" align="left">Status</TableCell>
                                        <TableCell className="userTableCell" align="left">Role</TableCell>
                                        <TableCell className="userTableCell" align="left">Date Created</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map((row, idx) => (
                                        <TableRow hover key={row.id_user} style={{ textAlign: 'center' }}>
                                            <TableCell align="left" className="editTableCell">
                                                <Button variant='contained' color='primary' onClick={(ev) => {
                                                    editRef.current.scrollIntoView()
                                                    setEditUsername(row.id_user);
                                                    setEditRole(row.role_user);
                                                    setEditStatus(row.status_user);
                                                }}>Edit</Button>
                                            </TableCell>
                                            <TableCell className="userTableCell" align="left">{idx + 1}</TableCell>
                                            {CustomTableCell({ ...{ row, name: "id_user" } })}
                                            {CustomTableCell({ ...{ row, name: "status_user" } })}
                                            {CustomTableCell({ ...{ row, name: "role_user" } })}
                                            {CustomTableCell({ ...{ row, name: "hired_date" } })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>}
                    {pages}
                </Grid>
                <Grid item xs={10} sm={5} className={classes.gridItem}>
                    <Typography variant="h6" gutterBottom>Edit Pengguna</Typography>
                    {isUpdating ? <CircularProgress /> : <>
                        <TextField
                            id="edit_username"
                            name="edit_username"
                            label="Username"
                            value={editUsername}
                            disabled={true}
                            fullWidth
                        />
                        <Divider className={classes.divider} ref={editRef} />
                        {editUsername ? <>
                            <FormControl fullWidth>
                                <InputLabel>Role User</InputLabel>
                                <Select value={editRole} onChange={(ev) => setEditRole(ev.target.value)}>
                                    <MenuItem value={"sales"}>Sales</MenuItem>
                                    <MenuItem value={"admin_stok"}>Admin Stok</MenuItem>
                                    <MenuItem value={"super_admin"}>Super Admin</MenuItem>
                                    <MenuItem value={"admin_sales"}>Admin Sales</MenuItem>
                                    <MenuItem value={"admin_cat_sales"}>Admin Sales Toko Cat</MenuItem>
                                    <MenuItem value={"admin_cat_super"}>Super Admin Toko Cat</MenuItem>
                                </Select>
                            </FormControl>
                            <Divider className={classes.divider} />
                            <FormControl fullWidth>
                                <InputLabel>Status User</InputLabel>
                                <Select value={editStatus} onChange={(ev) => setEditStatus(ev.target.value)}>
                                    <MenuItem value={"Bekerja"}>Bekerja</MenuItem>
                                    <MenuItem value={"Berhenti"}>Berhenti</MenuItem>
                                </Select>
                            </FormControl>
                            <Divider className={classes.divider} />
                            <Button variant="contained" className={classes.button} color="primary" onClick={UpdateUser}>
                                <EditIcon className={classes.iconButton} />{"Update"}
                            </Button>
                            <Button variant="contained" className={classes.button} color="secondary" onClick={(ev) => {
                                setEditUsername("");
                                setEditRole("");
                                setEditStatus("");
                            }}>
                                <CancelIcon className={classes.iconButton} />{"Cancel"}
                            </Button>
                        </> : null}
                    </>}
                </Grid>
                <Grid item xs={10} sm={5} className={classes.gridItem}>
                    <Typography variant="h6" gutterBottom>Tambah Pengguna Baru</Typography>
                    {isCreating ? <CircularProgress /> : <>
                        <TextField
                            id="username"
                            name="username"
                            label="Username"
                            value={username}
                            onChange={(ev) => setUsername(ev.target.value)}
                            fullWidth
                        />
                        <Divider className={classes.divider} />
                        <TextField
                            id="password"
                            name="password"
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(ev) => setPassword(ev.target.value)}
                            fullWidth
                        />
                        <Divider className={classes.divider} />
                        <TextField
                            id="confirmPassword"
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            value={confirmPassword}
                            onChange={(ev) => setConfirmPassword(ev.target.value)}
                            fullWidth
                        />
                        <Divider className={classes.divider} />
                        <FormControl fullWidth>
                            <InputLabel>Role User</InputLabel>
                            <Select value={role} onChange={(ev) => setRole(ev.target.value)}>
                                <MenuItem value={"sales"}>Sales</MenuItem>
                                <MenuItem value={"admin_stok"}>Admin Stok</MenuItem>
                                <MenuItem value={"super_admin"}>Super Admin</MenuItem>
                                <MenuItem value={"admin_sales"}>Admin Sales</MenuItem>
                                <MenuItem value={"admin_cat_sales"}>Admin Sales Toko Cat</MenuItem>
                                <MenuItem value={"admin_cat_super"}>Super Admin Toko Cat</MenuItem>
                            </Select>
                        </FormControl>
                        <Divider className={classes.divider} />
                        <Button variant="contained" className={classes.button} color="primary" onClick={createUser}>
                            <PublishIcon className={classes.iconButton} />{"SUBMIT"}
                        </Button>
                    </>}
                </Grid>
            </Grid>
        </React.Fragment >
    );
}