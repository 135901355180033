import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircle, RemoveCircle, Publish, Block } from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Button from '@material-ui/core/Button';
import { names } from '../Settings/globalVar';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    divider: {
        height: '25px',
        backgroundColor: 'rgba(0,0,0,0)'
    },
    itemContainer: {
        marginTop: '10px',
        display: 'flex'
    },
    itemQuantity: {
        marginRight: '20px',
        width: '150px',
    },
    itemBarang: {
        marginRight: '20px',
        width: '250px',
    },
    gridItem: {
        backgroundColor: 'white',
        borderRadius: '10px',
        textAlign: 'center',
        margin: '20px'
    },
    signInButton: {
        width: '150px',
        height: '50px'
    },
    iconButton: {
    },
    newItemButton: {
        marginTop: '10px',
        margin: 'auto'
    },
    previewPaper: {
        // textAlign: 'left',
        padding: '10px',
        width: '600px',
        margin: 'auto',
        marginTop: '100px'
    },
    previewContainer: {
        // textAlign: 'center',
        zIndex: '1',
        position: 'fixed',
        left: '0',
        right: '0',
        top: '0',
        margin: 'auto',
        backgroundColor: 'rgba(55,55,55,0.3)',
        height: '100vh',
        overflowY: 'scroll',
    }
}))

const CustomTableCell = ({ row, name }) => {
    return (
        <TableCell align="left" className="userTableCell"> {name !== "nomor_bon" ? row[name] : row[name].split("_")[0]}</TableCell>
    );
};

const CreateData = (nomor_bon, item, tanggal, admin) => ({
    nomor_bon,
    item,
    tanggal,
    admin
});

export default function RemoveStock() {
    const classes = useStyles();

    const [bon, setBon] = React.useState("")
    const [items, setItems] = React.useState([{ barang: null, quantity: 1 }])
    const [keterangan, setKeterangan] = React.useState("")
    const [isCreating, setIsCreating] = React.useState(false)

    const [listBon, setListBon] = React.useState([])
    const [listNomorBon, setListNomorBon] = React.useState([])
    const [pageCount, setPageCount] = React.useState([])
    const [isFetching, setIsFetching] = React.useState(true)

    const [listItem, setListItem] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)

    const [checkNomorBon, setCheckNomorBon] = React.useState(null)
    const [previewData, setPreviewData] = React.useState(null)
    const [isPreviewing, setIsPreviewing] = React.useState(false)

    const { pageNumber } = useParams();

    function handleBarangChange(val, idx) {
        let temp = [];
        temp = temp.concat(items);
        temp[idx].barang = val;
        temp[idx].quantity = 1;
        setItems(temp);
    }

    function handleQuantityChange(val, idx, max) {
        if (val < 1 || isNaN(val)) val = 1;
        else val = parseInt(val)
        if (val > max) return alert("Stok tidak mencukupi")

        let temp = [];
        temp = temp.concat(items);
        temp[idx].quantity = val;
        setItems(temp);
    }

    function handleAddItem(ev) {
        let temp = [];
        temp = temp.concat(items);
        temp.push({ barang: null, quantity: 1 });
        setItems(temp);
    }

    function handleRemoveItem(idx) {
        if (items.length <= 1) return
        let temp = [];
        temp = temp.concat(items);
        temp.splice(idx, 1)
        setItems(temp);
    }

    function addBonKeluar() {
        const submitItems = items.filter((val, idx, arr) => val.barang !== null && val.barang !== "")
        if (!bon || submitItems.length === 0 || !keterangan) {
            alert("Please fill the form completely")
            return
        }

        const data = { bon: bon, items: submitItems, keterangan: keterangan }
        setIsCreating(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_stok/add_bon_keluar`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
            body: JSON.stringify(data)
        }).then(async (res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsCreating(false)
                return
            }

            const data = await res.json();
            if (!data["success"]) throw (new Error(data["message"]))
            else alert("Add New Bon Keluar Successfully !!!")
            window.location.reload();
        }).catch((ex) => {
            setIsCreating(false)
            alert(ex)
        })
    }

    function clearForm() {
        setBon('')
        setItems([{ barang: null, quantity: 1 }])
        setKeterangan('')
    }

    function showPreview(nomor_bon) {
        setIsPreviewing(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_stok/get_bon/${nomor_bon}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            }
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsLoading(false)
                return
            }

            return res.json()
        }).then((res) => {
            setPreviewData(res.data)
        }).catch(ex => {
            setIsPreviewing(false)
            alert(ex)
        })
    }

    useEffect(() => {
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin/get_list_barang`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            }
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsLoading(false)
                return
            }

            return res.json()
        }).then((res) => {
            setListItem(res.data)
            setIsLoading(false)
        }).catch((ex) => {
            setIsLoading(false)
            alert(ex)
        })
    }, [])

    useEffect(() => {
        setIsFetching(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_stok/get_all_bon_keluar/${pageNumber}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            }
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsLoading(false)
                return
            }

            return res.json()
        }).then((jsonData) => {
            const rowsData = jsonData.data.allBonData;
            const listNomor = jsonData.data.listNomorBon.map((val, idx, arr) => val.nomor_bon)
            const pagesData = jsonData.data.pageCount;
            const newRows = Object.values(rowsData).map(row => {
                return CreateData(row.nomor_bon, row.item, new Date(parseInt(row.tanggal)).toDateString() + ", " + new Date(parseInt(row.tanggal)).toLocaleTimeString(), row.admin)
            })

            const newPages = []
            for (let i = 0; i < pagesData; i++) {
                const index = i + 1
                newPages.push(
                    <Button key={index} className="next-page" color={pageNumber === index.toString() ? "primary" : "default"} style={{ marginTop: "5px", marginLeft: "5px" }
                    } variant="contained" onClick={(ev) => {
                        if (window.location.pathname !== "/ReduceStock/" + index) window.location.pathname = "/ReduceStock/" + index
                    }}> {index}</Button >
                )
                if (index === 50) break;
            }

            setListBon(newRows)
            setListNomorBon(listNomor)
            setPageCount(newPages)
            setIsFetching(false)
        }).catch((ex) => {
            setIsFetching(false)
            alert(ex)
        })
    }, [pageNumber])

    return (
        <React.Fragment>
            {isPreviewing ?
                <div className={classes.previewContainer}>
                    <Paper className={classes.previewPaper}>
                        {previewData == null ? <CircularProgress style={{ marginLeft: '46%' }} /> :
                            <>
                                <Typography variant="h6" gutterBottom>Nomor BON : {previewData[0].nomor_bon}</Typography>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="userTableCell" align="left">No</TableCell>
                                            <TableCell className="userTableCell" align="left">Item</TableCell>
                                            <TableCell className="userTableCell" align="left">Jumlah</TableCell>
                                            <TableCell className="userTableCell" align="left">Tanggal</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {previewData.map((val, idx, arr) => {
                                            return (
                                                <TableRow hover key={idx} style={{ textAlign: 'center' }} >
                                                    <TableCell className="userTableCell" align="left">{idx + 1}</TableCell>
                                                    <TableCell className='userTableCell' align="left">{val.id_barang}</TableCell>
                                                    <TableCell className='userTableCell' align="left">{val.jumlah_barang}</TableCell>
                                                    <TableCell className='userTableCell' align="left">{new Date(parseInt(val.tanggal)).toDateString() + ", " + new Date(parseInt(val.tanggal)).toLocaleTimeString()}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                                <Typography variant="h6" gutterBottom>Admin : {previewData[0].admin}</Typography>
                                <Typography variant="h6" gutterBottom>Keterangan : {previewData[0].keterangan}</Typography>
                                <Button variant="contained" color="primary" onClick={(ev) => {
                                    setIsPreviewing(false)
                                    setPreviewData(null)
                                }}>
                                    Close Preview
                                </Button>
                            </>}
                    </Paper>
                </div> : null}
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} className={classes.gridItem}>
                    <Typography variant="h6" gutterBottom>Tambah Bon Keluar</Typography>
                    {isCreating || isLoading ? <CircularProgress /> :
                        <>
                            <TextField
                                id="bon"
                                name="bon"
                                label="Nomor Bon"
                                value={bon}
                                onChange={(ev) => setBon(ev.target.value)}
                                fullWidth
                            />
                            <Divider className={classes.divider} />
                            {items.map((val, idx, arr) => {
                                return (
                                    <div key={idx} className={classes.itemContainer}>
                                        <Autocomplete
                                            options={listItem.map(val => val.id_barang).filter((val) => !items.find((item) => item.barang === val))}
                                            value={items[idx].barang}

                                            getOptionSelected={(option, value) => option === value || ""}
                                            onChange={(ev, value) => handleBarangChange(value, idx)}
                                            className={classes.itemBarang}
                                            key={"barang_" + idx}
                                            renderInput={(params) => <TextField {...params} label="Barang" />}
                                        />
                                        <TextField className={classes.itemQuantity}
                                            id={"quantity_" + idx}
                                            name={"quantity_" + idx}
                                            label="Quantity"
                                            type="number"
                                            key={"quantity_" + idx}
                                            value={items[idx].quantity}
                                            disabled={items[idx].barang == null ? true : false}
                                            onChange={(ev) => handleQuantityChange(ev.target.value, idx, listItem.find(val => val.id_barang === items[idx].barang).total_stok)}
                                        />
                                        <TextField label={items[idx].barang === null ? "Stok : -" : "Stok : " + listItem.find(val => val.id_barang === items[idx].barang).total_stok} disabled />
                                        <Button key={"remove_" + idx} className={classes.newItemButton} color="secondary" onClick={ev => handleRemoveItem(idx)}><RemoveCircle className={classes.iconButton} />Remove Item</Button>
                                    </div>
                                )
                            })}
                            <Button className={classes.newItemButton} color="primary" onClick={handleAddItem}><AddCircle className={classes.iconButton} />Add Item</Button>
                            <Divider className={classes.divider} />
                            <TextField
                                multiline
                                id="keterangan"
                                name="keterangan"
                                label="Keterangan"
                                value={keterangan}
                                onChange={(ev) => setKeterangan(ev.target.value)}
                                fullWidth
                            />
                            <Divider className={classes.divider} />
                            <Button variant="contained" className={classes.signInButton} color="primary" onClick={addBonKeluar}>
                                <Publish className={classes.iconButton} />{"SUBMIT"}
                            </Button>
                            <Divider className={classes.divider} />
                            <Button variant="contained" className={classes.signInButton} color="secondary" onClick={clearForm}>
                                <Block className={classes.iconButton} />{"CANCEL"}
                            </Button>
                        </>}
                </Grid>
                <Grid item xs={12} sm={5} className={classes.gridItem}>
                    <Typography variant="h6" gutterBottom>List Bon Keluar</Typography>
                    <div>
                        <Autocomplete
                            style={{ marginLeft: '20px', width: '70%', float: 'left' }}
                            value={checkNomorBon}
                            onChange={(ev, value) => setCheckNomorBon(value)}
                            options={listNomorBon}
                            renderInput={(params) => <TextField {...params} label="Cari Bon" />}
                        />
                        <Button variant="contained" color="primary" style={{ padding: '20px' }} onClick={(ev) => {
                            if (checkNomorBon) showPreview(checkNomorBon)
                        }}>Check</Button>
                    </div>
                    {isFetching ? <CircularProgress /> :
                        <>
                            <Table className="userTable" aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="userTableCell" align="left">No</TableCell>
                                        <TableCell className="userTableCell" align="left">Nomor Bon</TableCell>
                                        <TableCell className="userTableCell" align="left">Item</TableCell>
                                        <TableCell className="userTableCell" align="left">Tanggal</TableCell>
                                        <TableCell className="userTableCell" align="left">Admin</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listBon.map((row, idx) => (
                                        <TableRow hover key={row.nomor_bon} style={{ textAlign: 'center' }}>
                                            <TableCell className="userTableCell" align="left">{idx + 1 + ((pageNumber - 1) * 8)}</TableCell>
                                            {CustomTableCell({ ...{ row, name: "nomor_bon" } })}
                                            {CustomTableCell({ ...{ row, name: "item" } })}
                                            {CustomTableCell({ ...{ row, name: "tanggal" } })}
                                            {CustomTableCell({ ...{ row, name: "admin" } })}
                                            <TableCell className="userTableCell" align="left">
                                                <Button variant="contained" color="primary" onClick={ev => showPreview(row.nomor_bon)}>
                                                    Details
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>{pageCount}
                        </>}
                </Grid>
            </Grid>
        </React.Fragment >
    );
}