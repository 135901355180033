import React from 'react';
import sjlogo from '../Images/sjlogo.jpg';

export class SuratJalanToPrint extends React.PureComponent {
    render() {
        const headerStyle = { height: '75px', overflow: 'hidden', width: '100%' };
        const logoStyle = { width: '6%', float: 'left', marginTop: '10px' };
        const headerInfoStyle = { margin: '0', fontWeight: '700', fontSize: '16px', lineHeight: '18px' };
        const thStyle = { width: '20%', border: '1px solid black', fontSize: '18px', lineHeight: '20px', borderCollapse: 'collapse' }
        const tdStyle = { fontSize: '14px', lineHeight: '16px', fontWeight: '550', paddingLeft: '5px' }
        const listBarang = this.props.bonData.barang.split(', ');
        const listJumlahBarang = this.props.bonData.jumlah_barang.split(', ');
        // const totalHarga = () => {
        //     let harga = 0
        //     this.props.bonData[2].forEach((val, idx, arr) => {
        //         if (val.status_barang === "Cancel") return;
        //         const totalHargaPerItem = val.jumlah_barang * this.props.bonData[1].find((val1, idx1, arr1) => val.id_detail_barang.includes(val1.id_barang_penjualan + "_")).harga_barang;
        //         harga += totalHargaPerItem
        //     })
        //     return harga
        // }

        return (
            <div style={{ marginLeft: '10px', marginTop: '25px', fontFamily: 'Arial Narrow, sans-serif', width: '21.5cm' }}>
                <div style={headerStyle}>
                    <img src={sjlogo} style={logoStyle} />
                    <div style={{ float: 'left', marginLeft: '5px', width: '10%' }}>
                        <p style={headerInfoStyle}>ID SJ</p>
                        <p style={headerInfoStyle}>Store</p>
                        <p style={headerInfoStyle}>No Telp</p>
                        <p style={headerInfoStyle}>Alamat</p>
                    </div>
                    <div style={{ float: 'left', marginLeft: '0px', width: '37%' }}>
                        <p style={headerInfoStyle}>: {this.props.bonData.id_surat_jalan}</p>
                        <p style={headerInfoStyle}>: {this.props.bonData.id_customer.toUpperCase()}</p>
                        <p style={headerInfoStyle}>: {this.props.bonData.customer.telp_customer}</p>
                        <p style={headerInfoStyle}>: {this.props.bonData.customer.alamat_customer}</p>
                    </div>
                    <div style={{ float: 'left', marginLeft: '15px', width: '8%' }}>
                        <p style={headerInfoStyle}>Created</p>
                        <p style={headerInfoStyle}>Printed</p>
                        <p style={headerInfoStyle}>ID Bon</p>
                        <p style={headerInfoStyle}>Admin</p>
                    </div>
                    <div style={{ float: 'left', marginLeft: '0px', width: '35%' }}>
                        <p style={headerInfoStyle}>: {new Date(parseInt(this.props.bonData.id_surat_jalan)).toLocaleDateString("id-ID", { year: "numeric", month: "long", day: "numeric", weekday: "long" })}</p>
                        <p style={headerInfoStyle}>: {new Date().toLocaleDateString("id-ID", { year: "numeric", month: "long", day: "numeric", weekday: "long" })}</p>
                        <p style={headerInfoStyle}>: {this.props.bonData.id_bon_penjualan.split("-")[0] + " - " + this.props.bonData.no_bon_penjualan}</p>
                        <p style={headerInfoStyle}>: {btoa(this.props.bonData.admin.toUpperCase())}</p>
                    </div>
                </div>
                <div style={{ height: '260px', borderBottom: '1px solid black' }}>
                    <table style={{ borderCollapse: 'collapse', width: '95%' }}>
                        <thead>
                            <tr>
                                <th style={{ width: '40%', border: '1px solid black', fontSize: '16px', lineHeight: '18px' }}>Nama Barang</th>
                                <th style={thStyle}>Jumlah Barang</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listBarang.map((val, idx, arr) => {
                                return (<tr key={"item_" + idx}>
                                    <td style={{ fontSize: '14px', lineHeight: '16px', fontWeight: '550', paddingLeft: '5px', textTransform: 'uppercase' }}>{val.includes("(Custom)") ? val.replace("(Custom)", "") : val}</td>
                                    <td style={tdStyle}>{listJumlahBarang[idx]}</td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
                <div style={{ height: '75px', marginTop: '5px', borderBottom: '1px solid black' }}>
                    <div style={{ float: 'left', width: '58%' }}>
                        <div style={{ float: 'left', width: '18%' }}>
                            <p style={{ margin: 0, fontWeight: '600', fontSize: '15px', lineHeight: '16px', height: '32px' }}>Pengantaran</p>
                            <p style={{ margin: 0, fontWeight: '600', fontSize: '15px', lineHeight: '16px', height: '32px' }}>Note</p>
                        </div>
                        <div style={{ float: 'left', overflow: 'hidden', width: '82%' }}>
                            <p style={{ margin: 0, fontWeight: '600', fontSize: '15px', lineHeight: '16px', height: '32px', overflow: 'hidden' }}>: {this.props.bonData.alamat_pengantaran}</p>
                            <p style={{ margin: 0, fontWeight: '600', fontSize: '15px', lineHeight: '16px', height: '32px', overflow: 'hidden' }}>: {this.props.bonData.keterangan}</p>
                        </div>
                    </div>
                    <div style={{ height: '75px', marginLeft: '15px', width: '40%', float: 'left' }}>
                        <div style={{ float: 'left' }}>
                            <p style={{ margin: '0', fontWeight: '600', fontSize: '15px', lineHeight: '16px' }}>Penerima </p>
                            <p style={{ margin: '0', fontWeight: '600', fontSize: '15px', lineHeight: '16px' }}>HP Penerima </p>
                            <p style={{ margin: '0', fontWeight: '600', fontSize: '15px', lineHeight: '16px' }}>Supir </p>
                            <p style={{ margin: '0', fontWeight: '600', fontSize: '15px', lineHeight: '16px' }}>Mobil </p>
                        </div>
                        <div style={{ float: 'left' }}>
                            <p style={{ margin: '0', fontWeight: '600', fontSize: '15px', lineHeight: '16px' }}> : &nbsp;{this.props.bonData.penerima}</p>
                            <p style={{ margin: '0', fontWeight: '600', fontSize: '15px', lineHeight: '16px' }}> : &nbsp;{this.props.bonData.nomor_telepon}</p>
                            <p style={{ margin: '0', fontWeight: '600', fontSize: '15px', lineHeight: '16px' }}> : &nbsp;{this.props.bonData.nama_supir}</p>
                            <p style={{ margin: '0', fontWeight: '600', fontSize: '15px', lineHeight: '16px' }}> : &nbsp;{this.props.bonData.plat_kendaraan}</p>
                        </div>
                    </div>
                </div>
                <div style={{ textAlign: 'center', marginTop: '5px', clear: 'both' }}>
                    <p style={{ margin: '0', float: 'left', fontSize: '14px', lineHeight: '16px', width: '24%' }}>Telly</p>
                    <p style={{ margin: '0', float: 'left', fontSize: '14px', lineHeight: '16px', width: '24%' }}>Supir</p>
                    <p style={{ margin: '0', float: 'left', fontSize: '14px', lineHeight: '16px', width: '24%' }}>Diperiksa Oleh</p>
                    <p style={{ margin: '0', float: 'left', fontSize: '14px', lineHeight: '16px', width: '24%' }}>Diterima Oleh</p>
                </div>
            </div >
        );
    }
}