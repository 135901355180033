import React, { useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, Tooltip } from 'recharts';
import Title from './Title';
import { names } from '../../Settings/globalVar';
import CircularProgress from '@material-ui/core/CircularProgress';

function createData(time, cash, transfer) {
    return { time, cash, transfer };
}

export default function Chart(props) {
    const theme = useTheme();

    const { id_barang, tanggal } = props;
    const [chartData, setChartData] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)

    useEffect(() => {
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_cat_super/get_cat_transactions_chart_data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
            body: JSON.stringify({ id_barang: id_barang, tanggal: tanggal.month_year })
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsLoading(false)
                return
            }
            else if (res.status === 500) throw new Error("Internal Server Error");
            return res.json()
        }).then((res) => {
            const newChartData = []
            const resData = res.data.transaksiData
            const maxCount = res.data.pageCount
            for (let idx = 0; idx < maxCount; idx++) {
                const index = idx + 1;
                const day = ('0' + index).slice(-2)
                const dataTransaksiCashDebit = resData.find(val => val.tanggal === day + " " + tanggal.month_year && val.jenis_transaksi === "Cash" && val.alur_transaksi === "Debit");
                const dataTransaksiCashKredit = resData.find(val => val.tanggal === day + " " + tanggal.month_year && val.jenis_transaksi === "Cash" && val.alur_transaksi === "Kredit");
                const dataTransaksiTransferDebit = resData.find(val => val.tanggal === day + " " + tanggal.month_year && val.jenis_transaksi === "Transfer" && val.alur_transaksi === "Debit");
                const dataTransaksiTransferKredit = resData.find(val => val.tanggal === day + " " + tanggal.month_year && val.jenis_transaksi === "Transfer" && val.alur_transaksi === "Kredit");
                newChartData.push(createData(day, (dataTransaksiCashDebit ? parseInt(dataTransaksiCashDebit.total) : 0) - (dataTransaksiCashKredit ? parseInt(dataTransaksiCashKredit.total) : 0), (dataTransaksiTransferDebit ? parseInt(dataTransaksiTransferDebit.total) : 0) - (dataTransaksiTransferKredit ? parseInt(dataTransaksiTransferKredit.total) : 0)))
            }
            setChartData(newChartData)
            setIsLoading(false)
        }).catch((ex) => {
            setIsLoading(false)
            alert(ex)
        })
    }, [id_barang, tanggal])

    return (
        <React.Fragment>
            <Title>Per - {tanggal.month_name_year}</Title>
            {isLoading ? <CircularProgress style={{ margin: 'auto' }} /> :
                <ResponsiveContainer>
                    <LineChart
                        data={chartData}
                        margin={{
                            top: 10,
                            right: 16,
                            bottom: 5,
                            left: 24,
                        }}>
                        <XAxis dataKey="time">
                            <Label position="center" dy={10} style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}>
                                Tanggal
                            </Label>
                        </XAxis>
                        <Tooltip labelFormatter={(value) => value + " " + tanggal.month_name_year} formatter={(value) => parseInt(value).toLocaleString('id-ID')} />
                        <YAxis stroke={theme.palette.text.secondary} tickFormatter={(value) => parseInt(value).toLocaleString('id-ID')} form>
                            <Label angle={270} position="left" style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}>
                                Nominal
                            </Label>
                        </YAxis>
                        <Line type="monotone" dataKey="cash" stroke="#008000" dot={false} />
                        <Line type="monotone" dataKey="transfer" stroke="#191970" dot={false} />
                    </LineChart>
                </ResponsiveContainer>}
        </React.Fragment >
    );
}