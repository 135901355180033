import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import { names } from '../../Settings/globalVar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

// // Generate Order Data
// function createData(id, date, name, shipTo, paymentMethod, amount) {
//     return { id, date, name, shipTo, paymentMethod, amount };
// }

// const rows = [
//     createData(0, '16 Mar, 2019', 'Elvis Presley', 'Tupelo, MS', 'VISA ⠀•••• 3719', 312.44),
//     createData(1, '16 Mar, 2019', 'Paul McCartney', 'London, UK', 'VISA ⠀•••• 2574', 866.99),
//     createData(2, '16 Mar, 2019', 'Tom Scholz', 'Boston, MA', 'MC ⠀•••• 1253', 100.81),
//     createData(3, '16 Mar, 2019', 'Michael Jackson', 'Gary, IN', 'AMEX ⠀•••• 2000', 654.39),
//     createData(4, '15 Mar, 2019', 'Bruce Springsteen', 'Long Branch, NJ', 'VISA ⠀•••• 5919', 212.79),
// ];

// function preventDefault(event) {
//     event.preventDefault();
// }

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    previewPaper: {
        // textAlign: 'left',
        padding: '10px',
        width: '600px',
        margin: 'auto',
        marginTop: '100px'
    },
    previewContainer: {
        // textAlign: 'center',
        zIndex: '1',
        position: 'fixed',
        left: '0',
        right: '0',
        top: '0',
        margin: 'auto',
        backgroundColor: 'rgba(55,55,55,0.3)',
        height: '100vh',
        overflowY: 'scroll',
    },
    userTableCell: {
        maxWidth: '100px',
        paddingRight: '0px'
    }
}));

export default function Orders(props) {
    const classes = useStyles();
    const { id_barang, tanggal, jenis_transaksi } = props;
    const [day, setDay] = React.useState(('0' + new Date().getDate()).slice(-2))
    const [listTransaksi, setListTransaksi] = React.useState([])
    const [listPage, setListPage] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/super_admin/get_transaksi_dashboard`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
            body: JSON.stringify({ id_barang: id_barang, tanggal: day + " " + tanggal.month_year, jenisTransaksi: jenis_transaksi })
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsLoading(false)
                return
            }
            else if (res.status === 500) throw new Error("Internal Server Error");
            return res.json()
        }).then((jsonData) => {
            const rowsData = jsonData.data.allTransaksiData;
            const pagesData = jsonData.data.pageCount;
            const newPages = []
            for (let i = 0; i < pagesData; i++) {
                const index = i + 1
                newPages.push(
                    <Button key={index} className="next-page" color={day === ('0' + index).slice(-2) ? "primary" : "default"} style={{ marginTop: "5px", marginLeft: "5px" }
                    } variant="contained" onClick={(ev) => {
                        if (day !== ('0' + index).slice(-2)) setDay(('0' + index).slice(-2))
                    }}> {index}</Button >
                )
            }
            setListTransaksi(rowsData)
            setListPage(newPages)
            setIsLoading(false)
        }).catch((ex) => {
            setIsLoading(false)
            alert(ex)
        })
    }, [id_barang, tanggal, day, jenis_transaksi])

    return (
        <React.Fragment>
            <Title>{day} {tanggal.month_name_year} Recent Orders</Title>
            <div style={{ padding: '10px' }}>
                <Typography>
                    Tanggal :
                </Typography>
                {listPage}
            </div>
            {isLoading ? <CircularProgress /> :
                <>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}><Typography variant="h6">Transaksi Debit - Rp {listTransaksi.filter((val, idx, arr) => val.alur_transaksi === "Debit").reduce((a, b) => a + b.jumlah_transaksi, 0).toLocaleString('id-ID')}</Typography></AccordionSummary>
                        <AccordionDetails>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nomor Transaksi</TableCell>
                                        <TableCell>ID Bon</TableCell>
                                        <TableCell>Customer</TableCell>
                                        <TableCell>Jumlah Transaksi</TableCell>
                                        <TableCell>Jenis Transaksi</TableCell>
                                        <TableCell>Alur Transaksi</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listTransaksi.filter((val, idx, arr) => val.alur_transaksi === "Debit").map((val, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell>{val.id_transaksi_penjualan}</TableCell>
                                            <TableCell>{val.id_bon_penjualan}</TableCell>
                                            <TableCell>{val.id_customer}</TableCell>
                                            <TableCell>Rp {parseInt(val.jumlah_transaksi).toLocaleString('id-ID')}</TableCell>
                                            <TableCell>{val.jenis_transaksi}</TableCell>
                                            <TableCell>{val.alur_transaksi}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}><Typography variant="h6">Transaksi Kredit - Rp {listTransaksi.filter((val, idx, arr) => val.alur_transaksi === "Kredit").reduce((a, b) => a + b.jumlah_transaksi, 0).toLocaleString('id-ID')}</Typography></AccordionSummary>
                        <AccordionDetails>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nomor Bon</TableCell>
                                        <TableCell>ID Bon</TableCell>
                                        <TableCell>Customer</TableCell>
                                        <TableCell>Jumlah Transaksi</TableCell>
                                        <TableCell>Jenis Transaksi</TableCell>
                                        <TableCell>Alur Transaksi</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listTransaksi.filter((val, idx, arr) => val.alur_transaksi === "Kredit").map((val, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell>{val.id_transaksi_penjualan}</TableCell>
                                            <TableCell>{val.id_bon_penjualan}</TableCell>
                                            <TableCell>{val.id_customer}</TableCell>
                                            <TableCell>Rp {parseInt(val.jumlah_transaksi).toLocaleString('id-ID')}</TableCell>
                                            <TableCell>{val.jenis_transaksi}</TableCell>
                                            <TableCell>{val.alur_transaksi}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </>
            }
        </React.Fragment>
    );
}