import React from 'react';
import { Button, CircularProgress } from '@material-ui/core'
import { InputOutlined } from '@material-ui/icons'
import { names } from '../Settings/globalVar'
import logo from '../Images/trimustlogo.png'
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    signOut: {
        textAlign: 'center',
        marginTop: '5vh'
    },
    logo: {
        width: '200px',
        borderRadius: '100px'
    },
    signOutButton: {
        width: '150px',
        height: '50px'
    },
    signOutTitle: {
        color: 'white'
    },
    iconButton: {
        padding: '5px',
    },
    divider: {
        height: '20px',
        backgroundColor: 'rgba(0,0,0,0)'
    }
}))

export default function SignOut() {
    const classes = useStyles()
    const [isLoading, setIsLoading] = React.useState(false)
    const routerHistory = useHistory()
    function signOut() {
        setIsLoading(true)
        localStorage.removeItem(names.token)
        routerHistory.replace("")
        setIsLoading(false)
        window.location.reload();
    }

    return (
        <div className={classes.signOut} >
            <img className={classes.logo} src={logo} alt={logo} />
            <h1 className={classes.signOutTitle}>Sign Out</h1>
            <Button variant="contained" className={classes.signOutButton} color="primary" onClick={signOut}>
                <InputOutlined className={classes.iconButton} />{"SIGN OUT"}
            </Button>
            <div className={classes.divider} />
            {isLoading ? <CircularProgress /> : null}
        </div>
    )
}