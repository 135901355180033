import React, { useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { names } from '../Settings/globalVar';
import { Close as CloseIcon, Remove as RemoveIcon, Add as AddIcon, Publish as SubmitIcon, Delete as DeleteIcon, Eject as LoadingIcon, AssignmentTurnedIn as FinishIcon, Print as PrintIcon } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useReactToPrint } from 'react-to-print';
import { SuratJalanToPrint } from '../Components/SuratJalanForm';

const useStyles = makeStyles((theme) => ({
    transactionsContainer: {
        width: '100%'
    },
    transactionsForm: {
        width: '100%',
        padding: '10px'
    },
    loadingIndicator: {
        margin: 'auto',
    },
    button: {
        marginLeft: '20px',
        width: '150px',
        height: '50px'
    },
    previewPaper: {
        // textAlign: 'left',
        padding: '10px',
        width: '95%',
        margin: 'auto',
        marginTop: '100px',
        marginBottom: '50px'
    },
    previewContainer: {
        // textAlign: 'center',
        zIndex: '1',
        position: 'fixed',
        left: '0',
        right: '0',
        top: '0',
        margin: 'auto',
        backgroundColor: 'rgba(55,55,55,0.3)',
        height: '100vh',
        overflowY: 'scroll',
    },
    dividerSection: {
        width: '100%',
        border: '1px solid black',
        marginTop: '10px',
        marginBottom: '10px'
    },
    inputBarang: {
        width: '100%'
    },
}))

export default function AddSales() {
    const classes = useStyles();
    const [filterNoBonPenjualan, setFilterNoBonPenjualan] = React.useState("");
    const [filterIdCustomer, setFilterIdCustomer] = React.useState("");
    const [listBon, setListBon] = React.useState([]);
    const [listHistory, setListHistory] = React.useState([]);
    const [customerListHistory, setCustomerListHistory] = React.useState(null)
    // const [listIdBon, setListIdBon] = React.useState([]);
    const [bonData, setBonData] = React.useState(null);
    const [barangJalanData, setBarangJalanData] = React.useState(null);
    const [isCreatingSuratJalan, setIsCreatingSuratJalan] = React.useState(false);
    const [isLoadingBon, setIsLoadingBon] = React.useState(false);
    const [isLoadingHistory, setIsLoadingHistory] = React.useState(false);
    const [isSubmittingSuratJalan, setIsSubmittingSuratJalan] = React.useState(false);
    const [isPreviewingHistorySuratJalan, setIsPreviewingHistorySuratJalan] = React.useState(false);
    const [suratJalanData, setSuratJalanData] = React.useState([null])

    const [penerima, setPenerima] = React.useState("-")
    const [alamatPengantaran, setAlamatPengantaran] = React.useState("-")
    const [nomorTelepon, setNomorTelepon] = React.useState("-")
    const [keteranganTambahan, setKeteranganTambahan] = React.useState("-")
    const [kendaraan, setKendaraan] = React.useState("")
    const [supir, setSupir] = React.useState("")
    const [isCustomerSesuaiBon, setIsCustomerSesuaiBon] = React.useState(false)
    const [isAlamatSesuaiBon, setIsAlamatSesuaiBon] = React.useState(false)
    const [isNomorSesuaiBon, setIsNomorSesuaiBon] = React.useState(false)
    const [isKeteranganSesuaiBon, setIsKeteranganSesuaiBon] = React.useState(false)

    const [isEditingSuratJalan, setIsEditingSuratJalan] = React.useState(false);
    const [suratJalanViewData, setSuratJalanViewData] = React.useState(null);
    const [isDeletingSuratJalan, setIsDeletingSuratJalan] = React.useState(false);
    const [isUpdatingSuratJalan, setIsUpdatingSuratJalan] = React.useState(false);

    const [suratJalanPrintData, setSuratJalanPrintData] = React.useState(null);
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function getBonPenjualanForSuratJalan() {
        if (!filterNoBonPenjualan && !filterIdCustomer) return alert("Tolong Beri Minimal 1 Input Filter Pencarian !!!");
        setIsLoadingBon(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_logistik/get_bon_penjualan_for_surat_jalan?noBon=${filterNoBonPenjualan}&idCustomer=${filterIdCustomer}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            }
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsLoadingBon(false)
                return
            }
            return res.json()
        }).then((res) => {
            if (!res["success"]) throw (new Error(res["message"]))
            else {
                setListBon(res.data)
                setIsLoadingBon(false)
            }
        }).catch(ex => {
            alert(ex)
            setIsLoadingBon(false)
        })
    }

    function getDetailBonPenjualan(id_bon_penjualan) {
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_logistik/get_detail_bon_penjualan_for_surat_jalan/${id_bon_penjualan}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            }
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                return
            }
            return res.json()
        }).then((res) => {
            if (!res["success"]) throw (new Error(res["message"]))
            const jsonData1 = JSON.stringify(res.data)
            const jsonData2 = JSON.stringify(res.barang)
            setBonData(JSON.parse(jsonData1))
            setBarangJalanData(JSON.parse(jsonData2))
        }).catch(ex => {
            alert(ex)
            setIsCreatingSuratJalan(false)
        })
    }

    function addSuratJalan(needPrint = true, idBonPenjualan, noBonPenjualan, idCustomer) {
        setIsSubmittingSuratJalan(true);
        const bodyData = { suratJalanData: suratJalanData, alamatPengantaran: alamatPengantaran, nomorTelepon: nomorTelepon, idBonPenjualan: idBonPenjualan, noBonPenjualan: noBonPenjualan, keteranganTambahan: keteranganTambahan, idCustomer: idCustomer, platKendaraan: kendaraan, supir: supir, penerima: penerima }
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_logistik/input_surat_jalan`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
            body: JSON.stringify(bodyData)
        }).then(async (res) => {
            const jsonData = await res.json()
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                return
            }
            else if (res.status === 400 || res.status === 409) {
                alert(jsonData.message)
                setIsSubmittingSuratJalan(false)
                return
            }

            if (res.status === 200) {
                getDetailBonPenjualan(idBonPenjualan)
                setCustomerListHistory(jsonData.data.dataCustomer);
                setListHistory(jsonData.data.historySuratJalan);
                const temp = JSON.stringify(jsonData.data.historySuratJalan.find((val, idx, arr) => val.id_surat_jalan == jsonData.data.idSuratJalan));
                const tempData = JSON.parse(temp);
                tempData.customer = jsonData.data.dataCustomer;
                setSuratJalanViewData(tempData);

                ResetSuratJalanTemplate()
                setIsCreatingSuratJalan(false);
                setIsLoadingHistory(false)
                setIsPreviewingHistorySuratJalan(true)
                setIsEditingSuratJalan(true);
            }
            alert(jsonData.message)
            setIsSubmittingSuratJalan(false)
        }).catch((ex) => {
            alert(ex)
            setIsSubmittingSuratJalan(false)
        })
    }

    function getBonSuratJalanHistory(idBonPenjualan) {
        setIsLoadingHistory(true);
        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_logistik/get_history_surat_jalan/${idBonPenjualan}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            }
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsLoadingHistory(false)
                return
            }
            return res.json()
        }).then((res) => {
            if (!res["success"]) throw (new Error(res["message"]))
            else {
                setCustomerListHistory(res.data.dataCustomer);
                setListHistory(res.data.historySuratJalan);
                setIsLoadingHistory(false)
                // console.log(res.data.dataCustomer);
            }
        }).catch(ex => {
            alert(ex)
            setIsLoadingHistory(false)
        })
    }

    function getKuotaBarang(dataEntry) {
        if (dataEntry === null) return 0
        const barangJalan = barangJalanData.find((val1) => val1.id_barang == dataEntry.id_barang)
        if (barangJalanData.length < 1 || barangJalan == undefined || barangJalan == null) return bonData[2].filter((val1, idx1, arr1) => val1.id_detail_barang.includes(dataEntry.id_barang_penjualan) && val1.status_barang != "Cancel").reduce((a, b) => a + parseInt(b.jumlah_barang), 0)

        return bonData[2].filter((val1, idx1, arr1) => val1.id_detail_barang.includes(dataEntry.id_barang_penjualan) && val1.status_barang != "Cancel").reduce((a, b) => a + parseInt(b.jumlah_barang), 0) - barangJalan.jumlah_barang
    }

    function getRowColorByStatus(status) {
        if (status == "Open") return "white"
        if (status == "Loading") return "#FFFF8F"
        if (status == "On The Way") return "#F28C28"
        if (status == "Finish") return "#50C878"
        return "red"
    }

    function ResetSuratJalanTemplate() {
        setSuratJalanData([null])
        setKendaraan("")
        setSupir("")
        setKeteranganTambahan("")
        setIsKeteranganSesuaiBon(false)
        setPenerima("")
        setIsCustomerSesuaiBon(false)
        setAlamatPengantaran("")
        setIsAlamatSesuaiBon(false)
        setNomorTelepon("")
        setIsNomorSesuaiBon(false)
    }

    function DeleteSuratJalan(idSuratJalan, idBonPenjualan) {
        setIsDeletingSuratJalan(true);
        const bodyData = { idSuratJalan: idSuratJalan }

        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_logistik/delete_surat_jalan`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
            body: JSON.stringify(bodyData)
        }).then(async (res) => {
            const jsonData = await res.json()
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                return
            }
            else if (res.status === 400 || res.status === 409) {
                alert(jsonData.message)
                setIsDeletingSuratJalan(false)
                return
            }

            if (res.status === 200) {
                setIsEditingSuratJalan(false);
                setSuratJalanViewData(null);
                getBonSuratJalanHistory(idBonPenjualan);
            }
            alert(jsonData.message)
            setIsDeletingSuratJalan(false)
        }).catch((ex) => {
            alert(ex)
            setIsDeletingSuratJalan(false)
        })
    }

    function UpdateStatusSuratJalan(idSuratJalan, statusSuratJalan, idBonPenjualan) {
        setIsUpdatingSuratJalan(true);
        const bodyData = { idSuratJalan: idSuratJalan, statusSuratJalan: statusSuratJalan }

        fetch(`${process.env.REACT_APP_HOSTNAME}/admin_logistik/update_surat_jalan`, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
            body: JSON.stringify(bodyData)
        }).then(async (res) => {
            const jsonData = await res.json()
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                return
            }
            else if (res.status === 400 || res.status === 409) {
                alert(jsonData.message)
                setIsUpdatingSuratJalan(false)
                return
            }

            if (res.status === 200) {
                setIsUpdatingSuratJalan(false);
                const tempString = JSON.stringify(suratJalanViewData);
                const temp = JSON.parse(tempString);
                temp.status = statusSuratJalan;
                setSuratJalanViewData(temp);
                getBonSuratJalanHistory(idBonPenjualan);
            }
            alert(jsonData.message)
            setIsUpdatingSuratJalan(false)
        }).catch((ex) => {
            alert(ex)
            setIsUpdatingSuratJalan(false)
        })
    }

    function GenerateItemSuratJalan(barang, jumlahBarang) {
        console.log(suratJalanViewData)
        const listBarang = barang.split(", ");
        const listJumlahBarang = jumlahBarang.split(", ");

        return listBarang.map((val, idx, arr) => {
            return (
                <>
                    <Grid item xs={12} sm={2}>
                        <Typography style={{ paddingTop: '10px' }} variant="h6">Barang Ke - {idx + 1}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField label={"Nama Barang"} value={val} style={{ width: '100%', color: 'black' }} />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField label={"Jumlah Barang"} value={listJumlahBarang[idx]} style={{ width: '100%', color: 'black' }} />
                    </Grid>
                    <Divider style={{ height: '1px', width: '100%', backgroundColor: 'white' }} />
                </>
            )
        })
    }

    return (
        <div className={classes.transactionsContainer}>
            {suratJalanPrintData !== null ? <div style={{ display: 'none' }}><SuratJalanToPrint ref={componentRef} bonData={suratJalanPrintData} /></div> : null}
            {isCreatingSuratJalan ? <div className={classes.previewContainer}><Paper className={classes.previewPaper}>
                {bonData == null || isSubmittingSuratJalan ? <CircularProgress style={{ marginLeft: '46%' }} /> : <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={2}>
                            <TextField label={"Referensi ID Bon"} value={bonData[0].id_bon_penjualan} style={{ width: '100%', color: 'black' }} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField label={"Referensi No Bon"} value={bonData[0].no_bon_penjualan} style={{ width: '100%', color: 'black' }} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField label={"Nama Customer"} value={bonData[0].id_customer} style={{ width: '100%', color: 'black' }} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField label={"Telp Customer"} value={bonData[0].telp_customer || "-"} style={{ width: '100%', color: 'black' }} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField label={"Alamat Customer"} value={bonData[0].alamat_customer || "-"} style={{ width: '100%', color: 'black' }} />
                        </Grid>
                    </Grid>
                    <Divider className={classes.dividerSection} />
                    <Typography variant="h6">Pilih Barang Yang Mau Di-Antar</Typography>
                    <Grid container spacing={3}>
                        {suratJalanData.map((val, idx, arr) => {
                            return (<>
                                <Grid item xs={12} sm={2} key={`title ${idx}`}>
                                    <Typography style={{ paddingTop: '10px' }} variant="h6">Barang Ke - {idx + 1}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4} key={`id barang ${idx}`}>
                                    <Autocomplete
                                        className={classes.inputBarang}
                                        options={bonData[1].filter((val, idx, arr) => {
                                            const jumlahBarangYangTidakBisaDiAntar = bonData[2].filter((val1, idx1, arr1) => val1.id_detail_barang.includes(val.id_barang_penjualan) && val1.status_barang == "Cancel").reduce((a, b) => a + parseInt(b.jumlah_barang), 0)
                                            if (jumlahBarangYangTidakBisaDiAntar === val.jumlah_barang) return false
                                            return true
                                        })}
                                        onChange={(ev, value) => {
                                            let temp = []
                                            temp = temp.concat(arr);
                                            temp[idx] = value;
                                            if (value !== null) {
                                                const listIdDetailBarang = bonData[2].filter((val1, idx1, arr1) => val1.id_detail_barang.includes(value.id_barang_penjualan) && val1.status_barang != "Cancel").map((val1, idx1, arr1) => val1.id_detail_barang).join(", ");
                                                temp[idx].jumlah_barang_jalan = 0;
                                                temp[idx].id_detail_barang = listIdDetailBarang;
                                            }
                                            const tempJsonString = JSON.stringify(temp)
                                            setSuratJalanData(JSON.parse(tempJsonString));
                                        }}
                                        value={val}
                                        getOptionLabel={(opt) => opt.id_barang || ""}
                                        getOptionSelected={(opt, val) => opt.id_barang === val.id_barang}
                                        renderInput={(params) => <TextField {...params} label="ID Barang" />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2} key={`quantity ${idx}`}>
                                    <TextField type="number" label="Quantity" value={val ? val.jumlah_barang_jalan : 0} style={{ width: '100%', color: 'black' }} onChange={(ev) => {
                                        if (val === null || val == "") return
                                        if (parseInt(ev.target.value) > getKuotaBarang(val) || parseInt(ev.target.value) < 0) return
                                        let temp = [];
                                        temp = temp.concat(arr);
                                        temp[idx].jumlah_barang_jalan = parseInt(ev.target.value);
                                        setSuratJalanData(temp);
                                    }} />
                                </Grid>
                                <Grid item xs={12} sm={1} key={`kuota ${idx}`}>
                                    <TextField style={{ width: '100%' }} type="number" label="Kuota" disabled value={getKuotaBarang(val)} />
                                </Grid>
                                <Grid item xs={12} sm={3} key={`remove ${idx}`}>
                                    <Button color="secondary" variant="contained" startIcon={<RemoveIcon />} onClick={(ev) => {
                                        let temp = [];
                                        temp = temp.concat(arr);
                                        if (temp.length === 1) return
                                        temp.splice(idx, 1)
                                        setSuratJalanData(temp);
                                    }}>
                                        Remove
                                    </Button>
                                </Grid>
                            </>)
                        })}
                    </Grid>
                    <Button style={{ marginTop: '20px' }} startIcon={<AddIcon />} color="primary" variant="contained" onClick={(ev) => {
                        let temp = [];
                        temp = temp.concat(suratJalanData);
                        temp.push(null)
                        setSuratJalanData(temp);
                    }}>
                        Add More Item
                    </Button>
                    <Divider className={classes.dividerSection} />
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={1}>
                            <Typography variant="h6">Nama Penerima</Typography>
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <FormControlLabel control={<Checkbox checked={isCustomerSesuaiBon} onChange={(ev) => {
                                if (ev.target.checked) setPenerima(bonData[0].id_customer)
                                else setPenerima("-");
                                setIsCustomerSesuaiBon(ev.target.checked)
                            }} />} label="Sesuai Bon" />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField style={{ width: '85%' }} label="Nama Penerima" disabled={isCustomerSesuaiBon} value={penerima} onChange={(ev) => {
                                setPenerima(ev.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <Typography variant="h6">Alamat Penerima</Typography>
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <FormControlLabel control={<Checkbox checked={isAlamatSesuaiBon} onChange={(ev) => {
                                if (ev.target.checked) setAlamatPengantaran(bonData[0].alamat_pengantaran)
                                else setAlamatPengantaran("-");
                                setIsAlamatSesuaiBon(ev.target.checked)
                            }} />} label="Sesuai Bon" />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField style={{ width: '85%' }} label="Alamat Penerima" disabled={isAlamatSesuaiBon} value={alamatPengantaran} onChange={(ev) => {
                                setAlamatPengantaran(ev.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <Typography variant="h6">Telepon Penerima</Typography>
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <FormControlLabel control={<Checkbox checked={isNomorSesuaiBon} onChange={(ev) => {
                                if (ev.target.checked) setNomorTelepon(bonData[0].telp_customer)
                                else setNomorTelepon("-");
                                setIsNomorSesuaiBon(ev.target.checked)
                            }} />} label="Sesuai Bon" />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField style={{ width: '85%' }} label="Telepon Penerima" disabled={isNomorSesuaiBon} value={nomorTelepon} onChange={(ev) => {
                                setNomorTelepon(ev.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <Typography variant="h6">Keterangan Tambahan</Typography>
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <FormControlLabel control={<Checkbox checked={isKeteranganSesuaiBon} onChange={(ev) => {
                                if (ev.target.checked) setKeteranganTambahan(bonData[0].keterangan)
                                else setKeteranganTambahan("-");
                                setIsKeteranganSesuaiBon(ev.target.checked)
                            }} />} label="Sesuai Bon" />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField style={{ width: '85%' }} label="Keterangan Tambahan" disabled={isKeteranganSesuaiBon} value={keteranganTambahan} onChange={(ev) => {
                                setKeteranganTambahan(ev.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <Typography variant="h6">Plat Kendaraan</Typography>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <TextField style={{ width: '90%' }} label="Kendaraan" value={kendaraan} onChange={(ev) => {
                                setKendaraan(ev.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <Typography variant="h6">Nama Pengemudi</Typography>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <TextField style={{ width: '90%' }} label="Supir" value={supir} onChange={(ev) => {
                                setSupir(ev.target.value)
                            }} />
                        </Grid>
                    </Grid>
                    <Divider className={classes.dividerSection} />
                    <Button style={{ marginRight: '10px' }} color='secondary' variant='contained' startIcon={<CloseIcon />} onClick={(ev) => {
                        setBonData(null);
                        setIsCreatingSuratJalan(false);
                        ResetSuratJalanTemplate()
                    }}>Close Menu</Button>
                    <Button style={{ marginRight: '20px' }} color='primary' variant='contained' startIcon={<SubmitIcon />} onClick={(ev) => {
                        addSuratJalan(true, bonData[0].id_bon_penjualan, bonData[0].no_bon_penjualan, bonData[0].id_customer);
                    }}>Submit And Print</Button>
                </>}
            </Paper></div> : null}

            {isPreviewingHistorySuratJalan ? <div className={classes.previewContainer}><Paper className={classes.previewPaper}>
                {isLoadingHistory ? <CircularProgress className={classes.loadingIndicator} /> : <>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID Surat Jalan || Tanggal</TableCell>
                                <TableCell>ID Customer || Penerima</TableCell>
                                <TableCell>Barang</TableCell>
                                <TableCell>Telp</TableCell>
                                <TableCell>Alamat</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Kendaraan</TableCell>
                                <TableCell>Admin</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listHistory.map((val, idx, arr) => {
                                return (
                                    <TableRow style={{ backgroundColor: getRowColorByStatus(val.status) }}>
                                        <TableCell>{val.id_surat_jalan + " || " + new Date(parseInt(val.id_surat_jalan)).toLocaleDateString("id-ID", { month: '2-digit', day: '2-digit', year: 'numeric' })}</TableCell>
                                        <TableCell>{val.id_customer + " || " + val.penerima}</TableCell>
                                        <TableCell>{val.barang}</TableCell>
                                        <TableCell>{val.nomor_telepon}</TableCell>
                                        <TableCell>{val.alamat_pengantaran}</TableCell>
                                        <TableCell>{val.status}</TableCell>
                                        <TableCell>{`${val.plat_kendaraan} (${val.nama_supir})`}</TableCell>
                                        <TableCell>{val.admin || "-"}</TableCell>
                                        <TableCell>
                                            <Button variant="contained" style={{ color: 'white', backgroundColor: 'green' }} className={classes.button} onClick={(ev) => {
                                                const temp = JSON.stringify(val);
                                                const tempData = JSON.parse(temp);
                                                tempData.customer = customerListHistory;
                                                setSuratJalanViewData(tempData);
                                                setIsEditingSuratJalan(true);
                                            }}>View</Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    <Button variant="contained" style={{ color: 'white', backgroundColor: 'red', marginTop: '20px' }} className={classes.button} onClick={(ev) => {
                        setIsPreviewingHistorySuratJalan(false)
                    }}>Close</Button>
                </>}
            </Paper></div> : null}

            {isSubmittingSuratJalan ? <CircularProgress className={classes.loadingIndicator} /> : <Paper className={classes.transactionsForm}>
                <Typography variant="h6">Buat Surat Jalan - {new Date().toLocaleDateString("id-ID", { year: "numeric", month: "long", day: "numeric", weekday: "long" })}</Typography>
                <TextField label={"ID Customer"} value={filterIdCustomer} onChange={(ev) => {
                    setFilterIdCustomer(ev.target.value)
                }} />
                <TextField style={{ marginLeft: '20px' }} label={"Nomor Bon Penjualan"} value={filterNoBonPenjualan} onChange={(ev) => {
                    setFilterNoBonPenjualan(ev.target.value)
                }} />
                <Button variant="contained" color="primary" style={{ marginLeft: '20px' }} className={classes.button} onClick={(ev) => {
                    getBonPenjualanForSuratJalan();
                }}>Search</Button>
                {isLoadingBon ? <CircularProgress className={classes.loadingIndicator} /> : <>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>No Bon</TableCell>
                                <TableCell>ID Bon</TableCell>
                                <TableCell>Tanggal</TableCell>
                                <TableCell>ID Customer</TableCell>
                                <TableCell>Alamat Pengantaran</TableCell>
                                <TableCell>ID Sales</TableCell>
                                <TableCell>ID Admin</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listBon.map((val, idx, arr) => {
                                return (
                                    <TableRow>
                                        <TableCell>{val.no_bon_penjualan}</TableCell>
                                        <TableCell>{val.id_bon_penjualan}</TableCell>
                                        <TableCell>{new Date(parseInt(val.tanggal)).toLocaleDateString("id-ID", { month: '2-digit', day: '2-digit', year: 'numeric' })}</TableCell>
                                        <TableCell>{val.id_customer}</TableCell>
                                        <TableCell>{val.alamat_pengantaran}</TableCell>
                                        <TableCell>{val.id_sales || "-"}</TableCell>
                                        <TableCell>{val.id_admin || "-"}</TableCell>
                                        <TableCell style={{ width: '200px' }}>
                                            <Button variant="contained" color="primary" className={classes.button} onClick={(ev) => {
                                                getDetailBonPenjualan(val.id_bon_penjualan)
                                                setIsCreatingSuratJalan(true)
                                            }}>Create</Button>
                                        </TableCell>
                                        <TableCell style={{ padding: 0, margin: 0, width: '200px' }}>
                                            <Button variant="contained" style={{ color: 'white', backgroundColor: 'green' }} className={classes.button} onClick={(ev) => {
                                                setIsPreviewingHistorySuratJalan(true)
                                                getBonSuratJalanHistory(val.id_bon_penjualan);
                                            }}>History</Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </>}
            </Paper>
            }

            {isEditingSuratJalan && !isDeletingSuratJalan ? <div className={classes.previewContainer}><Paper className={classes.previewPaper} >
                {suratJalanViewData == null || isUpdatingSuratJalan ? <CircularProgress style={{ marginLeft: '46%' }} /> : <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                            <TextField label={"Referensi ID Bon"} value={suratJalanViewData.id_bon_penjualan} style={{ width: '100%', color: 'black' }} />
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <TextField label={"Referensi No Bon"} value={suratJalanViewData.no_bon_penjualan} style={{ width: '100%', color: 'black' }} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField label={"Nama Customer"} value={suratJalanViewData.id_customer} style={{ width: '100%', color: 'black' }} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField label={"Telp Customer"} value={suratJalanViewData.customer.telp_customer || "-"} style={{ width: '100%', color: 'black' }} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField label={"Alamat Customer"} value={suratJalanViewData.customer.alamat_customer || "-"} style={{ width: '100%', color: 'black' }} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField label={"ID Surat Jalan"} value={suratJalanViewData.id_surat_jalan} style={{ width: '100%', color: 'black' }} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField label={"Status Surat Jalan"} value={suratJalanViewData.status} style={{ width: '100%', color: 'black', backgroundColor: getRowColorByStatus(suratJalanViewData.status) }} />
                        </Grid>
                    </Grid>
                    <Divider className={classes.dividerSection} />
                    <Typography variant="h6">List Barang Yang Di-Antar</Typography>
                    <Grid container spacing={3}>
                        {GenerateItemSuratJalan(suratJalanViewData.barang, suratJalanViewData.jumlah_barang)}
                    </Grid>
                    <Divider className={classes.dividerSection} />
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={1}>
                            <Typography variant="h6">Nama Penerima</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField style={{ width: '85%' }} label="Nama Penerima" value={suratJalanViewData.penerima} onChange={(ev) => {
                                // setPenerima(ev.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <Typography variant="h6">Alamat Penerima</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField style={{ width: '85%' }} label="Alamat Penerima" value={suratJalanViewData.alamat_pengantaran} onChange={(ev) => {
                                // setAlamatPengantaran(ev.target.value)
                            }} />
                        </Grid>
                        <Divider style={{ height: '1px', width: '100%' }} />
                        <Grid item xs={12} sm={1}>
                            <Typography variant="h6">Telepon Penerima</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField style={{ width: '85%' }} label="Telepon Penerima" value={suratJalanViewData.nomor_telepon} onChange={(ev) => {
                                // setNomorTelepon(ev.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <Typography variant="h6">Keterangan Tambahan</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField style={{ width: '85%' }} label="Keterangan Tambahan" value={suratJalanViewData.keterangan} onChange={(ev) => {
                                // setKeteranganTambahan(ev.target.value)
                            }} />
                        </Grid>
                        <Divider style={{ height: '1px', width: '100%' }} />
                        <Grid item xs={12} sm={1}>
                            <Typography variant="h6">Plat Kendaraan</Typography>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <TextField style={{ width: '90%' }} label="Kendaraan" value={suratJalanViewData.plat_kendaraan} onChange={(ev) => {
                                // setKendaraan(ev.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <Typography variant="h6">Nama Pengemudi</Typography>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <TextField style={{ width: '90%' }} label="Supir" value={suratJalanViewData.nama_supir} onChange={(ev) => {
                                // setSupir(ev.target.value)
                            }} />
                        </Grid>
                    </Grid>
                </>}
                <Divider className={classes.dividerSection} />
                <Button style={{ color: 'white', backgroundColor: 'red', marginRight: '10px' }} variant='contained' startIcon={<CloseIcon />} onClick={(ev) => {
                    setIsEditingSuratJalan(false);
                    setSuratJalanViewData(null);
                }}>Close Menu</Button>

                <Button style={{ color: 'white', backgroundColor: 'blue', marginRight: '10px' }} variant='contained' startIcon={<PrintIcon />} onClick={(ev) => {
                    setSuratJalanPrintData(suratJalanViewData);
                    handlePrint()
                }}>Print</Button>

                {suratJalanViewData.status != 'Finish' && suratJalanViewData.status != 'Loading' ? <Button style={{ color: 'black', backgroundColor: 'yellow', marginRight: '10px' }} variant='contained' startIcon={<LoadingIcon />} onClick={(ev) => {
                    UpdateStatusSuratJalan(suratJalanViewData.id_surat_jalan, "Loading", suratJalanViewData.id_bon_penjualan);
                }}>Status Loading</Button> : null};

                {suratJalanViewData.status != 'Finish' && suratJalanViewData.status != 'On The Way' ? <Button style={{ color: 'black', backgroundColor: 'orange', marginRight: '10px' }} variant='contained' startIcon={<FinishIcon />} onClick={(ev) => {
                    UpdateStatusSuratJalan(suratJalanViewData.id_surat_jalan, "On The Way", suratJalanViewData.id_bon_penjualan);
                }}>Status On The Way</Button> : null}

                {suratJalanViewData.status != 'Finish' ? <Button style={{ color: 'white', backgroundColor: 'green', marginRight: '10px' }} variant='contained' startIcon={<FinishIcon />} onClick={(ev) => {
                    UpdateStatusSuratJalan(suratJalanViewData.id_surat_jalan, "Finish", suratJalanViewData.id_bon_penjualan);
                }}>Status Finish</Button> : null}

                {suratJalanViewData.status != 'Finish' ? <Button style={{ color: 'white', backgroundColor: 'black', float: 'right' }} variant='contained' startIcon={< DeleteIcon />} onClick={(ev) => {
                    DeleteSuratJalan(suratJalanViewData.id_surat_jalan, suratJalanViewData.id_bon_penjualan);
                }}>DELETE SURAT JALAN</Button> : null}
            </Paper></div> : null
            }
        </div >
    )
}