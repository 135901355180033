import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chart from '../Components/Transactions/Chart';
import ChartData from '../Components/Transactions/ChartData';
import Orders from '../Components/Transactions/Orders';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { names } from '../Settings/globalVar';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import { Publish, Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    filter: {
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'row',
    },
    filterText: {
        // backgroundColor: "blue",
        padding: '20px'
    },
    fixedHeight: {
        height: 240,
    },
    itemBarang: {
        marginRight: '20px',
        width: '250px',
    },
    itemText: {
        padding: '20px',
        fontWeight: 'bold',
        // marginLeft: "10%",
        // backgroundColor: 'blue'
    },
    button: {
        marginLeft: '20px',
        width: '350px'
    },
    divider: {
        height: '10px',
        backgroundColor: 'rgba(0,0,0,0)'
    },
    previewPaper: {
        // textAlign: 'left',
        padding: '10px',
        width: '600px',
        margin: 'auto',
        marginTop: '100px'
    },
    previewContainer: {
        // textAlign: 'center',
        zIndex: '1',
        position: 'fixed',
        left: '0',
        right: '0',
        top: '0',
        margin: 'auto',
        backgroundColor: 'rgba(55,55,55,0.3)',
        height: '100vh',
        overflowY: 'scroll',
    }
}));

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const listJenisTransaksi = ["Cash", "Transfer", "Semua"]

export default function DashboardSales() {
    const classes = useStyles();
    const [item, setItem] = React.useState('Semua');
    const [tanggal, setTanggal] = React.useState({
        month_name_year: months[new Date().getMonth()] + " " + new Date().getFullYear(),
        month_year: ('0' + (new Date().getMonth() + 1)).slice(-2) + " " + new Date().getFullYear()
    });
    const [listDate, setListDate] = React.useState([]);
    const [jenisTransaksi, setJenisTransaksi] = React.useState(listJenisTransaksi[2]);
    const [isLoadingDate, setIsLoadingDate] = React.useState(false);
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    const [isCreatingTransaksi, setIsCreatingTransaksi] = React.useState(false)
    const [isSubmittingTransaksi, setIsSubmittingTransaksi] = React.useState(false)
    const [newJenisTransaksi, setNewJenisTransaksi] = React.useState("");
    const [newJumlahTransaksi, setNewJumlahTransaksi] = React.useState(0);
    const [newNamaTransaksi, setNewNamaTransaksi] = React.useState("");
    const [newTipeUang, setNewTipeUang] = React.useState("");

    useEffect(() => {
        setIsLoadingDate(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/super_admin/get_sales_date_filter_list`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            }
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                setIsLoadingDate(false)
                return
            }
            return res.json()
        }).then((res) => {
            setListDate(res.data)
            setIsLoadingDate(false)
        }).catch((ex) => {
            setIsLoadingDate(false)
            alert(ex)
        })
    }, [])

    function inputTransaksi() {
        if (!newNamaTransaksi || !newJenisTransaksi || !newJumlahTransaksi) {
            alert("Please input all the data in the form")
            return
        }

        const data = { namaTransaksi: newNamaTransaksi, jenisTransaksi: newJenisTransaksi, jumlahTransaksi: newJumlahTransaksi, tipeUang: newTipeUang }
        setIsSubmittingTransaksi(true)
        fetch(`${process.env.REACT_APP_HOSTNAME}/super_admin/input_transaksi`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(names.token)
            },
            body: JSON.stringify(data)
        }).then(async (res) => {
            const data = await res.json();
            if (res.status === 401) {
                localStorage.removeItem(names.token)
                window.location.pathname = ""
                alert("Un-Authorized Access Detected !!!")
                return
            }
            if (!data["success"]) throw (new Error(data["message"]))
            else alert("Create New Transaksi Successfully !!!")
            window.location.reload();
        }).catch((ex) => {
            alert(ex)
            setNewNamaTransaksi("")
            setNewJenisTransaksi("")
            setNewJumlahTransaksi("")
            setIsSubmittingTransaksi(false)
        })
    }

    return (
        <React.Fragment>
            <CssBaseline />
            {isCreatingTransaksi ? <div className={classes.previewContainer}>
                <Paper className={classes.previewPaper} id="divcontents">
                    <Typography variant="h6" gutterBottom>Tambah Customer Baru</Typography>
                    {isSubmittingTransaksi ? <CircularProgress /> : <>
                        <TextField
                            id="namaTransaksi"
                            name="namaTransaksi"
                            label="Nama Transaksi"
                            value={newNamaTransaksi}
                            onChange={(ev) => setNewNamaTransaksi(ev.target.value)}
                            fullWidth
                        />
                        <Divider className={classes.divider} />
                        <FormControl fullWidth>
                            <InputLabel>Jenis Transaksi</InputLabel>
                            <Select value={newJenisTransaksi} onChange={(ev) => setNewJenisTransaksi(ev.target.value)}>
                                <MenuItem value={"Debit"}>Debit</MenuItem>
                                <MenuItem value={"Kredit"}>Kredit</MenuItem>
                            </Select>
                        </FormControl>
                        <Divider className={classes.divider} />
                        <FormControl fullWidth>
                            <InputLabel>Tipe Uang</InputLabel>
                            <Select value={newTipeUang} onChange={(ev) => setNewTipeUang(ev.target.value)}>
                                <MenuItem value={"Cash"}>Cash</MenuItem>
                                <MenuItem value={"Transfer"}>Transfer</MenuItem>
                            </Select>
                        </FormControl>
                        <Divider className={classes.divider} />
                        <TextField
                            id="jumlahTransaksi"
                            name="jumlahTransaksi"
                            label="Jumlah Transaksi"
                            value={newJumlahTransaksi}
                            onChange={(ev) => {
                                if (isNaN(parseInt(ev.target.value))) return setNewJumlahTransaksi(null);
                                setNewJumlahTransaksi(parseInt(ev.target.value))
                            }}
                            fullWidth
                        />
                        <Divider className={classes.divider} />
                        <Button style={{ padding: '10px' }} variant="contained" color="primary" onClick={(ev) => inputTransaksi()}>
                            <Publish />{"SUBMIT"}
                        </Button>
                        <Button style={{ padding: '10px', marginLeft: '10px' }} variant="contained" color="secondary" onClick={(ev) => {
                            setNewJenisTransaksi("")
                            setNewJumlahTransaksi(0)
                            setNewNamaTransaksi("")
                            setIsCreatingTransaksi(false)
                        }}>
                            <Close className={classes.iconButton} />{"CLOSE"}
                        </Button>
                    </>}
                </Paper>
            </div> : null}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.filter}>
                        <Typography className={classes.filterText}>Filter : </Typography>
                        {isLoadingDate ? <CircularProgress style={{ marginTop: '10px', marginRight: '250px' }} /> :
                            <Autocomplete
                                options={listDate.filter(val => val.month_name_year !== tanggal.month_name_year)}
                                getOptionSelected={(option, value) => option.month_name_year === value.month_name_year || listDate.find(val => val.month_name_year === value.month_name_year)}
                                getOptionLabel={(option) => option.month_name_year}
                                value={tanggal}
                                onChange={(ev, value) => {
                                    if (value == null) return
                                    setTanggal(value)
                                }}
                                className={classes.itemBarang}
                                renderInput={(params) => <TextField {...params} label="Date" />}
                            />}
                        <FormControl style={{ width: '200px' }}>
                            <InputLabel>Jenis Transaksi</InputLabel>
                            <Select value={jenisTransaksi} onChange={(ev) => setJenisTransaksi(ev.target.value)}>
                                {listJenisTransaksi.map((jenisVal, jenisIdx, jenisArr) => {
                                    return (<MenuItem key={`jenis_${jenisIdx}`} value={jenisVal}>{jenisVal}</MenuItem>)
                                })}
                            </Select>
                        </FormControl>
                        <Typography className={classes.itemText}>DATA TRANSAKSI {tanggal.month_name_year}</Typography>
                        <Button variant="contained" color="primary" className={classes.button} onClick={(ev) => setIsCreatingTransaksi(true)}>
                            Tambah Transaksi Untuk Hari Ini
                        </Button>

                    </Paper>
                </Grid>
                <Grid item xs={12} md={8} lg={9}>
                    <Paper className={fixedHeightPaper}>
                        <Chart id_barang={item} tanggal={tanggal} />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Paper className={fixedHeightPaper}>
                        <ChartData id_barang={item} tanggal={tanggal} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Orders id_barang={item} tanggal={tanggal} jenis_transaksi={jenisTransaksi} />
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}