import React from 'react';
import SignIn from './Pages/signIn';
import DashboardStock from './Pages/dashboardStock';
import AddStock from './Pages/addStock';
import RemoveStock from './Pages/removeStock';
import UserSetup from './Pages/userSetup'
import SignOut from './Pages/signOut'
import ItemSetup from './Pages/itemSetup'
import CustomerSetup from './Pages/customerSetup'
import AddSales from './Pages/addSales'
import AddSalesCat from './Pages/addSalesCat'
import AddSuratJalan from './Pages/addSuratJalan'
import SuratJalanSetup from './Pages/suratJalanSetup';
import SalesSetup from './Pages/salesSetup'
import SalesSetupCat from './Pages/salesSetupCat'
import ItemInfo from './Pages/itemInfo'
import DashboardSales from './Pages/dashboardSales'
import DashboardTransactions from './Pages/dashboardTransactions'
import DashboardCatTransactions from './Pages/dashboardCatTransactions'
import { hasToken, hasTokenExpired, getTokenOwnerRole } from './Settings/globalFunc'
import { Link, Route, BrowserRouter as Router } from 'react-router-dom'
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Drawer, AppBar, Toolbar, List, Typography, Divider, IconButton, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { Menu as MenuIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, DirectionsRun as RunIcon, Group as PersonIcon, RemoveCircleOutlineOutlined as RemoveIcon, AddCircleOutlineOutlined as AddIcon, BallotOutlined as ItemIcon, Equalizer as DashboardStockIcon, SupervisedUserCircle as CustomerIcon, MonetizationOn as AddSalesIcon, Receipt as SalesSetupIcon, AssignmentSharp as DashboardSalesIcon, Payment as DashboardTransactionsIcon, LocalShipping as AddSuratJalanIcon, EmojiTransportation as SuratJalanSetupIcon } from '@material-ui/icons'

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  navLink: {
    textDecoration: 'none',
    color: 'black',
    fontWeight: 'bolder',
    textShadow: '1px 1px 2px gray'
  }
}));

function MiniDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  if (hasToken() && !hasTokenExpired()) {
    return (
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>PT. Tri Mustika Sejagat</Typography>
          </Toolbar>
        </AppBar>
        <Drawer className={classes.drawer} variant="persistent" anchor="left" open={open}
          classes={{
            paper: classes.drawerPaper,
          }}>
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            {getTokenOwnerRole() === "super_admin" ? <Link className={classes.navLink} to="/DashboardTransactions" onClick={handleDrawerClose}>
              <ListItem button key="Dashboard Transactions">
                <ListItemIcon><DashboardTransactionsIcon /></ListItemIcon>
                <ListItemText primary="Dashboard Transactions" />
              </ListItem>
            </Link> : null}
            {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_super") ? <Link className={classes.navLink} to="/DashboardCatTransactions" onClick={handleDrawerClose}>
              <ListItem button key="Dashboard Cat Transactions">
                <ListItemIcon><DashboardTransactionsIcon /></ListItemIcon>
                <ListItemText primary="Dashboard Cat Transactions" />
              </ListItem>
            </Link> : null}
            {getTokenOwnerRole() === "super_admin" ? <Link className={classes.navLink} to="/DashboardSales" onClick={handleDrawerClose}>
              <ListItem button key="Dashboard Sales">
                <ListItemIcon><DashboardSalesIcon /></ListItemIcon>
                <ListItemText primary="Dashboard Sales" />
              </ListItem>
            </Link> : null}
            {getTokenOwnerRole() === "super_admin" ? <Link className={classes.navLink} to="/DashboardStock" onClick={handleDrawerClose}>
              <ListItem button key="Dashboard Stok">
                <ListItemIcon><DashboardStockIcon /></ListItemIcon>
                <ListItemText primary="Dashboard Stok" />
              </ListItem>
            </Link> : null}
            {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_sales") ?
              <Link className={classes.navLink} to="/AddSales" onClick={handleDrawerClose}>
                <ListItem button key="Add Penjualan">
                  <ListItemIcon><AddSalesIcon /></ListItemIcon>
                  <ListItemText primary="Add Penjualan" />
                </ListItem>
              </Link> : null}
            {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_sales") || getTokenOwnerRole().includes("admin_cat_super") ?
              <Link className={classes.navLink} to="/AddSalesCat" onClick={handleDrawerClose}>
                <ListItem button key="Add Penjualan Cat">
                  <ListItemIcon><AddSalesIcon /></ListItemIcon>
                  <ListItemText primary="Add Penjualan Cat" />
                </ListItem>
              </Link> : null}
            {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_sales") ?
              <Link className={classes.navLink} to="/SalesSetup" onClick={handleDrawerClose}>
                <ListItem button key="Atur Bon Penjualan">
                  <ListItemIcon><SalesSetupIcon /></ListItemIcon>
                  <ListItemText primary="Atur Bon Penjualan" />
                </ListItem>
              </Link> : null}
            {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_cat_sales") || getTokenOwnerRole().includes("admin_cat_super") ?
              <Link className={classes.navLink} to="/SalesSetupCat" onClick={handleDrawerClose}>
                <ListItem button key="Atur Bon Penjualan Toko Cat">
                  <ListItemIcon><SalesSetupIcon /></ListItemIcon>
                  <ListItemText primary="Atur Bon Penjualan Toko Cat" />
                </ListItem>
              </Link> : null}
            {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_sales") || getTokenOwnerRole().includes("admin_cat_sales") ?
              <Link className={classes.navLink} to="/AddSuratJalan" onClick={handleDrawerClose}>
                <ListItem button key="Surat Jalan">
                  <ListItemIcon><AddSuratJalanIcon /></ListItemIcon>
                  <ListItemText primary="Surat Jalan" />
                </ListItem>
              </Link> : null}
            {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_stok") || getTokenOwnerRole().includes("admin_cat_super") ? <Link className={classes.navLink} to="/IncreaseStock/1" onClick={handleDrawerClose}>
              <ListItem button key="Stok Masuk">
                <ListItemIcon><AddIcon /></ListItemIcon>
                <ListItemText primary="Stok Masuk" />
              </ListItem>
            </Link> : null}
            {getTokenOwnerRole() === "super_admin" || getTokenOwnerRole().includes("admin_stok") || getTokenOwnerRole().includes("admin_cat_super") ? <Link className={classes.navLink} to="/ReduceStock/1" onClick={handleDrawerClose}>
              <ListItem button key="Stok Keluar">
                <ListItemIcon><RemoveIcon /></ListItemIcon>
                <ListItemText primary="Stok Keluar" />
              </ListItem>
            </Link> : null}
            {getTokenOwnerRole().includes("admin") ?
              <Link className={classes.navLink} to="/SetupItem/1" onClick={handleDrawerClose}>
                <ListItem button key="Atur Barang">
                  <ListItemIcon><ItemIcon /></ListItemIcon>
                  <ListItemText primary="Atur Barang" />
                </ListItem>
              </Link> : null}
            {getTokenOwnerRole() === "super_admin" ?
              <Link className={classes.navLink} to="/SetupCustomer/1" onClick={handleDrawerClose}>
                <ListItem button key="Atur Customer">
                  <ListItemIcon><CustomerIcon /></ListItemIcon>
                  <ListItemText primary="Atur Customer" />
                </ListItem>
              </Link> : null}
            {getTokenOwnerRole() === "sales" ?
              <Link className={classes.navLink} to="/ItemInfo" onClick={handleDrawerClose}>
                <ListItem button key="Info Barang">
                  <ListItemIcon><ItemIcon /></ListItemIcon>
                  <ListItemText primary="Info Barang" />
                </ListItem>
              </Link> : null}
            {getTokenOwnerRole() === "super_admin" ?
              <Link className={classes.navLink} to="/SetupUser/1" onClick={handleDrawerClose}>
                <ListItem button key="Pengaturan Pengguna">
                  <ListItemIcon><PersonIcon /></ListItemIcon>
                  <ListItemText primary="Atur Pengguna" />
                </ListItem>
              </Link> : null}
            <Link className={classes.navLink} to="/SignOut" onClick={handleDrawerClose}>
              <ListItem button key="Sign Out">
                <ListItemIcon><RunIcon /></ListItemIcon>
                <ListItemText primary="Sign Out" />
              </ListItem>
            </Link>
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {/* <Route path="/" component={AddStock} /> */}
          <Route path="/DashboardStock" component={DashboardStock} />
          <Route path="/DashboardSales" component={DashboardSales} />
          <Route path="/DashboardTransactions" component={DashboardTransactions} />
          <Route path="/DashboardCatTransactions" component={DashboardCatTransactions} />
          <Route path="/IncreaseStock/:pageNumber" component={AddStock} />
          <Route path="/AddSales" component={AddSales} />
          <Route path="/AddSalesCat" component={AddSalesCat} />
          <Route path="/AddSuratJalan" component={AddSuratJalan} />
          <Route path="/SalesSetup" component={SalesSetup} />
          <Route path="/SalesSetupCat" component={SalesSetupCat} />
          <Route path="/ReduceStock/:pageNumber" component={RemoveStock} />
          <Route path="/ItemInfo" component={ItemInfo} />
          <Route path="/SetupItem/:pageNumber" component={ItemSetup} />
          <Route path="/SetupUser/:pageNumber" component={UserSetup} />
          <Route path="/SetupCustomer/:pageNumber" component={CustomerSetup} />
          <Route path="/SignOut" component={SignOut} />
        </main>
      </div>
    );
  }
  else return (<SignIn />)
}

function App() {
  return (
    <Router>
      {MiniDrawer()}
    </Router>
  )
}

export default App;
