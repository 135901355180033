import { names } from './globalVar'

export function hasToken() {
    const accessToken = localStorage.getItem(names.token)
    return accessToken !== null && accessToken !== undefined && accessToken !== "" && accessToken !== "undefined" && accessToken !== "null"
}

export function hasTokenExpired() {
    let accessToken = localStorage.getItem(names.token).replace("Bearer ", "");
    if (!hasToken()) return true
    const token = atob(accessToken).split("-")
    const tokenLifeTime = atob(token[1])
    const tokenCreationTime = atob(token[0])
    const tokenExpireTime = Number.parseInt(tokenCreationTime) + Number.parseInt(tokenLifeTime)
    if (new Date().getTime() > tokenExpireTime) return true
    else return false
}

export function getTokenOwnerRole() {
    let accessToken = localStorage.getItem(names.token).replace("Bearer ", "");
    if (!hasToken()) return null
    const token = atob(accessToken).split("-")
    const tokenOwnerRole = atob(token[3])
    return tokenOwnerRole;
}

export function getTokenOwnerName() {
    let accessToken = localStorage.getItem(names.token).replace("Bearer ", "");
    if (!hasToken()) return null
    const token = atob(accessToken).split("-")
    const tokenOwnerName = atob(token[4])
    return tokenOwnerName;
}